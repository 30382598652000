import React from 'react';
import {
  api,
  APIReplayPlaybackInfoResponse,
  StreamingType,
  VODPlaybackInfo,
  getAdditionalConsentString,
  getConsentString,
  isOqeeCustomization
} from '@oqee/core';

import { BrowserContext, BrowserContextType } from '../components/context/BrowserContextProvider';
import { useWebStoreActions } from '../store/webStoreUtils';
import { ActionCreator } from 'easy-peasy';

const { DRM_SYSTEM } = api.constant;

type SVODParams = {
  apiType: 'svod';
  offerId: number;
};

type TVODParams = {
  apiType: 'vod';
  purchaseId: string;
};

function useWebVodPlaybackInfo(params: SVODParams | TVODParams): VODPlaybackInfo | null {
  const browserContext: BrowserContextType = React.useContext(BrowserContext);
  const triggerError: ActionCreator<any> = useWebStoreActions(actions => actions.error.trigger);
  const idParam: string = params.apiType === 'svod' ? params.offerId.toString() : params.purchaseId;

  const [playbackInfo, setPlaybackInfo] = React.useState<VODPlaybackInfo | null>(null);

  const handlePlaybackInfoResponse = React.useCallback(
    (response: APIReplayPlaybackInfoResponse) => {
      if (!response.success) {
        triggerError({
          code: response.error?.code,
          data: response.error
        });
        return;
      }

      if (response.result) {
        const {
          media_url,
          drm,
          license_server,
          ads_info,
          position,
          subtitles = {},
          program_id,
          pre_ads,
          pre_medias,
          thumbnails
        } = response.result;
        setPlaybackInfo({
          broadcastType: 'vod',
          manifestUrl: media_url,
          drmSystem: drm && DRM_SYSTEM?.[drm],
          drmServer: license_server,
          adsInfo: ads_info,
          position,
          promoToken: null,
          programId: program_id,
          subtitles,
          preAds: pre_ads,
          preMedias: pre_medias,
          thumbnails
        });
      }
    },
    [setPlaybackInfo, triggerError]
  );

  React.useEffect(() => {
    if (!idParam) {
      return;
    }

    const [supportedStreamType, supportedDrm, supportedCipher]: [string, string, (string | null | undefined)[]] =
      browserContext?.selectedStreamingType === StreamingType.HLS
        ? [StreamingType.HLS, 'fairplay', browserContext.availableCiphers || ['cbcs']]
        : [StreamingType.DASH, 'widevine', browserContext.availableCiphers || ['cenc']];

    const playbackInfoOptions = {
      supported_stream_types: [supportedStreamType],
      supported_drms: [supportedDrm],
      supported_ciphers: supportedCipher,
      supported_ads: ['vast', 'vmap'],
      consent_string: getConsentString(),
      additional_consent_string: getAdditionalConsentString()
    };

    // Get playbackInfos for svod or vod
    api[params.apiType]
      .requestPlaybackInfo(idParam, playbackInfoOptions, isOqeeCustomization())
      .then(handlePlaybackInfoResponse)
      .catch(error => {
        triggerError({ code: 'content_not_found', data: error });
      });
  }, [idParam]);

  return playbackInfo;
}

export default useWebVodPlaybackInfo;
