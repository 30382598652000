import React, { useState } from 'react';
import { WebErrorRegistryEntry } from '../../../lib/errorRegistry';
import useWebError from '../../../hooks/useWebError';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../Modal';

interface ErrorHandlerProps extends React.PropsWithChildren {
  onError?: () => void;
}

const ErrorHandler: React.FC<ErrorHandlerProps> = ({ onError }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const defaultHandleBack = () => (location.key !== 'default' ? navigate(-1) : navigate('/home'));
  const error = useWebError();
  const [webError, setWebError] = useState<WebErrorRegistryEntry>();

  React.useEffect(() => {
    if (!webError && error?.shownComponentInPlayer === 'Modal' && error?.title) {
      return () => error.reset();
    }
  }, [error]);

  return (
    <>
      {webError && (
        <Modal
          title={webError.title}
          description={webError.description as string}
          onValidate={() => {
            setWebError(undefined);
            error.reset();
            (onError || defaultHandleBack)();
          }}
        />
      )}
    </>
  );
};

export default ErrorHandler;
