import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '@oqee/core';

import VodPortalLayout from '../../components/layout/VodPortalLayout/VodPortalLayout';

// TODO create hook when used on TV or other file
function VodGroupPage() {
  const { id: groupId } = useParams();

  const [group, setGroup] = useState({
    title: '',
    entries: []
  });

  useEffect(() => {
    const fetchPortal = async () => {
      const data = await api.vod.fetchPortalGroup(groupId);
      return data;
    };

    fetchPortal().then(response => {
      setGroup(response.result.group);
    });
  }, []);

  return <VodPortalLayout title={group.title} entries={group.entries} />;
}

export default VodGroupPage;
