import React from 'react';
import { PICTURE_FORMAT_WEB, analyticsApi, useProgram } from '@oqee/core';
import clsx from 'clsx';

import PageLayout from '../PageLayout';
import Header from '../Header';
import Footer from '../Footer';
import { HeaderNav } from '../../../utils/webUtils';

import './MainLayout.css';
import { Backdrop } from '@oqee/ui';

interface MainLayoutProps extends React.PropsWithChildren {
  activeHeaderNav?: HeaderNav;
  frontChildren?: React.ReactNode;
  fbScreenName?: string;
  hidden?: boolean;
  className?: string;
  program?: any;
  withBackdrop?: boolean;
}

function MainLayout({
  children,
  frontChildren,
  activeHeaderNav,
  fbScreenName,
  hidden = false,
  className,
  program: programEntry,
  withBackdrop
}: MainLayoutProps) {
  const program = useProgram(programEntry);

  React.useEffect(() => {
    if (fbScreenName) analyticsApi.logPageView(fbScreenName);
  }, [fbScreenName]);

  const thumbnailUrl = React.useMemo(() => {
    return program?.getBackdropUrl(PICTURE_FORMAT_WEB.THUMBNAIL);
  }, [program]);

  // TODO add Toaster here for other page when needed in future but be careful with page that contains Player Component, maybe using playbackInfo?.liveChannel?.noticeMessage, and later another continuous refactoring
  return (
    <PageLayout>
      <div className={clsx('MainLayout', { hidden }, className)}>
        <Header activeHeaderNav={activeHeaderNav} />
        <div className="MainDiv" id="MainDiv">
          <div className={clsx('ContentDiv', { withoutFront: !frontChildren })}>{children}</div>
          {frontChildren && <div className="ContentFrontDiv">{frontChildren}</div>}
          <BackToTop />
          <Footer />
        </div>
        {withBackdrop && thumbnailUrl && <Backdrop url={thumbnailUrl} blur={20} overlay animated />}
      </div>
    </PageLayout>
  );
}

function BackToTop() {
  const [isVisible, setIsVisible] = React.useState(false);

  const getMainDiv = () => document.getElementById('MainDiv')!;

  const goToTop = () => {
    const mainDiv: HTMLElement = getMainDiv();
    mainDiv.scrollTop = 0;
  };

  React.useEffect(() => {
    const mainDiv: HTMLElement = getMainDiv();

    const onScroll = () => {
      setIsVisible(mainDiv.scrollTop > 100);
    };

    mainDiv.addEventListener('scroll', onScroll);
    return () => {
      mainDiv.removeEventListener('scroll', onScroll);
    };
  }, []);

  return isVisible ? <div className={'BackToTopDiv'} onClick={goToTop} /> : null;
}

export default MainLayout;
