import React, { useMemo } from 'react';
import { Button, CroixPleinIcon, PlayIcon, Typography, VodPleineIcon } from '@oqee/ui';
import { Link } from 'react-router-dom';
import { useVodProgramDetails } from '@oqee/core';

type VodCTAProps = {
  program: any;
  rounded?: boolean;
  isResumable?: boolean;
  onResumableDelete?: () => void;
}

function VodCTA({ program, rounded = false, isResumable = false, onResumableDelete }: VodCTAProps) {
  const { content_id, ordered_offer_groups } = program.content;
  const purchasedVod = useVodProgramDetails(content_id, null, false, ordered_offer_groups);

  const [isPlayable, playbackUrl, trailerUrl] = useMemo(() => {
    const programEntry = purchasedVod?.programEntry;
    const content = program?.content;
    const trailer_ids = content?.trailer_ids;
    const provider_ids = content?.provider_ids;

    return [
      programEntry?.isPlayable,
      programEntry?.purchase?.id ? `/tvod/${programEntry.purchase.id}/play` : `/svod/${content_id}/play`,
      trailer_ids?.length > 0 && provider_ids?.length > 0 ? `/vod/${provider_ids[0]}/${content_id}/trailer` : undefined
    ];
  }, [purchasedVod, program]);

  return (
    <>
      {isPlayable && (
        <Link to={playbackUrl}>
          <Button
            color="primary"
            rounded={rounded}
            icon={PlayIcon}
            iconColor="info"
            tooltip={rounded ? 'Lecture' : undefined}
          >
            {!rounded && (
              <Typography variant="body1" bold>
                Lecture
              </Typography>
            )}
          </Button>
        </Link>
      )}
      {isResumable && (
        <Button
          data-test-id="cta-vod-resume-delete"
          icon={CroixPleinIcon}
          tooltip="Retirer de la ligne"
          onClick={onResumableDelete}
        />
      )}
      {trailerUrl && (
        <Link to={trailerUrl}>
          <Button color="secondary" icon={VodPleineIcon} rounded={rounded} iconColor="info" tooltip="Bande-annonce" />
        </Link>
      )}
    </>
  );
}

export default VodCTA;
