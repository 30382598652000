import React from 'react';
import { Link } from 'react-router-dom';

import './LandingHeader.css';

function LandingHeader() {
  return (
    <div className="LandingHeader">
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar-2 w-nav"
      >
        <div className="w-container">
          <div className="w-nav-brand">
            <Link to={'/logout'}>
              <img src="/img/landing/Theme3.svg" loading="lazy" width="80" alt="logo OQEE" className="image-17" />
            </Link>
          </div>
          <nav role="navigation" className="nav-menu w-nav-menu">
            <div className="voir-les-offres">
              <Link to={'/offers'}>Voir les offres</Link>
            </div>
            <div className="button-4 w-button" data-test-id="button-sign-in">
              <Link to={'/login_switch'}>Se connecter</Link>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default LandingHeader;
