import React from 'react';

import Breadcrumb from '../shared/Breadcrumb';
import ButtonBack from '../shared/ButtonBack';

interface VodNavHeaderProps {
  title: string;
}

function VodNavHeader({ title }: VodNavHeaderProps) {
  return (
    <Breadcrumb>
      <ButtonBack title={title} />
    </Breadcrumb>
  );
}

export default VodNavHeader;
