import { createStore, createTypedHooks } from 'easy-peasy';
import { webModel, WebModel } from './webModel';

const typedHooks = createTypedHooks<WebModel>();

const useWebStoreActions = typedHooks.useStoreActions;
const useWebStoreDispatch = typedHooks.useStoreDispatch;
const useWebStoreState = typedHooks.useStoreState;

const webStore = createStore<WebModel>(webModel, {
  disableImmer: true,
  devTools: import.meta.env.DEV
});

export { useWebStoreActions, useWebStoreDispatch, useWebStoreState, webStore };
