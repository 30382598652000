import React from 'react';
import { useParams } from 'react-router-dom';
import { CONSTANTS, useVodProgramDetails } from '@oqee/core';

import PlayerPageLayout from '../../components/layout/PlayerPageLayout';
import SVodPlayer from '../../components/player/SVodPlayer';

type SVodPlayerPageParams = {
  contentId: string;
};

function SVodPlayerPage() {
  const { contentId } = useParams() as SVodPlayerPageParams;
  const { isReady, getOffers } = useVodProgramDetails(Number(contentId));

  if (!isReady) return null;

  const oqeeCineOffers: any[] = getOffers('svod', CONSTANTS.OQEE_CINE_PROVIDER_ID);
  const oqeeCineOfferId: number | undefined = oqeeCineOffers?.[0]?.id;

  if (oqeeCineOfferId === undefined) return null;

  return (
    <PlayerPageLayout>
      <SVodPlayer offerId={oqeeCineOfferId} />
    </PlayerPageLayout>
  );
}

export default SVodPlayerPage;
