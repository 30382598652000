import React from 'react';
import { useLocation, Location } from 'react-router-dom';
import { ChannelSubscriptionContext } from '@oqee/core';

import ChannelSubscription from './ChannelSubscription';

interface PlayerErrorNeedSubscriptionProps {
  channelNumber: number;
  onSuccess: () => void;
  onCancel: () => void;
}

function PlayerErrorNeedSubscription({ channelNumber, onSuccess, onCancel }: PlayerErrorNeedSubscriptionProps) {
  const location: Location = useLocation();

  const context = React.useMemo(
    () => ({
      baseUrl: location.pathname,
      channelNumber,
      onSuccess,
      onCancel
    }),
    [channelNumber]
  );

  return (
    <ChannelSubscriptionContext.Provider value={context}>
      <ChannelSubscription />
    </ChannelSubscriptionContext.Provider>
  );
}

export default PlayerErrorNeedSubscription;
