import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useProfileUpdate } from '@oqee/core';

import Spinner from '../../../../components/shared/Spinner';
import UserProfileLayout from '../../../../components/layout/UserProfileLayout';

function ProfileSave() {
  const {
    state: { profile }
  } = useLocation();
  const { updatedProfile, update } = useProfileUpdate();

  React.useEffect(() => {
    update(profile);
  }, [update, profile]);

  if (updatedProfile) {
    return <Navigate to=".." />;
  }

  return (
    <UserProfileLayout>
      <Spinner testId="profile-save-pending" />
    </UserProfileLayout>
  );
}

export default ProfileSave;
