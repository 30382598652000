import React from 'react';
import { ErrorModelState } from '@oqee/core';

import errorRegistry from '../lib/errorRegistry';
import { useWebStoreActions, useWebStoreState } from '../store/webStoreUtils';

function useWebError() {
  const errorActions = useWebStoreActions(actions => actions.error);
  const errorState: ErrorModelState = useWebStoreState(state => state.error);

  const error = React.useMemo(
    () => ({
      ...errorState,
      ...(errorState.code && errorRegistry[errorState.code]),
      ...errorActions,
      code: errorState.code
    }),
    [errorActions, errorState]
  );

  return error;
}

export default useWebError;
