import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { useAvatars } from '@oqee/core';

import ProfileAge from '../ProfileAge';
import ProfilePicture from '../ProfilePicture';
import ProfileColor from '../ProfileColor';
import ProfileName from '../ProfileName';
import ProfileCreate from './ProfileCreate';

function ProfileCreation() {
  const avatars = useAvatars();

  if (!avatars) {
    return null;
  }
  return (
    <Routes>
      <Route path=":ageRange/:avatarTone/:avatarShapeKey/:color/commit" element={<ProfileCreate />} />
      <Route path=":ageRange/:avatarTone/:avatarShapeKey/:color" element={<ProfileName />} />
      <Route path=":ageRange/:avatarTone/:avatarShapeKey" element={<ProfileColor />} />
      <Route path=":ageRange" element={<ProfilePicture />} />
      <Route index element={<ProfileAge />} />
    </Routes>
  );
}

export default ProfileCreation;
