import { useProgram, PICTURE_FORMAT_WEB } from '@oqee/core';
import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import Typography from '../../shared/Typography';
import Lock from '../../shared/Lock';
import CircularProgress from '../../shared/CircularProgress';
import useNavigationState from '../../../hooks/useNavigationState';
import useScrollRestoration from '../../../hooks/useScrollRestoration';

import './ContentThumbnail.css';
import { useWebStoreState } from '../../../store/webStoreUtils';
import withVisibility from '../../hoc/withVisibility';

export type ContentThumbnailSize = 'xsmall' | 'small' | 'medium';

type ContentThumbnailProps = {
  entry: any;
  showCircularProgress?: boolean;
  showTileTitle?: boolean;
  lineType?: string;
  nolink?: boolean;
  visible?: boolean;
  displayVodAsLandscape?: boolean;
  onThumbnailClick?: () => any;
  size?: ContentThumbnailSize;
};

function ContentThumbnail(
  {
    entry,
    showCircularProgress = false,
    showTileTitle,
    lineType = '',
    visible = true,
    displayVodAsLandscape = false,
    nolink = false,
    onThumbnailClick,
    size
  }: ContentThumbnailProps,
  ref
) {
  if (!entry) return null;

  const entryChild = entry[entry.type];
  const isLandscape = displayVodAsLandscape && entryChild?.thumbnail_orientation !== 'landscape';
  const entryType = entry.type === 'vod' && entryChild?.thumbnail_orientation === 'landscape' ? 'contents' : entry.type;
  const containerClassName = clsx('ContentThumbnailContainer', lineType, entryType, { landscape: isLandscape });

  return (
    <div ref={ref} className={containerClassName}>
      <div className="ContentThumbnail">
        {entry.isPlaceholder ? (
          <ContentThumbnailPlaceholder />
        ) : (
          <ContentThumbnailEntry
            entry={entry}
            showCircularProgress={showCircularProgress}
            showTileTitle={showTileTitle}
            lineType={lineType}
            visible={visible}
            displayVodAsLandscape={isLandscape}
            onThumbnailClick={onThumbnailClick}
            nolink={nolink}
            size={size}
          />
        )}
      </div>
    </div>
  );
}

function ContentThumbnailEntry({
  entry,
  showCircularProgress = false,
  showTileTitle,
  lineType = '',
  visible,
  displayVodAsLandscape,
  nolink,
  onThumbnailClick,
  size
}: ContentThumbnailProps) {
  const timestamp: number = useWebStoreState(state => state.channel.timestamp);
  const program = useProgram(entry);

  const [start, end] = program.getTimeRange();
  // TODO should not be there
  const thumbnailType: string = ['grid_banner', 'group_banner'].includes(lineType)
    ? 'PROMO_BANNER'
    : lineType === 'vod_portal'
    ? 'VOD_PORTAL'
    : 'THUMBNAIL';

  const thumbnail = PICTURE_FORMAT_WEB[thumbnailType];
  const entryChild = entry?.[entry?.type];
  const entryId = entryChild?.content_id ?? entryChild?.id;
  const isResumable = Boolean(entry.position); //considering as resumable if entry with position

  const thumbnailRef = React.useRef<HTMLDivElement>(null);
  const { saveEntryId } = useNavigationState();
  const { restoreThumbnailScrollPosition } = useScrollRestoration();

  const handleOnClick = () => {
    onThumbnailClick && onThumbnailClick();
    return saveEntryId(entryId);
  };

  React.useEffect(() => {
    restoreThumbnailScrollPosition(thumbnailRef?.current, entryId);
  }, [thumbnailRef?.current]);

  const programUrl = program.getUrl(import.meta.env.VITE_APPLICATION_TYPE);

  const logoUrl = React.useMemo(() => {
    let logoUrl: string;
    try {
      logoUrl = program.getIcon('light', size === 'xsmall' ? 24 : 48);
    } catch {
      logoUrl = '';
    }
    return logoUrl;
  }, [program]);

  return withLink(
    <>
      <div className={clsx('ThumbnailImageContainer', size)}>
        {displayVodAsLandscape && entry.type === 'vod' ? (
          <img
            className="ThumbnailImageBackdrop"
            alt=""
            src={program?.getBackdropUrl(PICTURE_FORMAT_WEB.VOD_THUMBNAIL_BACKDROP)}
            loading="lazy"
          />
        ) : null}
        {visible && (
          <>
            <img className="ThumbnailImage" alt="" src={program?.getThumbnailUrl(thumbnail)} loading="lazy" />
            {showCircularProgress && (
              <CircularProgress
                color={program?.channel?.color}
                currentTime={timestamp} // todo this is only for live timestamp should come from other thing and need position of content
                start={start}
                end={end}
                logoUrl={logoUrl}
                showProgress={program?.contentType === 'live'}
                size={size === 'small' ? 'xsmall' : 'small'}
              />
            )}
            {program?.isLocked && <Lock isOpen={!!program.promoToken} />}
          </>
        )}
      </div>
      {showTileTitle && program?.contentType !== 'record' ? (
        <div
          className="ContentThumbnailTitle"
          data-tooltip-id="main-tooltip"
          data-tooltip-content={program?.content?.title}
          data-tooltip-place="top-start"
        >
          <Typography variant="body2">{program?.content?.title}</Typography>
        </div>
      ) : null}
      {showTileTitle && program?.contentType === 'record' ? (
        <>
          <Typography
            className="ContentThumbnailTitle"
            bold
            color="white"
            data-tooltip-id="main-tooltip"
            data-tooltip-content={program?.content?.title}
            data-tooltip-place="top-start"
          >
            {program?.content?.title}
          </Typography>
          <Typography
            className="ContentThumbnailSubTitle"
            variant="body2"
            data-tooltip-id="main-tooltip"
            data-tooltip-content={program?.content?.sub_title}
            data-tooltip-place="top-start"
          >
            {program?.content?.sub_title}
          </Typography>
        </>
      ) : null}
    </>,
    nolink,
    programUrl,
    handleOnClick,
    isResumable,
  );
}

function withLink(children, noLink, programUrl, onClick, isResumable) {
  if (noLink) return <div onClick={onClick}>{children}</div>;
  return (
    <Link to={programUrl} state={{ isResumable }} onClick={onClick}>
      {children}
    </Link>
  );
}

function ContentThumbnailPlaceholder() {
  return <div className="ThumbnailImageContainer"></div>;
}

export default withVisibility<ContentThumbnailProps>(React.forwardRef(ContentThumbnail));
