import React from 'react';
import { useLocation, Location } from 'react-router-dom';

import Breadcrumb from '../../shared/Breadcrumb';
import ButtonBack from '../../shared/ButtonBack';

interface NavHeaderProps {
  title: string;
}

function NavHeader({ title }: NavHeaderProps) {
  const location: Location = useLocation();
  const enableBack: boolean = location.key !== 'default';

  return <Breadcrumb>{enableBack && <ButtonBack title={title} />}</Breadcrumb>;
}

export default NavHeader;
