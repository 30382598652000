import { SwiperOptions } from 'swiper/types';

const CONTENT_ROW_BREAKPOINTS: {
  [name: string]: {
    [width: number]: SwiperOptions;
    [ratio: string]: SwiperOptions;
  };
} = {
  portrait: {
    // when window width is >= 640px
    0: {
      spaceBetween: 5,
      slidesPerView: 3,
      slidesPerGroup: 3,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0
    },
    // when window width is >= 640px
    640: {
      spaceBetween: 5,
      slidesPerView: 4,
      slidesPerGroup: 4,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0
    },
    // when window width is >= 640px
    800: {
      spaceBetween: 5,
      slidesPerView: 5,
      slidesPerGroup: 5,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0
    },
    // when window width is >= 768px
    900: {
      spaceBetween: 5,
      slidesPerView: 6,
      slidesPerGroup: 6,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0
    },
    // when window width is >= 768px
    1024: {
      spaceBetween: 12,
      slidesPerView: 7,
      slidesPerGroup: 7,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0
    },
    // when window width is >= 768px
    1300: {
      spaceBetween: 12,
      slidesPerView: 10,
      slidesPerGroup: 10,
      slidesOffsetAfter: 55,
      slidesOffsetBefore: 55
    }
  },
  group: {
    // when window width is >= 640px
    0: {
      spaceBetween: 5,
      slidesPerView: 1,
      slidesPerGroup: 1,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0
    },
    // when window width is >= 640px
    640: {
      spaceBetween: 5,
      slidesPerView: 1,
      slidesPerGroup: 1,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0
    },
    // when window width is >= 768px
    966: {
      spaceBetween: 5,
      slidesPerView: 2,
      slidesPerGroup: 2,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0
    },
    // when window width is >= 768px
    1300: {
      spaceBetween: 14,
      slidesPerView: 3,
      slidesPerGroup: 3,
      slidesOffsetAfter: 55,
      slidesOffsetBefore: 55
    }
  },
  banner: {
    0: {
      spaceBetween: 0,
      slidesPerView: 1,
      slidesPerGroup: 1,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0
    },
    1300: {
      spaceBetween: 0,
      slidesPerView: 1,
      slidesPerGroup: 1,
      slidesOffsetAfter: 55,
      slidesOffsetBefore: 55,
      enabled: false
    }
  },
  default: {
    // when window width is >= 640px
    0: {
      spaceBetween: 5,
      slidesPerView: 2,
      slidesPerGroup: 2,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0
    },
    // when window width is >= 640px
    640: {
      spaceBetween: 5,
      slidesPerView: 2,
      slidesPerGroup: 2,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0
    },
    // when window width is >= 768px
    768: {
      spaceBetween: 5,
      slidesPerView: 3,
      slidesPerGroup: 3,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0
    },
    // when window width is >= 768px
    1200: {
      spaceBetween: 10,
      slidesPerView: 5,
      slidesPerGroup: 5,
      slidesOffsetAfter: 55,
      slidesOffsetBefore: 55
    }
  }
};

export default CONTENT_ROW_BREAKPOINTS;
