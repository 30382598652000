import React from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';

import { useAccountProfile, CONSTANTS, Feature } from '@oqee/core';

import { HeaderNav } from '../../utils/webUtils';

import MainLayout from '../../components/layout/MainLayout';
import Typography from '../../components/shared/Typography';
import Button from '../../components/shared/Button';
import ProfileAvatarGrid from './profile/ProfileAvatarGrid';
import './SettingsHomePage.css';
import { useModal } from '../../components/shared/Modal';
import { useWebStoreState } from '../../store/webStoreUtils';

enum SettingItemType {
  Internal,
  External
}

const SettingsItemIcon = {
  [SettingItemType.Internal]: '/img/link-right-arrow.svg',
  [SettingItemType.External]: '/img/link-new-window.svg'
};

interface SettingsGroupProps extends React.PropsWithChildren {
  label: string;
}

function SettingsGroup({ label, children }: SettingsGroupProps) {
  return (
    <div className="SettingsListElement">
      <div className="SettingsGroupTitle">
        <Typography variant="body1">{label}</Typography>
        <div className="SettingsGroupHr" />
      </div>
      <div className="SettingsLinksContainer">
        <div className="SettingsLinks">{children}</div>
      </div>
    </div>
  );
}

interface SettingsItemProps {
  label: string;
  type: SettingItemType;
}

function SettingsItem({ label, type }: SettingsItemProps) {
  return (
    <>
      <Typography variant="body1" bold className="SettingsLinkTypo">
        {label}
      </Typography>
      <img className="SettingsLinkIcon" alt="" src={SettingsItemIcon[type]} />
    </>
  );
}

interface SettingsLinkProps {
  label: string;
  href?: string;
  onClick?: () => void;
}

function SettingsLink({ label, href, onClick }: SettingsLinkProps) {
  if (href) {
    return href.startsWith('http') ? (
      <a href={href} target="_blank" className="SettingsLink" rel="noreferrer">
        <SettingsItem label={label} type={SettingItemType.External} />
      </a>
    ) : (
      <Link to={href || './'} className="SettingsLink">
        <SettingsItem label={label} type={SettingItemType.Internal} />
      </Link>
    );
  } else if (onClick) {
    return (
      <div onClick={onClick} className="SettingsLink">
        <SettingsItem label={label} type={SettingItemType.Internal} />
      </div>
    );
  }
  return null;
}

function SettingsHomePage() {
  const features: Feature[] = useWebStoreState(state => state.auth.features);
  const { activeProfile, setActive } = useAccountProfile();
  const navigate: NavigateFunction = useNavigate();
  const { Modal, openModal } = useModal({
    title: 'Erreur',
    description: 'Pour gérer vos données personnelles, veuillez désactiver votre bloqueur de publicités.',
    validateButtonLabel: 'Fermer'
  });
  const hasSubscriptionFeature = features.includes(Feature.SUBSCRIPTION);
  const hasNpvrFeature = features.includes(Feature.NPVR);

  const goToSwitchProfile = React.useCallback(
    (profileId: string) => {
      setActive(profileId);
      navigate('/home');
    },
    [navigate, setActive]
  );

  const showDidomiPreferences = () => {
    if (window?.Didomi?.preferences) {
      window.Didomi.preferences.show();
    } else {
      openModal();
    }
  };
  const logout = () => navigate('/logout');

  return (
    <MainLayout fbScreenName="settings" activeHeaderNav={HeaderNav.SETTINGS}>
      <div className="SettingsHomePage">
        <ProfileAvatarGrid activeProfile={activeProfile} onAvatarClick={goToSwitchProfile} />
        <div className="SettingsListContainer">
          <div className="SettingsList">
            <SettingsGroup label="Profils">
              <SettingsLink label="Ajouter un profil" href="/settings/profile/create" />
              <SettingsLink label="Modifier les profils" href="/settings/profile" />
              <SettingsLink label="Affichage au démarrage" href="/settings/profile/default" />
            </SettingsGroup>
            {hasSubscriptionFeature && (
              <SettingsGroup label="Abonnements et codes">
                <SettingsLink label="Mes abonnements" href="/settings/subscriptions" />
                <SettingsLink label="Code parental" href={CONSTANTS.PARENTAL_CODE_REMINDER_LINK} />
                <SettingsLink label="Code d’achat" href="/settings/purchase_code" />
              </SettingsGroup>
            )}
            {hasNpvrFeature && (
              <SettingsGroup label="Consommation">
                <SettingsLink label="Enregistrement" href="/settings/npvr_usage" />
              </SettingsGroup>
            )}
            <SettingsGroup label="Confidentialité">
              <SettingsLink label="Gérer mes données personnelles" onClick={showDidomiPreferences} />
              <SettingsLink label="Communication" href="/settings/newsletter" />
            </SettingsGroup>

            <SettingsGroup label="Aide">
              <SettingsLink label="Assistance OQEE by Free" href="https://assistance.oqee.tv/" />
            </SettingsGroup>
          </div>
        </div>
        <div className="SettingsFooter">
          <Button type="secondary" label={'Se déconnecter'} onClick={logout} />
          <Typography variant="body3" color="grey-3">
            {import.meta.env.VITE_RELEASE} ({import.meta.env.VITE_GIT_SHORT_REF})
          </Typography>
        </div>
        <Modal />
      </div>
    </MainLayout>
  );
}

export default SettingsHomePage;
