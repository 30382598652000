import React from 'react';

import { Link } from 'react-router-dom';

import Typography from '../../Typography';

import './ListItem.css';

interface ListItemProps {
  item: ListItemData;
}

interface ListItemData {
  id: number | string;
  title: string;
  subtitle: string;
  textAction: string;
  to: string;
  onClick: () => void;
}

function ListItem({ item: { title, subtitle, textAction, to, onClick } }: ListItemProps) {
  const Item = ({ children }) =>
    to || onClick ? (
      <Link to={to} className="ListItem" onClick={onClick}>
        {children}
      </Link>
    ) : (
      <div className="ListItem">{children}</div>
    );

  return (
    <Item>
      <div className="ListItemMetadata">
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body2" className="ListItemSubTitle">
          {subtitle}
        </Typography>
      </div>
      <div className="ListItemActions">
        <Typography variant="body2">{textAction}</Typography>
      </div>
    </Item>
  );
}

export default ListItem;
export type { ListItemData };
