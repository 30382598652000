import { useReplayPortalItem, APIReplayTile, PICTURE_FORMAT_WEB } from '@oqee/core';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import './ReplayHomeTile.css';
import useNavigationState from '../../../hooks/useNavigationState';
import useScrollRestoration from '../../../hooks/useScrollRestoration';

interface ReplayHomeTileProps {
  tile: APIReplayTile;
  entryId: string | number;
  homePathname: string;
}

// eslint-disable-next-line react/display-name
function ReplayHomeTile({ tile, entryId, homePathname }: ReplayHomeTileProps) {
  const portal = useReplayPortalItem(tile);

  const linkUrl: string = tile.type === 'home_tile' ? `/home/replay/${homePathname}/${entryId}` : `/replay/${entryId}`;

  const thumbnailRef = useRef<HTMLDivElement>(null);
  const { saveEntryId } = useNavigationState();
  const { restoreThumbnailScrollPosition } = useScrollRestoration();

  useEffect(() => {
    restoreThumbnailScrollPosition(thumbnailRef?.current, entryId);
  }, [thumbnailRef?.current]);

  return (
    <div
      className="ReplayHomeTile"
      ref={thumbnailRef}
      aria-label={portal.name}
      data-test-id={`replay-portal-${portal.id}`}
    >
      <Link to={linkUrl} onClick={() => saveEntryId(entryId)}>
        <img src={portal.getTileUrl(PICTURE_FORMAT_WEB.TILE)} alt="" className="ReplayHomeTileImage" />
      </Link>
    </div>
  );
}

function ReplayHomeTilePlaceholder() {
  return <div className="ReplayHomeTile"></div>;
}

export { ReplayHomeTile, ReplayHomeTilePlaceholder };
