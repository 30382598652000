import React from 'react';

import { MyListNav } from '../../utils/webUtils';

import MyListLayout from '../../components/layout/MyListLayout';
import EmptyResult from '../../components/shared/EmptyResult';

const MyListHomePage = () => (
  <MyListLayout pageId={MyListNav.ALL} filter={['all']} fallback={<EmptyResult title="Votre liste est vide" />} />
);

export default MyListHomePage;
