import {api, CONSTANTS, platform} from '@oqee/core';
import { APIEvent } from '@oqee/core/event';

function getAuthenticationToken() {
  return api.request.getRequestToken(CONSTANTS.AUTH.OQEE);
}

const config = {
  apiUrl: import.meta.env.VITE_EVENT_API_URL
};

const apiEvent = new APIEvent(
  platform.name,
  import.meta.env.VITE_VERSION,
  getAuthenticationToken,
  config
);

export default apiEvent;
