import React from 'react';
import shaka from 'shaka-player/dist/shaka-player.ui';
import { ChannelPlaybackInfo } from '@oqee/core';
import { ShakaPlayerProps } from '../ShakaPlayer';

const Go10sBack = class extends shaka.ui.Element {
  constructor(parent: HTMLDivElement, controls: shaka.ui.Controls, playbackInfo: ChannelPlaybackInfo) {
    super(parent, controls);
    const isSeekEnabled: boolean =
      playbackInfo?.broadcastType !== 'live' || !!playbackInfo?.liveChannel?.isStartOverAllowed;
    const this_ = this as any;
    this_.button_ = document.createElement('button');
    this_.button_.id = 'oqee-go10sBack-button';
    this_.button_.classList.add('oqee-10s-button');
    if (!isSeekEnabled) {
      this_.button_.classList.add('disabledGray');
      this_.button_.disabled = true;
    }
    this_.button_.setAttribute('data-tooltip-id', 'player-tooltip');
    this_.button_.setAttribute('data-tooltip-content', isSeekEnabled ? '-10 secondes' : 'Fonctionnalité désactivée');

    const img = document.createElement('img');
    img.src = '/img/player/10s-back.svg';
    this_.button_.appendChild(img);

    parent.appendChild(this_.button_);

    this_.eventManager.listen(this_.button_, 'click', () => {
      this_.video.currentTime = this_.video.currentTime - 10;
    });
  }
} as any;

const Go10sFwd = class extends shaka.ui.Element {
  constructor(parent: HTMLDivElement, controls: shaka.ui.Controls, playbackInfo: ChannelPlaybackInfo) {
    super(parent, controls);
    const isSeekEnabled: boolean =
      playbackInfo?.broadcastType !== 'live' || !!playbackInfo?.liveChannel?.isStartOverAllowed;
    const this_ = this as any;
    this_.button_ = document.createElement('button');
    this_.button_.id = 'oqee-go10sFwd-button';
    this_.button_.classList.add('oqee-10s-button');
    if (!isSeekEnabled) {
      this_.button_.classList.add('disabledGray');
      this_.button_.disabled = true;
    }
    this_.button_.setAttribute('data-tooltip-id', 'player-tooltip');
    this_.button_.setAttribute('data-tooltip-content', isSeekEnabled ? '+10 secondes' : 'Fonctionnalité désactivée');

    const img = document.createElement('img');
    img.src = '/img/player/10s-fwd.svg';
    this_.button_.appendChild(img);

    parent.appendChild(this_.button_);

    this_.eventManager.listen(this_.button_, 'click', () => {
      this_.video.currentTime = this_.video.currentTime + 10;
    });
  }
} as any;

const CommonFactory = class {
  playbackInfo_: ChannelPlaybackInfo;

  constructor(playbackInfo: ChannelPlaybackInfo) {
    this.playbackInfo_ = playbackInfo;
  }
};

Go10sBack.Factory = class extends CommonFactory {
  create(rootElement, controls) {
    return new Go10sBack(rootElement, controls, this.playbackInfo_);
  }
};
Go10sFwd.Factory = class extends CommonFactory {
  create(rootElement, controls) {
    return new Go10sFwd(rootElement, controls, this.playbackInfo_);
  }
};

interface ShakaPlayerUi10sButtonsProps extends ShakaPlayerProps {}

function ShakaPlayerUi10sButtons({ playbackInfo }: ShakaPlayerUi10sButtonsProps) {
  React.useEffect(() => {
    // Register our factory with the controls, so controls can create button instances.
    shaka.ui.Controls.registerElement(
      /* This name will serve as a reference to the button in the UI configuration object */
      'oqee_10s_back_button',
      new Go10sBack.Factory(playbackInfo)
    );
    shaka.ui.Controls.registerElement(
      /* This name will serve as a reference to the button in the UI configuration object */
      'oqee_10s_fwd_button',
      new Go10sFwd.Factory(playbackInfo)
    );
  }, [playbackInfo]);

  return null;
}

export default ShakaPlayerUi10sButtons;
