import { ChannelSubscriptionContext } from '@oqee/core';
import React from 'react';
import { useLocation, Location } from 'react-router-dom';
import ChannelSubscription from '../../player/ChannelSubscription';

interface ChannelSubscriptionGuardProps extends React.PropsWithChildren {
  channelNumber: number;
  onSuccess: () => void;
  onCancel: () => void;
  displaySubscription?: boolean;
}

function ChannelSubscriptionGuard({
  channelNumber,
  children,
  onSuccess,
  onCancel,
  displaySubscription
}: ChannelSubscriptionGuardProps) {
  const location: Location = useLocation();

  const context = React.useMemo(
    () => ({
      baseUrl: location.pathname,
      channelNumber,
      onSuccess,
      onCancel
    }),
    [channelNumber]
  );

  return (
    <div>
      {children}
      {
        <ChannelSubscriptionContext.Provider value={context}>
          {displaySubscription && <ChannelSubscription />}
        </ChannelSubscriptionContext.Provider>
      }
    </div>
  );
}

export default ChannelSubscriptionGuard;
