import { IUserStatus } from '@didomi/react';

function getConsentString(): string | undefined {
  const didomiUserStatus: IUserStatus | undefined = window.Didomi?.getUserStatus();
  return didomiUserStatus?.consent_string;
}

function getAdditionalConsentString(): string | undefined {
  const didomiUserStatus: IUserStatus | undefined = window.Didomi?.getUserStatus();
  const globalConsentEnabled: string[] | undefined = didomiUserStatus?.vendors?.['global_consent']?.enabled;
  const additional_consent_string: string = globalConsentEnabled?.filter(e => /^c:/.test(e)).join(',') ?? '';
  const additionalConsentStringVersion = 1;

  return window.Didomi ? `${additionalConsentStringVersion}~${additional_consent_string}` : undefined;
}

function isOqeeCustomization(): boolean {
  const didomiUserStatus: IUserStatus | undefined = window.Didomi?.getUserStatus();
  return !!didomiUserStatus?.purposes?.consent?.enabled?.includes('oqee_customization');
}

export { getConsentString, getAdditionalConsentString, isOqeeCustomization };
