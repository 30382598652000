import React from 'react';
import ContentThumbnail from '../ContentThumbnail';

import './ContentListItem.css';
import { MixedLine, Typography } from '@oqee/ui';
import { useProgram } from '@oqee/core';

import './ContentListItem.css';
import ProgramCTA from '../../cta/ProgramCTA';

interface ContentListItemProps {
  entry: any;
  program?: any;
  showCircularProgress?: boolean;
}

function ContentListItem({ entry, program: mainProgram, showCircularProgress = false }: ContentListItemProps) {
  const program = useProgram(entry);
  if (!program) return <></>;
  const { content } = program;
  const { entity_title, entity_corner = { items: [] }, entity_dataline = { items: [] } } = content;

  const mixedLine = {
    type: 'mixedLine',
    items: [...entity_corner.items, ...entity_dataline.items]
  };

  return (
    <div className="ContentListItem" data-test-id={`content-list-item-${content.id}`}>
      <div className="ContentListItemContainer">
        <ContentThumbnail
          entry={entry}
          showCircularProgress={showCircularProgress && ['replay', 'live'].includes(entry.type)}
          nolink
          size="small"
        />
        <div className="ContentListItemDetails">
          <Typography variant="body1">{entity_title}</Typography>
          <div className="ContentListMixedLine">
            <MixedLine mixedLine={mixedLine} program={program} />
          </div>
        </div>
        <ProgramCTA mainProgram={mainProgram} program={program} rounded displayInformation />
      </div>
    </div>
  );
}

export default ContentListItem;
