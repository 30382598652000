import React, { useEffect, useMemo } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Mousewheel, Navigation, FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import clsx from 'clsx';

import useNavigationState from '../../../hooks/useNavigationState';
import useScrollRestoration from '../../../hooks/useScrollRestoration';

import Typography from '../../shared/Typography';
import ContentThumbnail from '../ContentThumbnail';

import './ContentRow.css';
import CONTENT_ROW_BREAKPOINTS from './Breakpoints';

interface TileProps extends React.PropsWithChildren {
  rowId: string;
  entries: any[];
  children: JSX.Element;
}

function Tile({ children, rowId, entries }: TileProps) {
  const swiper = useSwiper();
  const { restoreRowScrollPosition } = useScrollRestoration();
  useEffect(() => {
    restoreRowScrollPosition(swiper, rowId, entries);
  }, [swiper]);

  return children;
}

interface ContentRowProps {
  row: any;
  showTileTitle?: boolean;
  notAvailableProviders: Array<any>;
  showTileTitleInRows: Array<string>;
  hideCircularProgressInRows: Array<string>;
  showCircularProgress?: boolean;
}

function ContentRow({
  row,
  showTileTitle = true,
  showTileTitleInRows,
  hideCircularProgressInRows,
  showCircularProgress = false
}: ContentRowProps) {
  const { saveRowId } = useNavigationState();

  const rowKey = `${row.key || row.id || row.name}`;
  const isHead = row.type === 'head';
  const isContent = row.type === 'contents';
  const entryType = row?.entries?.[0]?.type;
  const isRecord = row?.entries?.[0]?.type === 'record';

  const isPlaceHolder = row?.entries?.[0]?.isPlaceholder;

  const showTitle = showTileTitleInRows.includes(row.key) || showTileTitle;

  const breakpoint = useMemo<string>(() => {
    const isGroup = row.type === 'groups';
    const isVod = ['vod', 'vod_purchase'].includes(entryType);
    const isPortrait = isHead || isContent || isVod;
    const isBanner = ['group_banner', 'grid_banner'].includes(row.type);
    return isPortrait ? 'portrait' : isGroup ? 'group' : isBanner ? 'banner' : 'default';
  }, [row]);

  const displayCircularProgress = showCircularProgress && !hideCircularProgressInRows.includes(rowKey);

  return (
    <div className="RowDiv">
      {row?.title ? (
        <div className="RowTitle">
          <Typography variant="h3">{!isPlaceHolder && row?.title}</Typography>
        </div>
      ) : null}
      <div className={clsx('RowSlides', [showTitle && 'hasTitle', isRecord && 'hasSubtitle'])}>
        <Swiper
          key={`row_${row.id}`}
          id={`row_${row.id}`}
          onClick={() => saveRowId(row.id)}
          breakpoints={CONTENT_ROW_BREAKPOINTS[breakpoint]}
          className={row.type && `swiper-${row.type}`}
          modules={[Navigation, Mousewheel, FreeMode]}
          navigation={breakpoint !== 'banner'}
          slidesPerView="auto"
          mousewheel={{
            forceToAxis: true
          }}
          freeMode={{
            enabled: true
          }}
        >
          {row?.entries?.map((entry, entryIndex) => {
            // EntryIndex is used for placeHolder
            const { content_id, id } = (entry && entry[entry.type]) || {};
            const key = row.id + (id || content_id || entryIndex);

            return (
              <SwiperSlide key={key} data-test-id={key}>
                <Tile rowId={row.id} entries={row.entries}>
                  <ContentThumbnail
                    entry={entry}
                    showCircularProgress={displayCircularProgress}
                    showTileTitle={showTitle}
                    lineType={row.type}
                  />
                </Tile>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default ContentRow;
