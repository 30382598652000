import React from 'react';
import { useParams } from 'react-router-dom';
import { APIReplayHomePortal, useReplayPortalItem } from '@oqee/core';

import MainLayout from '../../../components/layout/MainLayout';
import ContentRows, { CONTENT_ROWS_PLACEHOLDERS } from '../../../components/content/ContentRows';
import { useWebStoreActions, useWebStoreState } from '../../../store/webStoreUtils';
import NavHeader from '../../../components/layout/NavHeader';
import { HeaderNav } from '../../../utils/webUtils';
import ParentalGuard from '../../../components/shared/ParentalGuard';

import './ReplayPortalPage.css';
import { ThunkCreator } from 'easy-peasy';

type ReplayPortalPageParams = {
  strPortalId: string;
};

function ReplayPortalPage() {
  const { strPortalId } = useParams<ReplayPortalPageParams>();

  const portalId = Number(strPortalId);
  const fetchPortal: ThunkCreator<number, any> = useWebStoreActions(actions => actions.replay.fetchPortal);
  const storePortal: APIReplayHomePortal | null = useWebStoreState(state => state.replay.portal);

  const portal = useReplayPortalItem(storePortal);
  const isReady: boolean = portal ? portal.id === portalId : false;

  React.useEffect(() => {
    if (!isReady) {
      fetchPortal(portalId);
    }
  }, [isReady, portalId, fetchPortal]);

  return (
    <MainLayout activeHeaderNav={HeaderNav.REPLAY} fbScreenName={`replay_portal_${strPortalId}`}>
      <div className="ReplayPortalPage">
        {isReady ? (
          <ParentalGuard isAdult={!!storePortal?.need_parental_code}>
            <NavHeader title={portal?.name} />
            <ContentRows rows={portal.getSections()} />
          </ParentalGuard>
        ) : (
          <ContentRows rows={CONTENT_ROWS_PLACEHOLDERS} />
        )}
      </div>
    </MainLayout>
  );
}

export default ReplayPortalPage;
