import React from 'react';
import shaka from 'shaka-player/dist/shaka-player.ui';
import {
  PlaybackInfo,
  APIPlaybackInfoThumbnail,
  APIEpgEntry,
  formatTimeHM,
  formatDurationHMS,
  formatTimeHMS
} from '@oqee/core';

import { webStore } from '../../../../store/webStoreUtils';
import { BrowserContext, BrowserContextType } from '../../../context/BrowserContextProvider';
import { absoluteTimeToBrowserTime, browserTimeToAbsoluteTime } from '../../../../utils/playerUtils';

const THUMBNAIL_WIDTH = 258;
const THUMBNAIL_HEIGHT = 145;
const COLORS = {
  played: '#FFFFFF',
  live: '#EB292F',
  transparant: 'rgba(0, 0, 0, 0)'
};

const SeekBar = class extends shaka.ui.SeekBar {
  oqeePlaybackInfo_: PlaybackInfo;
  oqeeThumbnailBar_: HTMLElement;
  oqeeThumbnailImage_: HTMLElement;
  oqeeThumbnailTime_: HTMLElement;
  oqeeTimeBoundaryStart_: HTMLElement;
  oqeeTimeBoundaryEnd_: HTMLElement;
  oqeeLiveCursorTime_: HTMLElement;
  oqeeLiveBar_: HTMLInputElement;
  oqeeLiveLabel_: HTMLElement;
  oqeeBrowserContext_: BrowserContextType;

  constructor(
    parent: HTMLDivElement,
    controls: shaka.ui.Controls,
    playbackInfo: PlaybackInfo,
    browserContext: BrowserContextType
  ) {
    super(parent, controls);
    this.oqeePlaybackInfo_ = playbackInfo;
    this.oqeeBrowserContext_ = browserContext;

    this.oqeeThumbnailBar_ = makeDiv('oqee-thumbnail-container');
    this.oqeeThumbnailImage_ = makeDiv('oqee-thumbnail-image');
    this.oqeeThumbnailTime_ = makeDiv('oqee-thumbnail-time', ['Typography', 'h4', 'bold']);
    this.oqeeTimeBoundaryStart_ = makeDiv('oqee-timeBoundary-start', ['Typography', 'body2', 'bold', 'color-grey-2']);
    this.oqeeTimeBoundaryEnd_ = makeDiv('oqee-timeBoundary-end', ['Typography', 'body2', 'bold', 'color-grey-2']);
    this.oqeeLiveCursorTime_ = makeLiveCursorTime('oqee-live-cursor-time', ['Typography', 'body2', 'bold']);
    this.oqeeLiveBar_ = makeLiveBar('oqee-live-bar', this.bar);
    this.oqeeLiveLabel_ = makeLiveLabel('oqee-live-label');

    this.oqeeThumbnailBar_.appendChild(this.oqeeThumbnailImage_);
    this.oqeeThumbnailBar_.appendChild(this.oqeeThumbnailTime_);
    this.container.appendChild(this.oqeeThumbnailBar_);
    this.container.appendChild(this.oqeeTimeBoundaryStart_);
    this.container.appendChild(this.oqeeTimeBoundaryEnd_);

    parent.insertBefore(this.container, parent.childNodes[1]);

    if (playbackInfo.broadcastType === 'live' && !playbackInfo?.liveChannel?.isStartOverAllowed) {
      this.container.classList.add('disabledGray');
      this.bar.classList.add('disabledGray');
      this.bar.disabled = true;
      this.bar.setAttribute('data-tooltip-id', 'player-tooltip');
      this.bar.setAttribute('data-tooltip-content', "Cette fonctionnalité n'est pas disponible pour cette chaîne");
      this.bar.setAttribute('data-tooltip-float', 'true');
    }

    if (playbackInfo.broadcastType === 'live') {
      this.container.appendChild(this.oqeeLiveLabel_);
      this.container.appendChild(this.oqeeLiveCursorTime_);
      this.container.insertBefore(this.oqeeLiveBar_, this.bar); // insert liveBar before seekBar so that the click affects the latter

      // for live playback update live cursor time on each time update
      this.video!.addEventListener('timeupdate', () => {
        const absoluteCursorTime: number = browserTimeToAbsoluteTime(
          this.getValue(),
          browserContext.selectedStreamingType,
          playbackInfo.broadcastType
        );
        this.oqeeLiveCursorTime_.textContent = formatTimeHMS(absoluteCursorTime);
        const timeRect: DOMRect = this.oqeeLiveCursorTime_.getBoundingClientRect();
        const min: number = parseFloat(this.bar.min);
        const max: number = parseFloat(this.bar.max);

        if (max > 0) {
          const barRect: DOMRect = this.bar.getBoundingClientRect();
          const value: number = Math.round(this.getValue());
          const scale: number = (max - min) / barRect.width;
          const cursorPosition: number = (value - min) / scale;
          const timePosition: number = cursorPosition - timeRect.width / 2; // remove half of time width in order to center element
          const timePositionConstrained = `clamp(0px, ${timePosition}px, calc(100% - ${timeRect.width}px ))`; // prevent cursor time from exceeding seekBar div

          handleOverlapingElements(
            this.oqeeLiveLabel_,
            this.oqeeLiveCursorTime_,
            (_, liveCursorTime) => {
              liveCursorTime.style.left = timePositionConstrained;
            },
            false
          );
        }
      });

      // update live bar each second
      const intervalId = setInterval(() => {
        if (this.player) {
          const { start, end } = this.oqeeGetSeekRange();
          const seekRangeSize: number = end - start;
          const liveTime: number = this.oqeeGetLiveTime();
          const livePercent: number = ((liveTime - start) / seekRangeSize) * 100;
          const liveLabelRect: DOMRect = this.oqeeLiveLabel_.getBoundingClientRect();

          this.oqeeLiveBar_.value = absoluteTimeToBrowserTime(
            liveTime,
            this.oqeeBrowserContext_.selectedStreamingType,
            playbackInfo.broadcastType
          ).toString();
          this.oqeeLiveBar_.style.background = `linear-gradient(to right, ${COLORS.live} 0%, ${COLORS.live} ${livePercent}%, ${COLORS.transparant} ${livePercent}%, ${COLORS.transparant} 100%)`;
          this.oqeeLiveBar_.style.visibility = 'unset';

          handleOverlapingElements(
            this.oqeeLiveLabel_,
            this.oqeeLiveCursorTime_,
            (liveLabel, _) => {
              liveLabel.style.left = `calc(${livePercent}% - ${liveLabelRect.width / 2}px)`; // remove half of time width in order to center element
            },
            true
          );
        }
      }, 1000);

      // stop interval updating live bar when player unloads
      this.player!.addEventListener('unloading', () => {
        clearInterval(intervalId);
      });

      // disable seeking past live bar value
      this.bar.addEventListener('input', (event: any) => {
        if (event.target.value > this.oqeeLiveBar_.value) {
          event.target.value = this.oqeeLiveBar_.value;
          event.stopPropagation();
        }
      });
    }

    // show thumbnails when mouse hovers on seek bar
    this.bar.addEventListener('mousemove', (event: MouseEvent) => {
      if (!playbackInfo.thumbnails) {
        this.oqeeHideThumbnail();
        return;
      }
      const rect: DOMRect = this.bar.getBoundingClientRect();
      const min: number = parseFloat(this.bar.min);
      const max: number = parseFloat(this.bar.max);
      const mousePosition: number = event.clientX - rect.left;
      const scale: number = (max - min) / rect.width;
      const value: number = Math.round(min + scale * mousePosition);
      this.oqeeShowThumbnail(mousePosition, value);
    });

    // hide thumbnails when mouse leaves seek bar
    this.container.addEventListener('mouseleave', () => {
      this.oqeeHideThumbnail();
    });
  }

  /**
   * Update the video element's state to match the input element's state.
   * Called by the base class when the input element changes.
   *
   * @override
   */
  onChange() {
    super.onChange();

    if (this.oqeePlaybackInfo_.thumbnails) {
      const min: number = parseFloat(this.bar.min);
      const max: number = parseFloat(this.bar.max);
      const rect: DOMRect = this.bar.getBoundingClientRect();
      const value: number = Math.round(this.getValue());
      const scale: number = (max - min) / rect.width;
      const position = (value - min) / scale;
      this.oqeeShowThumbnail(position, value);
    } else {
      this.oqeeHideThumbnail();
    }
  }

  /**
   * Update the seekBar UI
   * @override
   */
  update() {
    const absoluteCursorTime: number = this.oqeeBrowserContext_
      ? browserTimeToAbsoluteTime(
          this.getValue(),
          this.oqeeBrowserContext_.selectedStreamingType,
          this.oqeePlaybackInfo_.broadcastType
        )
      : this.getValue();
    const bufferedLength: number = this.video!.buffered.length;
    const seekRange = this.oqeeGetSeekRange();
    const seekRangeSize: number = seekRange.end - seekRange.start;

    if (this.oqeeBrowserContext_) {
      const streamingType = this.oqeeBrowserContext_.selectedStreamingType;
      this.setRange(
        absoluteTimeToBrowserTime(seekRange.start, streamingType, this.oqeePlaybackInfo_.broadcastType),
        absoluteTimeToBrowserTime(seekRange.end, streamingType, this.oqeePlaybackInfo_.broadcastType)
      );
    }

    if (this.oqeeTimeBoundaryStart_ && this.oqeeTimeBoundaryEnd_) {
      this.oqeeTimeBoundaryStart_.textContent = this.oqeeFormatStartTime();
      this.oqeeTimeBoundaryEnd_.textContent = this.oqeeFormatEndTime();
    }

    if (!this.oqeeShouldBeDisplayed_()) {
      SeekBar.oqeeSetDisplay(this.container, false);
    } else {
      SeekBar.oqeeSetDisplay(this.container, true);

      if (bufferedLength == 0) {
        this.bar.style.background = COLORS.transparant;
      } else {
        const clampedCursorTime: number = Math.min(Math.max(absoluteCursorTime, seekRange.start), seekRange.end);
        const cursorDistance: number = clampedCursorTime - seekRange.start;
        // NOTE: the fallback to zero eliminates NaN.
        const cursorFraction: number = cursorDistance / seekRangeSize || 0;

        const gradient: string[] = [
          'to right',
          this.oqeeMakeColor_(COLORS.played, cursorFraction),
          this.oqeeMakeColor_(COLORS.transparant, cursorFraction)
        ];
        this.bar.style.background = 'linear-gradient(' + gradient.join(',') + ')';
      }
    }
  }

  /**
   * @override
   */
  setRange(min, max) {
    // update seek bar min&max
    this.bar.min = min;
    this.bar.max = max;

    if (this.oqeeLiveBar_) {
      // update live bar min&max
      this.oqeeLiveBar_.min = min;
      this.oqeeLiveBar_.max = max;
    }
  }

  /**
   * @returns player seekRange, limited to current program for live
   */
  oqeeGetSeekRange() {
    const playerSeekRange: shaka.extern.BufferedRange = this.player!.seekRange();
    const currentEpgProgram: APIEpgEntry | null = webStore.getState().webPlayer.currentEpgProgram;

    if (
      this.oqeePlaybackInfo_?.broadcastType === 'live' &&
      currentEpgProgram?.live.start &&
      currentEpgProgram?.live.end
    ) {
      return { start: currentEpgProgram.live.start, end: currentEpgProgram.live.end };
    } else {
      return playerSeekRange;
    }
  }

  oqeeGetLiveTime(): number {
    // real position of live playback is approx. 10 sec before actual current time
    return Date.now() / 1000 - 10;
  }

  /**
   * @param {string} color
   * @param {number} fract
   * @return {string}
   * @private
   */
  oqeeMakeColor_(color, fract) {
    return color + ' ' + fract * 100 + '%';
  }

  /**
   * @return {boolean}
   * @private
   */
  oqeeShouldBeDisplayed_() {
    // The seek bar should be hidden when there's an ad playing.
    return this.ad == null || !this.ad.isLinear();
  }

  /**
   * Depending on the value of display, sets/removes the css class of element to
   * either display it or hide it.
   *
   * @param {Element} element
   * @param {boolean} display
   */
  static oqeeSetDisplay(element, display) {
    if (!element) {
      return;
    }

    if (display) {
      // Removing a non-existent class doesn't throw, so, even if
      // the element is not hidden, this should be fine.
      element.classList.remove('shaka-hidden');
    } else {
      element.classList.add('shaka-hidden');
    }
  }

  async oqeeShowThumbnail(pixelPosition: number, value: number) {
    if (!this.oqeePlaybackInfo_.thumbnails || !this.player) {
      this.oqeeHideThumbnail();
      return;
    }
    // hide controls behind thumbnails
    this.oqeeSetTimelineUpperControlsVisibility('hidden');

    if (value < 0) {
      value = 0;
    }
    const thumbnails: APIPlaybackInfoThumbnail = this.oqeePlaybackInfo_.thumbnails;
    const seekRange = this.oqeeGetSeekRange();
    const playerValue = Math.max(Math.ceil(seekRange.start), Math.min(Math.floor(seekRange.end), value));

    this.oqeeThumbnailTime_.textContent = formatDurationHMS(value);

    const offsetTop = -60;
    const leftPosition = Math.min(
      this.bar.offsetWidth - THUMBNAIL_WIDTH,
      Math.max(0, pixelPosition - THUMBNAIL_WIDTH / 2)
    );
    // set thumbnail container position
    this.oqeeThumbnailBar_.style.top = -(THUMBNAIL_HEIGHT - offsetTop) + 'px';
    this.oqeeThumbnailBar_.style.left = leftPosition + 'px';
    this.oqeeThumbnailBar_.style.visibility = 'unset';

    const snapshotCount = Math.floor((seekRange.end - seekRange.start) / thumbnails.interval);
    const currentTimePercentRatio = (playerValue * 100) / (seekRange.end - seekRange.start) / 100;
    const index = Math.floor(snapshotCount * currentTimePercentRatio);

    const thumbnailCount = thumbnails.nb_rows * thumbnails.nb_columns;
    const tilesetNumber = Math.floor(index / thumbnailCount) + 1;
    const tilesetUrl = thumbnails.pattern.replace(/%(.)(\d+)d/, (match, char, times) => {
      return (char.repeat(Number(times)) + tilesetNumber).slice(-times);
    });

    const rows = thumbnails.nb_rows;
    const columns = thumbnails.nb_columns;
    const positionX = (index % columns) * THUMBNAIL_WIDTH;
    const positionY = Math.floor(index / columns) * THUMBNAIL_HEIGHT;
    const sizeX = THUMBNAIL_WIDTH * columns;
    const sizeY = THUMBNAIL_HEIGHT * rows;

    // set thumbnail image size and background
    this.oqeeThumbnailImage_.style.height = THUMBNAIL_HEIGHT + 'px';
    this.oqeeThumbnailImage_.style.width = THUMBNAIL_WIDTH + 'px';
    this.oqeeThumbnailImage_.style.backgroundImage = `url(${tilesetUrl})`;
    this.oqeeThumbnailImage_.style.backgroundPosition = `-${positionX}px -${positionY}px`;
    this.oqeeThumbnailImage_.style.backgroundSize = `${sizeX}px ${sizeY}px`;
  }

  oqeeHideThumbnail() {
    this.oqeeThumbnailBar_.style.visibility = 'hidden';
    this.oqeeThumbnailTime_.textContent = '';

    // show controls behind thumbnails
    this.oqeeSetTimelineUpperControlsVisibility('unset');
  }

  /**
   * show or hide controls that are on top of the timeline depending on ether thumbnail is showed
   * @param visibility
   */
  oqeeSetTimelineUpperControlsVisibility(visibility: 'unset' | 'hidden') {
    ['shaka-small-play-button', 'oqee-10s-button', 'oqee-vertical-volume-container', 'shaka-fullscreen-button'].forEach(
      (className: string) => {
        const elements = document.getElementsByClassName(className) as HTMLCollectionOf<HTMLElement>;
        for (const element of elements) {
          element.style.visibility = visibility;
        }
      }
    );
  }

  oqeeFormatStartTime(): string {
    const seekRange = this.oqeeGetSeekRange();
    if (this.oqeePlaybackInfo_.broadcastType === 'live') {
      return formatTimeHM(seekRange.start);
    }
    if (this.oqeePlaybackInfo_.broadcastType === 'record') {
      return formatDurationHMS(this.getValue() - seekRange.start);
    }
    return formatDurationHMS(this.getValue());
  }

  oqeeFormatEndTime(): string {
    const seekRange = this.oqeeGetSeekRange();
    const duration = seekRange.end - seekRange.start;
    if (this.oqeePlaybackInfo_.broadcastType === 'live') {
      return formatTimeHM(seekRange.end);
    }
    if (this.oqeePlaybackInfo_.broadcastType === 'record') {
      return '-' + formatDurationHMS(duration - (this.getValue() - seekRange.start));
    }
    return '-' + formatDurationHMS(duration - this.getValue());
  }
};

const SeekBarFactory = class {
  timelineBar_: HTMLDivElement;
  playbackInfo_: PlaybackInfo;
  browserContext_: BrowserContextType;

  constructor(timelineBar: HTMLDivElement, playbackInfo: PlaybackInfo, browserContext: BrowserContextType) {
    this.timelineBar_ = timelineBar;
    this.playbackInfo_ = playbackInfo;
    this.browserContext_ = browserContext;
  }
  create(rootElement, controls) {
    return new SeekBar(this.timelineBar_, controls, this.playbackInfo_, this.browserContext_);
  }
};

interface ShakaPlayerUiTimelineProps {
  timelineBar: HTMLDivElement;
  playbackInfo: PlaybackInfo;
}

function ShakaPlayerUiTimeline({ timelineBar, playbackInfo }: ShakaPlayerUiTimelineProps) {
  const browserContext: BrowserContextType = React.useContext(BrowserContext);

  React.useEffect(() => {
    shaka.ui.Controls.registerSeekBar(new SeekBarFactory(timelineBar, playbackInfo, browserContext));
  }, []);

  return null;
}

function makeDiv(id: string, classList?: string[]): HTMLDivElement {
  const element: HTMLDivElement = document.createElement('div');
  element.id = id;
  if (classList) element.classList.add(...classList);
  return element;
}

function makeLiveCursorTime(id: string, classList?: string[]): HTMLDivElement {
  const liveCursorTime: HTMLDivElement = makeDiv(id, classList);
  liveCursorTime.style.visibility = 'hidden'; // hide element at first
  return liveCursorTime;
}

function makeLiveLabel(id: string): HTMLElement {
  const liveLabelDiv: HTMLImageElement = document.createElement('img');
  liveLabelDiv.id = id;
  liveLabelDiv.src = '/img/player/live-label-large.svg';
  liveLabelDiv.style.visibility = 'hidden'; // hide element at first
  return liveLabelDiv;
}

function makeLiveBar(id: string, seekBar: HTMLInputElement): HTMLInputElement {
  const liveBarInput: HTMLInputElement = document.createElement('input');
  liveBarInput.id = id;
  liveBarInput.classList.add(...seekBar.classList);
  liveBarInput.type = seekBar.type;
  liveBarInput.step = seekBar.step;
  liveBarInput.min = seekBar.min;
  liveBarInput.max = seekBar.max;
  liveBarInput.style.visibility = 'hidden'; // hide element at first
  return liveBarInput;
}

/**
 * Function called when one element between liveLabel and liveCursorTime has to be moved.
 * The element will be moved while handling overlaping of these elements:
 * the rule is that during timeshifting we should hide liveLabel whenever there is an overlap, and show it otherwise
 *
 * @param liveLabel  HTMLElement representing live label
 * @param liveCursorTime  HTMLElement representing live live cursor time
 * @param moveOneElement  Function that will move one of the 2 elements
 * @param showLiveLabelIfHidden   prevents from showing live label if its initially hidden (value will be false when live cursor is the one to be moved)
 * @returns
 */
function handleOverlapingElements(
  liveLabel: HTMLElement,
  liveCursorTime: HTMLElement,
  moveOneElement: (liveLabel: HTMLElement, liveCursorTime: HTMLElement) => void,
  showLiveLabelIfHidden: boolean
): void {
  // updating liveCursorTime position should not show liveLabel if its in initial hidden state (not positioned)
  if (liveLabel.style.visibility === 'hidden' && !showLiveLabelIfHidden) {
    moveOneElement(liveLabel, liveCursorTime);
    liveCursorTime.style.visibility = 'unset';
    return;
  }

  // hide both elements
  liveLabel.style.visibility = 'hidden';
  liveCursorTime.style.visibility = 'hidden';

  // move one element
  moveOneElement(liveLabel, liveCursorTime);

  const rect1: DOMRect = liveLabel.getBoundingClientRect();
  const rect2: DOMRect = liveCursorTime.getBoundingClientRect();

  const doElementsOverlap = !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );

  // is timeshifting on ?
  const isTimeshifting: boolean = webStore.getState().webPlayer.isTimeshifting;

  // hide liveLabel when timeshifting is on and it overlaps with liveCursorTime
  liveLabel.style.visibility = isTimeshifting && doElementsOverlap ? 'hidden' : 'unset';
  // always show liveCursorTime when timeshifting is on
  liveCursorTime.style.visibility = isTimeshifting ? 'unset' : 'hidden';
}

export default ShakaPlayerUiTimeline;
