import React from 'react';

import { NavigateFunction, useNavigate } from 'react-router-dom';

import { HeaderNav } from '../../../../utils/webUtils';

import MainLayout from '../../../../components/layout/MainLayout';
import SettingsNavHeader from '../../SettingsNavHeader';
import Typography from '../../../../components/shared/Typography';

import './OfferDetailsSection.css';

function OfferDetailsSection({ showBackButton = true, title, subtitle = '', ButtonsSlot }) {
  const navigate: NavigateFunction = useNavigate();

  const handleBack = () => navigate(-1);

  return (
    <MainLayout activeHeaderNav={HeaderNav.SETTINGS}>
      {showBackButton ? <SettingsNavHeader onBack={handleBack} /> : null}
      <div className="OfferDetailsSection">
        <div className="OfferDetailsSectionDetails">
          <div className="OfferDetailsSectionTitle">
            <Typography variant="h1">{title}</Typography>
          </div>
          {subtitle ? (
            <div className="OfferDetailsSectionPrice">
              <Typography variant="h4">{subtitle}</Typography>
            </div>
          ) : null}
          <div className="OfferDetailsSectionActions">
            <ButtonsSlot />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default OfferDetailsSection;
