import React from 'react';
import { ActionCreator } from 'easy-peasy';
import { api } from '@oqee/core';

import useWebError from '../../../hooks/useWebError';
import { InputCodeModalProps } from '../InputCodeModal/InputCodeModal';
import InputCodeModal from '../InputCodeModal';
import { useWebStoreActions } from '../../../store/webStoreUtils';

type PurchaseCodeModal = Omit<InputCodeModalProps, 'onValidate'> & {
  onValidate: (purchaseCode: string) => Promise<any>;
  onSuccess?: () => void;
};

/**
 * An InputCodeModal to handle purchase code endpoints which returns the tvRights token
 */
function PurchaseCodeModal(props: PurchaseCodeModal) {
  const { onValidate, onSuccess, ...inputCodeModalProps } = props;

  const { trigger: triggerError, reset: resetError } = useWebError();
  const saveTvRights: ActionCreator<any> = useWebStoreActions(actions => actions.auth.saveTvRights);
  const resetCodeAttempt: ActionCreator<void> = useWebStoreActions(actions => actions.user.resetPurchaseCodeAttempt);

  const handleValidate = React.useCallback<(code: string) => void>(
    (purchaseCode: string) => {
      onValidate(purchaseCode).then(response => {
        const { success, result, error } = response;
        if (success) {
          if (!result.token) return;
          const { token } = result;
          const [, base64Payload] = token.split('.');
          const payload = JSON.parse(window.atob(base64Payload));
          api.auth.saveToken(api.constant.AUTH.TV_RIGHTS, token);
          saveTvRights({ token, payload });
          resetCodeAttempt();
          resetError();
          onSuccess && onSuccess();
        } else {
          const { code } = error;
          triggerError({
            code,
            data: error[code]
          });
        }
      });
    },
    [onSuccess, resetCodeAttempt, saveTvRights, triggerError]
  );

  React.useEffect(() => {
    return () => resetCodeAttempt();
  }, [resetCodeAttempt]);

  return <InputCodeModal {...inputCodeModalProps} onValidate={handleValidate} />;
}

export default PurchaseCodeModal;
