import { action } from 'easy-peasy';
import WebPlayerModel, { WebPlayerModelActions, WebPlayerModelState } from '../types/WebPlayerModel';
import { APIEpgEntry } from '@oqee/core';

const initialState: WebPlayerModelState = {
  volume: 1,
  isTimeshifting: false,
  pendingPlayerExitRequest: null,
  currentEpgProgram: null
};

const actions: WebPlayerModelActions = {
  setVolume: action((state: WebPlayerModelState, payload: number) => {
    return {
      ...state,
      volume: payload
    };
  }),
  setIsTimeshifting: action((state: WebPlayerModelState, payload: boolean) => {
    return {
      ...state,
      isTimeshifting: payload
    };
  }),
  setPendingPlayerExitRequest: action((state: WebPlayerModelState, payload: () => void) => {
    return {
      ...state,
      pendingPlayerExitRequest: payload
    };
  }),
  resetPendingPlayerExitRequest: action((state: WebPlayerModelState) => {
    return {
      ...state,
      pendingPlayerExitRequest: initialState.pendingPlayerExitRequest
    };
  }),
  setCurrentEpgProgram: action((state: WebPlayerModelState, payload: APIEpgEntry) => {
    return {
      ...state,
      currentEpgProgram: payload
    };
  }),
  resetCurrentEpgProgram: action((state: WebPlayerModelState) => {
    return {
      ...state,
      currentEpgProgram: initialState.currentEpgProgram
    };
  })
};

const webPlayer: WebPlayerModel = {
  ...initialState,
  ...actions
};

export default webPlayer;
