import React from 'react';
import { Link } from 'react-router-dom';
import { ActionCreator } from 'easy-peasy';
import { APIUserOAuthInitResponse, OAuthProvider, api } from '@oqee/core';
import { FreeLetterIcon, Typography } from '@oqee/ui';

import PageLayout from '../../../components/layout/PageLayout';
import Button from '../../../components/shared/Button';
import LandingHeader from '../LandingHeader';
import LandingFooter from '../../../components/layout/Footer';

import { useWebStoreActions } from '../../../store/webStoreUtils';

import './LoginSwitchPage.css';

function LoginSwitchPage() {
  const triggerError: ActionCreator<any> = useWebStoreActions(state => state.error.trigger);

  const initOAuth = (provider: OAuthProvider) => {
    api.auth.initOAuth(provider).then((response: APIUserOAuthInitResponse) => {
      if (response.success) {
        window.location.assign(response.result.redirect_url);
      } else {
        triggerError({ code: response.error.code });
      }
    });
  };

  const handleFreeboxLogin = () => initOAuth('free');

  const handleFreeMobileLogin = () => initOAuth('freemobile');

  const handleDiscoverOffers = () => window.open('https://free.fr', '_blank');

  return (
    <PageLayout>
      <div className="LoginSwitchPage" data-test-id="login-oauth-provider-choice">
        <LandingHeader />
        <div className="w-container">
          <div className="fil-dariane">
            <Link to="/logout">
              <div className="BackLinkContainer">
                <div className={`BackArrow`}></div>
                <Typography variant="body2">Accueil</Typography>
              </div>
            </Link>
          </div>
        </div>
        <div className="LoginSwitchColumn">
          <div className="LoginSwitchLogo">
            <img src="/img/oqee-by-free.svg" width="150" alt="logo OQEE" />
          </div>
          <div className="LoginSwitchSection">
            <div className="LoginSwitchSectionHead">
              <div className="LoginSwitchHr" />
              <Typography variant="body1" bold>
                Je me connecte avec
              </Typography>
              <div className="LoginSwitchHr" />
            </div>
            <div className="LoginSwitchSectionBody">
              <div className="LoginSwitchButtons">
                <Button
                  label="Mes identifiants Freebox"
                  size="large"
                  type="primary"
                  icon={FreeLetterIcon}
                  onClick={handleFreeboxLogin}
                />
                <Button
                  label="Mes identifiants Free Mobile"
                  size="large"
                  type="primary"
                  icon={FreeLetterIcon}
                  onClick={handleFreeMobileLogin}
                />
              </div>
            </div>
          </div>
          <div className="LoginSwitchSection">
            <div className="LoginSwitchSectionHead">
              <div className="LoginSwitchHr" />

              <Typography variant="body1" bold>
                Souscrire à Free
              </Typography>
              <div className="LoginSwitchHr" />
            </div>
            <div className="LoginSwitchSectionBody">
              <div className="LoginSwitchButtons">
                <Button label="Découvrir nos offres" size="large" type="secondary" onClick={handleDiscoverOffers} />
              </div>
            </div>
          </div>
        </div>
        <LandingFooter />
      </div>
    </PageLayout>
  );
}

export default LoginSwitchPage;
