import React from 'react';

import { useChannelSubscription } from '@oqee/core';

import ChannelSubscriptionOffers from './ChannelSubscriptionOffers';
import ChannelSubscriptionCheckout from './ChannelSubscriptionCheckout';
import NeedSubscriptionCanalPlus from './NeedSubscriptionCanalPlus';

import './ChannelSubscription.css';

function ChannelSubscription() {
  const [showCheckout, setShowCheckout] = React.useState(false);

  const { reset, channel } = useChannelSubscription();

  React.useEffect(() => {
    // reset channel offers on unmount
    return () => reset();
  }, []);

  if (channel.from_canalplus) {
    return <NeedSubscriptionCanalPlus />;
  }

  return (
    <div className="ChannelSubscription">
      {showCheckout ? (
        <ChannelSubscriptionCheckout />
      ) : (
        <ChannelSubscriptionOffers onContinue={() => setShowCheckout(true)} />
      )}
    </div>
  );
}

export default ChannelSubscription;
