import React from 'react';

import { NavigateFunction, useNavigate } from 'react-router-dom';
import ContentList from '../../content/ContentList';
import SubMenu from '../../shared/SubMenu';
import { Select, SelectOption } from '../../shared/Select';

import EmptyResult from '../../../components/shared/EmptyResult';

import './MultipageList.css';
import { StickyMenu } from '../../shared/SubMenu/SubMenu';

type MultiPageListProps = {
  program: any;
  segmentedId?: string;
  baseUrl?: string;
};

const PLACEHOLDERS = [...Array(12)].map(_ => ({
  isPlaceholder: true
}));

function MultiPageList({ program, segmentedId = '', baseUrl = '' }: MultiPageListProps) {
  const navigate: NavigateFunction = useNavigate();

  const { content = {} } = program || {};
  const { segmentation = {}, channel_logo_url } = content;
  const { type: segmentationType, logical_type } = segmentation;

  const sections = segmentation[segmentationType] || [];
  const section = sections.find(section => section.id === segmentedId);

  const menuItems = React.useMemo(() => {
    return sections.map(section => {
      return {
        to: `${baseUrl}/${section.id}`,
        id: section.id,
        title: section.title
      };
    });
  }, [sections]);

  // TODO FIXME: temporary object signature as soon as we refactor search completly in TV
  const entries = React.useMemo(() => {
    const entries = content?.entries || content.collection?.entries;
    const defaultEntries = entries && entries.length > 0 ? entries : PLACEHOLDERS;
    if (!section) return defaultEntries;

    return section.item_ids.map(itemId => {
      const entity = segmentation.entities[itemId];

      const { type: contentType } = entity;
      const program = entity[contentType];
      const { type, [program]: content, images, ...props } = entity;

      const legacyType = type === 'diffusion' ? 'live' : type;
      const programEntry = {
        type: legacyType,
        [legacyType]: { ...content, ...program, ...props, type: legacyType },
        pictures: {
          main: images.thumbnail,
          preview: images.backdrop
        }
      };

      delete programEntry[legacyType][type];

      return programEntry;
    });
  }, [content, section, segmentation]);

  const handleSelect = React.useCallback<(value: SelectOption) => void>(value => {
    navigate(`${baseUrl}/${value.value}`, { replace: true });
  }, []);

  return (
    <div className="MultiPageList">
      {sections &&
        sections?.length > 0 &&
        (logical_type === 'serie' ? (
          <StickyMenu>
            <div className="SubMenu">
              <Select
                options={sections.map(({ id, title }) => {
                  return { value: id, text: title };
                })}
                onSelect={handleSelect}
              />
            </div>
          </StickyMenu>
        ) : logical_type !== 'collection' ? (
          <SubMenu items={menuItems} activeSubMenuItem={segmentedId} showRecordStatus={false} replaceHistory isSticky />
        ) : null)}
      {entries.length ? (
        <ContentList
          entries={entries}
          program={program}
          showCircularProgress={(logical_type === 'segmented' || logical_type === 'serie') && !channel_logo_url}
        />
      ) : (
        <div className="MultiPageListEmpty">
          <EmptyResult title="Aucun contenu disponible" />
        </div>
      )}
    </div>
  );
}

export default MultiPageList;
