import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecordProgramDetails } from '@oqee/core';

import PlayerPageLayout from '../../components/layout/PlayerPageLayout';
import RecordPlayer from '../../components/player/RecordPlayer';

type RecordPlayerPageParams = {
  recordId: string;
};

function RecordPlayerPage() {
  const { recordId } = useParams() as RecordPlayerPageParams;
  const recordDetail = useRecordProgramDetails(recordId);

  if (!recordDetail.isReady) {
    return null;
  }
  return (
    <PlayerPageLayout>
      <RecordPlayer recordId={recordId} recordDetail={recordDetail} />
    </PlayerPageLayout>
  );
}

export default RecordPlayerPage;
