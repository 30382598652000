import React from 'react';
import { useParams } from 'react-router-dom';

import PlayerPageLayout from '../../components/layout/PlayerPageLayout';
import TVodPlayer from '../../components/player/TVodPlayer';

type TVodPlayerPageParams = {
  purchaseId: string;
};

function TVodPlayerPage() {
  const { purchaseId } = useParams() as TVodPlayerPageParams;

  return (
    <PlayerPageLayout>
      <TVodPlayer purchaseId={purchaseId} />
    </PlayerPageLayout>
  );
}

export default TVodPlayerPage;
