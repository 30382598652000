import React from 'react';
import { useIntersectionObserver } from 'usehooks-ts';


function withVisibility<T>(Component) {
  function ComponentWithVisibility(props: T) {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const intersectionEntry = useIntersectionObserver(ref, {});
    const isVisible = !!intersectionEntry?.isIntersecting;

    return <Component ref={ref} {...props} visible={isVisible} />;
  }
  ComponentWithVisibility.displayName = Component.name
  return ComponentWithVisibility;
}
withVisibility.displayName = 'withVisibility';


export default withVisibility;
