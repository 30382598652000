import React from 'react';
import { useParams } from 'react-router-dom';
import { APIReplayTile, APIReplayTileInnerHomeTile, CONSTANTS, useReplayHome } from '@oqee/core';

import MainLayout from '../../../components/layout/MainLayout';
import { HeaderNav } from '../../../utils/webUtils';
import { ReplayHomeTile, ReplayHomeTilePlaceholder } from '../ReplayHomeTile';
import NavHeader from '../../../components/layout/NavHeader';
import ParentalGuard from '../../../components/shared/ParentalGuard';

import './ReplayHomePage.css';

type ReplayHomePageParams = {
  '*': string; // * = homePathname, can be empty => main home
};

function ReplayHomePage() {
  const { '*': splat } = useParams() as ReplayHomePageParams;
  const homePathname: string = splat.length > 0 ? splat : CONSTANTS.MAIN_HOME_ID;
  const { tiles, homeTile }: { tiles?: APIReplayTile[]; homeTile?: APIReplayTileInnerHomeTile } =
    useReplayHome(homePathname);

  return (
    <MainLayout activeHeaderNav={HeaderNav.REPLAY} fbScreenName="replay_portals">
      <ParentalGuard isAdult={!!homeTile?.need_parental_code}>
        <div className="ReplayHomePage">
          {homePathname !== CONSTANTS.MAIN_HOME_ID && <NavHeader title={homeTile?.name ?? ''} />}

          <div className="ReplayHomePageGrid">
            {tiles && tiles.length > 0
              ? tiles.map((tile: APIReplayTile, tileIndex: number) => {
                  const innerTile: any = tile?.[tile.type];
                  const entryId: string | number = innerTile?.home_id || innerTile?.portal_id;

                  return <ReplayHomeTile tile={tile} entryId={entryId} key={tileIndex} homePathname={homePathname} />;
                })
              : [...Array(20)].map((_, i) => <ReplayHomeTilePlaceholder key={i} />)}
          </div>
        </div>
      </ParentalGuard>
    </MainLayout>
  );
}

export default ReplayHomePage;
