import React from 'react';
import LandingHeader from '../LandingHeader';
import PageLayout from '../../../components/layout/PageLayout';

import './LandingPage.css';

function LandingPage() {
  return (
    <PageLayout>
      <div className="LandingPage">
        <div className="MainDiv" id="MainDiv">
          <LandingHeader />
          <iframe
            src="/landing/index.html"
            style={{
              height: '100vh',
              width: '100vw',
              border: 'none'
            }}
          />
        </div>
      </div>
    </PageLayout>
  );
}

export default LandingPage;
