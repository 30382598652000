import { useLocation, useNavigate } from 'react-router-dom';
import { useLastLocation } from 'react-router-dom-last-location';

function useNavigationState() {
  const location = useLocation();
  const { state, pathname, search } = location;

  const navigationState = state?.navigation || {
    rowId: null,
    entryId: null,
    scrollY: null
  };
  const navigate = useNavigate();
  const { lastLocation } = useLastLocation();
  const lastNavigationState = lastLocation?.state?.navigation;
  /* Save the rowId because same content can be displayed in multiple rows!
        /* ⚠️ WARNING ⚠️
        /* Construct ID, because API does not give ID and somes lines does not have title.
        /* For now, i use index, but this not really perfect because lines can changed...
        /* For home, i pass index to row using name based OR index property OR type of section (resumable, head)
        */

  const saveNavigationStateInLocation = (newNavigationState = {}) => {
    // Replace current route to change state history, is other way to do this ... ?
    navigate(pathname + search, {
      replace: true,
      state: {
        ...state,
        navigation: {
          ...navigationState,
          ...newNavigationState
        }
      }
    });
  };

  const saveWindowScrollY = () => saveNavigationStateInLocation({ scrollY });

  const saveRowId = rowId =>
    saveNavigationStateInLocation({
      rowId
    });

  const saveEntryId = entryId =>
    saveNavigationStateInLocation({
      entryId
    });

  return {
    location,
    lastLocation,
    lastNavigationState,
    navigationState,
    saveWindowScrollY,
    saveRowId,
    saveEntryId
  };
}

export default useNavigationState;
