import React from 'react';
import { APIEpgEntry, ChannelPlaybackInfo } from '@oqee/core';
import shaka from 'shaka-player/dist/shaka-player.ui';
import { ActionCreator } from 'easy-peasy';

import { useWebStoreActions } from '../../../store/webStoreUtils';

import 'shaka-player/dist/controls.css';
import './ShakaPlayer.css';

interface ShakaPlayerLiveTimelineUpdateProps {
  playbackInfo: ChannelPlaybackInfo;
  videoEl: HTMLVideoElement;
  player: shaka.Player;
  programEntry: any;
}

function ShakaPlayerLiveTimelineUpdate({
  player,
  videoEl,
  playbackInfo,
  programEntry
}: ShakaPlayerLiveTimelineUpdateProps) {
  const setIsTimeshifting: ActionCreator<boolean> = useWebStoreActions(state => state.webPlayer.setIsTimeshifting);
  const setCurrentEpgProgram: ActionCreator<APIEpgEntry> = useWebStoreActions(
    state => state.webPlayer.setCurrentEpgProgram
  );
  const resetCurrentEpgProgram: ActionCreator<void> = useWebStoreActions(
    state => state.webPlayer.resetCurrentEpgProgram
  );

  const currentTimeInSeconds: number = Math.round(Date.now() / 1000);

  React.useEffect(() => {
    if (programEntry) {
      setCurrentEpgProgram(programEntry);
    } else {
      // no program found in EPG => set avail window to 4h
      resetCurrentEpgProgram();
    }
  }, [currentTimeInSeconds, programEntry]);

  const handleTimeUpdate = () => {
    // disable goToLive & go10sFwd buttons if position is live
    const isTimeshifting: boolean = Math.abs(player.seekRange().end - videoEl.currentTime) >= 5;

    ['oqee-goToLive-button', 'oqee-go10sFwd-button'].forEach((id: string) => {
      const buttonElement: HTMLButtonElement | null = document.getElementById(id) as HTMLButtonElement;

      if (buttonElement) {
        const isButtonDisabled: boolean = buttonElement.disabled;

        if (!isTimeshifting && !isButtonDisabled) {
          buttonElement.disabled = true;
          buttonElement.classList.add('disabledGray');
        }
        if (isTimeshifting && isButtonDisabled) {
          buttonElement.disabled = false;
          buttonElement.classList.remove('disabledGray');
        }
      }
    });
    setIsTimeshifting(isTimeshifting);
  };

  React.useEffect(() => {
    const eventManager = new shaka.util.EventManager();
    eventManager.listen(videoEl, 'timeupdate', handleTimeUpdate);

    return () => {
      eventManager.unlisten(videoEl, 'timeupdate', handleTimeUpdate);
    };
  }, []);

  React.useEffect(() => {
    return () => {
      // reset currentEpgProgram & isTimeshifting between channels
      resetCurrentEpgProgram();
      setIsTimeshifting(false);
    };
  }, [playbackInfo.liveChannel.id]);

  return null;
}

export default ShakaPlayerLiveTimelineUpdate;
export type { ShakaPlayerLiveTimelineUpdateProps };
