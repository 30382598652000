import React from 'react';

import { EmptyResult } from '@oqee/ui';
import type { EmptyResultProps } from '@oqee/ui';

import { Player as LottierPlayer } from '@lottiefiles/react-lottie-player';

import './EmptyResult.css';

import EmptyResultLottieUrl from './empty-result.json';

function EmptyResultWithLottie(props) {
  return (
    <EmptyResult {...props}>
      <LottierPlayer autoplay loop src={EmptyResultLottieUrl} />
    </EmptyResult>
  );
}

export default EmptyResultWithLottie;

export type { EmptyResultProps };
