import React, { MouseEvent } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

import Typography from '../Typography';

import './ButtonBack.css';

interface ButtonBackProps {
  title?: string;
  backArrowSize?: string;
}

function ButtonBack({ title, backArrowSize = 'small' }: ButtonBackProps) {
  const navigate: NavigateFunction = useNavigate();

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!e.detail || e.detail < 2) {
      // Prevent double click => double back
      navigate(-1);
    }
  };

  return (
    <div className="BackLinkContainer" onClick={handleClick}>
      <div className={`BackArrow ${backArrowSize}`}></div>
      {title ? <Typography variant="body2">{title}</Typography> : null}
    </div>
  );
}

export default ButtonBack;
