import React from 'react';
import { PlaybackInfo } from '@oqee/core';

import Player from './Player';
import useWebVodTrailerPlaybackInfo from '../../hooks/useWebVodTrailerPlaybackInfo';

interface VodTrailerPlayerProps {
  program: any;
}

function VodTrailerPlayer({ program }: VodTrailerPlayerProps) {
  const { vod } = program;
  const playbackInfo: PlaybackInfo | null = useWebVodTrailerPlaybackInfo({ apiType: "vod", trailer_ids: vod.trailer_ids});

  return <Player playbackInfo={playbackInfo} />;
}

export default VodTrailerPlayer;
