import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { useAvatars } from '@oqee/core';

import ProfileAge from '../ProfileAge';
import ProfilePicture from '../ProfilePicture';
import ProfileColor from '../ProfileColor';
import ProfileName from '../ProfileName/ProfileName';
import ProfileEditionMenu from './ProfileEditionMenu';
import ProfileSave from './ProfileSave';

function ProfileEdition() {
  const avatars = useAvatars();

  return avatars ? (
    <Routes>
      <Route path="/avatar/:ageRange/:avatarTone/:avatarShapeKey/:avatarColor/commit" element={<ProfileSave />} />
      <Route path="/avatar/:ageRange/:avatarTone/:avatarShapeKey" element={<ProfileColor />} />
      <Route path="/avatar/:ageRange" element={<ProfilePicture />} />
      <Route path="/avatar" element={<ProfileAge />} />
      <Route path="/name/commit" element={<ProfileSave />} />
      <Route path="/name" element={<ProfileName />} />
      <Route index element={<ProfileEditionMenu />} />
    </Routes>
  ) : null;
}

export default ProfileEdition;
