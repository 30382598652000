import React from 'react';

import { PICTURE_FORMAT_WEB, formatPictureUrl, usePageMulti, useProgram, useRecorder } from '@oqee/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { MixedLine, Thumbnail, ThumbnailPicture } from '@oqee/ui';

import ProgramTitle from '../shared/ProgramTitle';
import MainLayout from '../layout/MainLayout';
import NavHeader from '../layout/NavHeader';
import MultiPageList from './MultiPageList';

import './MultiPage.css';

interface MultiPageProps {
  contentType: 'collection' | 'collection_replay' | 'multiprogram';
  baseUrl: string;
}

function MultiPage({ contentType, baseUrl }: MultiPageProps) {
  const { collectionId: strCollectionId, segmentedId = '' } = useParams();

  const collectionId = Number(strCollectionId);
  const { isReady, programEntry } = usePageMulti(contentType, collectionId);
  const segmentBaseUrl = `/${baseUrl}/${collectionId}`;

  const pageProgram = useProgram(programEntry);

  const { content = {} } = pageProgram || {};
  const { title, global_data, segmentation = {}, channel_logo_url } = content;
  const { type: segmentationType } = segmentation;

  const thumbnailUrl = pageProgram?.getThumbnailUrl(PICTURE_FORMAT_WEB.THUMBNAIL);

  // Wait for getting records list in order to prevent fetching later on it in PageMultiEntity.
  const [displayPlaceholder, setDisplayPlaceholder] = React.useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const segments = segmentation[segmentationType];
    if (!segmentedId && segments?.length) {
      const firstSegmentedId = segments[0].id;
      navigate(`${segmentBaseUrl}/${firstSegmentedId}`, {
        replace: true,
        state: location.state
      });
    }
  }, [segmentation.entities, segmentedId]);

  const { isReady: isRecordsReady } = useRecorder(!isReady);
  React.useEffect(() => {
    if (isRecordsReady && isReady) {
      setDisplayPlaceholder(false);
    }
  }, [isRecordsReady, isReady]);

  const fbScreenName = React.useMemo(() => {
    if (segmentation?.logical_type === 'serie') return `serie_all`;
    if(['all', 'live', 'replay', 'upcoming', 'vod'].includes(segmentedId || ''))
    return `multiprogram_${segmentedId}`;
  }, [segmentation, segmentedId]);
  
  if(displayPlaceholder) return <></>;

  return (
    <MainLayout
      fbScreenName={fbScreenName}
      program={programEntry}
      withBackdrop={true}
      frontChildren={<MultiPageList program={pageProgram} segmentedId={segmentedId} baseUrl={segmentBaseUrl} />}
    >
      <NavHeader title="Retour" />
      <div className="MultiPageHeader">
        <div className="MultiPageTitle">
          <ProgramTitle progressRing={{ logoUrl: formatPictureUrl(channel_logo_url, 48) }} title={title} />
          {global_data && <MixedLine mixedLine={global_data} />}
        </div>
        {thumbnailUrl && (
          <Thumbnail size='small'>
            <ThumbnailPicture url={thumbnailUrl} />
          </Thumbnail>
        )}
      </div>
    </MainLayout>
  );
}

export default MultiPage;
