import * as Sentry from '@sentry/react';

function initializeSentry() {
  if(!import.meta.env.VITE_SENTRY_ENABLED) return;

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_SENTRY_RELEASE,

    integrations: [],

    tracesSampleRate: 0.01
  });
}

export { initializeSentry };
