import React from 'react';
import shaka from 'shaka-player/dist/shaka-player.ui';

import { ShakaPlayerProps } from '../ShakaPlayer';

interface ShakaPlayerUiFocusProps extends ShakaPlayerProps {
  uiOverlay: shaka.ui.Overlay;
}

/*
 * Component to automatically apply focus on seek bar and allow seeking
 * with keyboard
 */
function ShakaPlayerUiFocus(props: ShakaPlayerUiFocusProps) {
  const { videoEl, uiOverlay, playbackInfo } = props;

  const { broadcastType } = playbackInfo;
  const isLive = broadcastType === 'live';

  React.useEffect(() => {
    // No seek on live for now
    if (isLive) return;

    const uiOverlayControls = uiOverlay.getControls();
    if (uiOverlayControls === null) return;
    const uiOverlayControlContainer = uiOverlayControls.getControlsContainer();

    const setFocusOnSeekbar = () => {
      const seekbar: HTMLInputElement | null = uiOverlayControlContainer.querySelector('.shaka-seek-bar');
      if (seekbar === null) return;
      seekbar.focus();
    };

    // Set focus on mount
    setFocusOnSeekbar();
    // Set focus when over controls
    uiOverlayControlContainer.addEventListener('mouseover', setFocusOnSeekbar);
    // Set focus when play/pause
    videoEl.addEventListener('play', setFocusOnSeekbar);
    // Can use it when to not use play event and mouseover, but loose functionality using keyboard on other controls like volume...
    // controlsContainer.addEventListener('focusout', setFocusOnSeekbar);

    return () => {
      uiOverlayControlContainer.removeEventListener('mouseover', setFocusOnSeekbar);
      videoEl.removeEventListener('play', setFocusOnSeekbar);
    };
  }, [isLive, videoEl, uiOverlay]);

  return null;
}

export default ShakaPlayerUiFocus;
