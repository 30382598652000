import MainLayout from '../MainLayout';
import VodNavHeader from '../../vod/VodNavHeader';
import ContentGrid from '../../content/ContentGrid';
import { HeaderNav } from '../../../utils/webUtils';
import React from 'react';

function VodPortalLayout({ title, entries }) {
  return (
    <MainLayout activeHeaderNav={HeaderNav.VOD}>
      <VodNavHeader title={title} />
      <ContentGrid entries={entries} showTileTitle className="ContentGrid--vod" />
    </MainLayout>
  );
}

export default VodPortalLayout;
