import React from 'react';
import Logger from 'js-logger';
import shaka from 'shaka-player/dist/shaka-player.ui';
import { loadImaSdk, google } from '@alugha/ima';

import { api, shouldMock, StreamingType } from '@oqee/core';

import Spinner from '../shared/Spinner/Spinner';

const logger = Logger.get('BrowserContextProvider');

const { DRM_SYSTEM } = api.constant;

type BrowserContextType = {
  isBrowserSupported: boolean;
  selectedStreamingType: StreamingType;
  availableCiphers?: string[];
  fairplayCertificate?: Uint8Array; // remains undefined if selectedStreamingType !== HLS
  ima?: typeof google.ima; // remains undefined if IMA SDK could not be loaded
};

const BrowserContext = React.createContext<BrowserContextType>({
  isBrowserSupported: true,
  selectedStreamingType: StreamingType.DASH
});

function BrowserContextProvider({ children }: React.PropsWithChildren) {
  const [contextValue, setContextValue] = React.useState<BrowserContextType | null>(null);

  React.useEffect(() => {
    if (shouldMock) {
      setContextValue({
        isBrowserSupported: false,
        selectedStreamingType: StreamingType.DASH
      });
      return;
    }
    shaka.polyfill.installAll();
    const isBrowserSupported: boolean = shaka.Player.isBrowserSupported();

    if (!isBrowserSupported) {
      setContextValue({
        isBrowserSupported,
        selectedStreamingType: StreamingType.DASH
      });
    } else {
      const probeSupportPromise: Promise<shaka.extern.SupportType> = shaka.Player.probeSupport();
      const imaSdkPromise: Promise<typeof google.ima | undefined> = loadImaSdk().catch(e => {
        logger.error(`IMA SDK failed to load. Check ad blocker`, e);
        return undefined;
      });

      Promise.all([probeSupportPromise, imaSdkPromise]).then(([support, ima]) => {
        const manifestSupport: { [key: string]: boolean } = support.manifest;
        const drmSupport: { [key: string]: shaka.extern.DrmSupportType | null } = support.drm;

        const supportsDash: boolean =
          manifestSupport['video/vnd.mpeg.dash.mpd'] === true && drmSupport?.[DRM_SYSTEM.widevine] != null;
        const supportsHls: boolean =
          manifestSupport['application/vnd.apple.mpegurl'] === true && drmSupport?.[DRM_SYSTEM.fairplay] != null;

        const selectedStreamingType: StreamingType = supportsDash
          ? StreamingType.DASH
          : supportsHls
          ? StreamingType.HLS
          : StreamingType.DASH;

        // select supported drm
        const drm = support.drm[supportsHls ? DRM_SYSTEM.fairplay : DRM_SYSTEM.widevine];
        // get all available encryption schemes
        const cipherList = drm?.encryptionSchemes;
        // check handled ciphers name
        const availableCiphers = ['cbcs', 'cenc'].filter(c => cipherList?.includes(c));

        if (selectedStreamingType === StreamingType.HLS) {
          fetch('/fairplay/free.cer').then((res: Response) => {
            res.arrayBuffer().then((data: ArrayBuffer) =>
              setContextValue({
                isBrowserSupported,
                selectedStreamingType,
                availableCiphers,
                fairplayCertificate: new Uint8Array(data),
                ima
              })
            );
          });
        } else {
          setContextValue({
            isBrowserSupported,
            selectedStreamingType,
            availableCiphers,
            ima
          });
        }
      });
    }
  }, []);

  // do not render children without context value
  return contextValue ? (
    <BrowserContext.Provider value={contextValue}>{children}</BrowserContext.Provider>
  ) : (
    <Spinner testId="browserContext-pending" />
  );
}

export default BrowserContextProvider;
export { BrowserContext };
export type { BrowserContextType };
