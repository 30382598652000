import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Typography from '../../shared/Typography';

import './SubMenu.css';
import MyListRecordStatus from '../../myList/MyListRecordStatus';

interface SubMenuItem {
  to: string;
  id: number;
  title: string;
}

interface SubMenuProps {
  items: Array<SubMenuItem>;
  activeSubMenuItem: number | string;
  showRecordStatus: boolean;
  replaceHistory?: boolean;
  isSticky?: boolean;
}

export function StickyMenu({ children }) {
  const sentinelRef = React.useRef(null);
  const [isSticky, setIsSticky] = React.useState(false);

  const sentinelObserverCallback: IntersectionObserverCallback = entries => {
    const [entry] = entries;
    setIsSticky(!entry.isIntersecting);
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver(sentinelObserverCallback, {
      rootMargin: '64px'
    });
    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }
    return () => {
      if (sentinelRef.current) observer.unobserve(sentinelRef.current);
    };
  }, [sentinelRef]);

  return (
    <>
      <div ref={sentinelRef}></div>
      <div className={clsx('StickySubMenu', { enabled: isSticky })}>{children}</div>
    </>
  );
}

function SubMenu({
  items,
  activeSubMenuItem,
  showRecordStatus,
  replaceHistory = false,
  isSticky = false
}: SubMenuProps) {
  const Menu = () => (
    <div className="SubMenu">
      {items.length > 1 &&
        items.map(({ id, title, to }) => {
          return (
            <Link
              key={id}
              to={to}
              id={`submenu-${id}`}
              className={clsx('SubMenuItem', { active: activeSubMenuItem === id })}
              replace={replaceHistory}
            >
              <Typography variant="body2">{title}</Typography>
            </Link>
          );
        })}
      {showRecordStatus && <MyListRecordStatus />}
    </div>
  );
  return isSticky ? (
    <StickyMenu>
      <Menu />
    </StickyMenu>
  ) : (
    <Menu />
  );
}

export default SubMenu;
export type { SubMenuItem };
