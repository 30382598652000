import React from 'react';
import { usePurchaseCodeEditor } from '@oqee/core';

import Modal from '../../../../components/shared/Modal';
import InputCodeModal from '../../../../components/shared/InputCodeModal';

function PurchaseCodeEditor({ codeCreation = false, purchaseCode, codeLocked, onSave, onCancel }) {
  const { stepId, title, subtitle, onValidation } = usePurchaseCodeEditor({
    isCodeCreation: codeCreation,
    purchaseCode
  });

  return (
    <div className="PurchaseCodeEditor">
      {purchaseCode.codeChanged ? (
        <Modal
          title={title}
          description="Nous avons enregistré votre nouveau code d’achat. Vous pouvez dès à présent l’utiliser."
          onValidate={onSave}
          validateButtonLabel="Terminer"
        />
      ) : (
        <InputCodeModal
          key={stepId}
          title={title}
          subtitle={subtitle}
          showLostCodeTip={stepId === 'current'}
          codeAttemptLeft={purchaseCode.codeAttemptLeft}
          codeLocked={codeLocked}
          onValidate={(code: string) => onValidation({ code })}
          onCancel={onCancel}
        />
      )}
    </div>
  );
}

export default PurchaseCodeEditor;
