import React from 'react';
import { useOutlet } from 'react-router-dom';
import Logger from 'js-logger';
import {
  OAuthProvider,
  api,
  DidomiConsent,
  OAuth,
  shouldMock,
  useAuthentication,
  useServicePlan,
  useTvRights,
  OAuthError
} from '@oqee/core';
import { useTimeout } from 'usehooks-ts';

import apiEvent from '../../lib/apiEvent';
import Spinner from '../shared/Spinner/Spinner';
import { useWebStoreState } from '../../store/webStoreUtils';
import useWebError from '../../hooks/useWebError';
import LoginSwitchPage from '../../routes/login/LoginSwitchPage';
import LoginErrorPage from '../../routes/login/LoginErrorPage';
import LandingPage from '../../routes/login/LandingPage';

const logger = Logger.get('RequireAuthentication');

// Get oauth result from localStorage or from OAuth response
function getOAuthValues(): OAuth | null {
  const params = new URLSearchParams(window.location.search.toString());
  if (params.get('oauth_result')) {
    const errorCode: string | null = params.get('error_code');
    const result: string | null = params.get('result');
    const provider = params.get('provider') as OAuthProvider;
    const errorData: string | null = params.get('error_data');

    if (errorCode) {
      throw new OAuthError(errorCode, provider, errorData);
    }
    if (result) {
      return { token: result, provider };
    }
  }
  return api.auth.getOAuth();
}

function RequireServicePlan({ children }) {
  const { channelList } = useServicePlan();
  const isReady = channelList.length > 0;

  return isReady ? children : null;
}

function RequireTvRights({ children }) {
  const { tvRights } = useTvRights();
  const error = useWebError();
  const hasTvRights = Boolean(tvRights);

  return hasTvRights ? children : !error ? <Spinner testId="tvRights-pending" /> : null;
}

function RequireUserConsent({ children }) {
  const oqeeAuth: string | null = useWebStoreState(state => state.auth.oqee.token);
  const [showChildren, setShowChildren] = React.useState(false);

  useTimeout(() => {
    // timeout to still show children if didomi could not load
    !showChildren && setShowChildren(true);
  }, 1000);

  const handleDidomiReady = () => {
    logger.debug('Didomi is ready');
    setShowChildren(true);
  };

  if (shouldMock) return children;
  if (!oqeeAuth) return null;
  return (
    <DidomiConsent
      environment={import.meta.env.MODE}
      apiKey={import.meta.env.VITE_DIDOMI_API_KEY}
      apiEvent={apiEvent}
      noticeId={import.meta.env.VITE_DIDOMI_NOTICE_ID}
      onReady={handleDidomiReady}
    >
      {showChildren ? children : <Spinner testId="userConsent-pending" />}
    </DidomiConsent>
  );
}

interface AuthenticateProps {
  oAuth: OAuth;
}

function Authenticate({ oAuth }: AuthenticateProps) {
  const outlet = useOutlet();
  const { isSigningIn, setIsSigningIn, signIn, isSignedIn } = useAuthentication({ oAuth });

  React.useEffect(() => {
    if (!isSigningIn) return;

    signIn()
      .then(() => {
        setIsSigningIn(false);
      })
      .catch(() => {
        setIsSigningIn(false);
      });
  }, [isSigningIn, signIn]);

  if (isSigningIn) {
    return <Spinner testId="require-authentication-pending" />;
  }

  if (isSignedIn) {
    return (
      <RequireUserConsent>
        <RequireTvRights>
          <RequireServicePlan>{outlet}</RequireServicePlan>
        </RequireTvRights>
      </RequireUserConsent>
    );
  }

  return <LandingPage />;
}

function RequireAuthentication() {
  let oAuth;
  try {
    oAuth = getOAuthValues();
  } catch (error) {
    const oauthError: OAuthError = error as OAuthError;

    if (oauthError.errorCode === 'login_cancelled') return <LoginSwitchPage />;
    return <LoginErrorPage oauthError={oauthError} />;
  }

  return <Authenticate oAuth={oAuth} />;
}

export default RequireAuthentication;
