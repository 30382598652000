import {
  FormattedChannel,
  useProgram,
  PICTURE_FORMAT_WEB,
  useChannelSingleEpg,
  useSuggestedChannels,
  zeroPad
} from '@oqee/core';
import React from 'react';
import clsx from 'clsx';

import CircularProgress from '../../../../components/shared/CircularProgress';
import Typography from '../../../../components/shared/Typography';
import Lock from '../../../../components/shared/Lock';
import { useWebStoreActions, useWebStoreState } from '../../../../store/webStoreUtils';

import './LiveChannels.css';
import { ActionCreator } from 'easy-peasy';

function LiveChannels() {
  const suggestedChannels = useSuggestedChannels({ channelNumber: null });

  return (
    <div className="LiveChannels">
      {suggestedChannels.map(
        (suggestedChannel, rowIndex) =>
          suggestedChannel?.channel && (
            <LiveChannelRow channel={suggestedChannel.channel} rowIndex={rowIndex} key={suggestedChannel.channel.id} />
          )
      )}
    </div>
  );
}

interface LiveChannelRowProps {
  channel: FormattedChannel;
  rowIndex: number;
}

function LiveChannelRow({ channel, rowIndex }: LiveChannelRowProps) {
  const containerDiv = React.useRef<HTMLDivElement>(null);
  const isTimeshifting: boolean = useWebStoreState(state => state.webPlayer.isTimeshifting);
  const suggestedChannelIndex: number = useWebStoreState(state => state.home.suggestedChannelIndex);
  const setSuggestedChannelIndex: ActionCreator<number> = useWebStoreActions(
    actions => actions.home.setSuggestedChannelIndex
  );
  const setPendingPlayerExitRequest: ActionCreator<(() => void) | null> = useWebStoreActions(
    actions => actions.webPlayer.setPendingPlayerExitRequest
  );
  const isSelectedRow: boolean = rowIndex === suggestedChannelIndex;
  const timestamp: number = useWebStoreState(state => state.channel.timestamp);
  const programEntry = useChannelSingleEpg(channel.id, timestamp);
  const program = useProgram(programEntry);
  const [[start, end], pictureUrl, programTitle, programSubtitle] = React.useMemo(
    () =>
      program
        ? [
            program.getTimeRange(),
            program.getThumbnailUrl(PICTURE_FORMAT_WEB.CHANNEL_LIST),
            program.content.title,
            program.content.sub_title
          ]
        : [],
    [program]
  );

  React.useEffect(() => {
    if (containerDiv.current && isSelectedRow) {
      // scroll to show selected row
      containerDiv.current.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  }, []);

  const handleRowClick = React.useCallback(() => {
    if (isTimeshifting) {
      return setPendingPlayerExitRequest(() => {
        setSuggestedChannelIndex(rowIndex);
      });
    }

    setSuggestedChannelIndex(rowIndex);
  }, [setPendingPlayerExitRequest, setSuggestedChannelIndex, rowIndex, isTimeshifting]);

  return (
    <div className={clsx('LiveChannelRowContainer', isSelectedRow ? 'selected' : '')} ref={containerDiv}>
      <div className="LiveChannelRow" onClick={handleRowClick}>
        <div className="LiveChannelThumbnailContainer">
          <div className="LiveChannelThumbnail">
            <img alt="" src={pictureUrl} className="LiveChannelThumbnailImage" width="176" height="99" />

            <CircularProgress
              color={channel.color}
              currentTime={timestamp}
              start={start}
              end={end}
              logoUrl={program.getIcon('light')}
              showProgress
            />

            {program?.isLocked && <Lock isOpen={!!program.promoToken} />}
          </div>
        </div>

        <div className="LiveChannelDataContainer">
          <div className="LiveChannelData">
            <Typography variant="body3" className="LiveChannelHeader">
              {zeroPad(channel.number)} - {channel.name}
            </Typography>
            <div className="LiveChannelTitlesContainer">
              <Typography variant="body2" bold className="LiveChannelTitle">
                {programTitle}
              </Typography>

              <Typography variant="body3" className="LiveChannelSubtitle">
                {programSubtitle}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveChannels;
