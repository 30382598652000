import React from 'react';

import { MyListNav } from '../../utils/webUtils';

import MyListLayout from '../../components/layout/MyListLayout';
import EmptyResult from '../../components/shared/EmptyResult';

const MyListVodPage = () => (
  <MyListLayout
    pageId={MyListNav.VOD}
    filter={['vod', 'vod_portal']}
    fallback={<EmptyResult title="Vous n'avez pas encore de VOD" />}
  />
);

export default MyListVodPage;
