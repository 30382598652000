import React from 'react';
import LiveChannels from './LiveChannels';
import LivePreview from './LivePreview';

import './LiveSection.css';

function LiveSection() {
  return (
    <div className="LiveSectionContainer">
      <div className="LiveSection">
        <LivePreview />
        <LiveChannels />
      </div>
    </div>
  );
}

export default LiveSection;
