import React from 'react';
import { FormattedChannel, PlaybackInfo } from '@oqee/core';
import Player from './Player';
import useWebChannelPlaybackInfo from '../../hooks/useWebChannelPlaybackInfo';

interface LivePlayerProps {
  liveChannel: FormattedChannel;
  handleBack?: () => void;
  isLivePreview?: boolean;
  startOver?: boolean;
}

function LivePlayer({ liveChannel, handleBack, isLivePreview = false, startOver = false }: LivePlayerProps) {
  const playbackInfo: PlaybackInfo = useWebChannelPlaybackInfo(liveChannel);

  return (
    <>
      <Player playbackInfo={playbackInfo} handleBack={handleBack} isLivePreview={isLivePreview} startOver={startOver} />
    </>
  );
}

export default LivePlayer;
