import { StreamingType } from '@oqee/core';

const STARTOVER_MAX_DURATION_SEC = 4 * 60 * 60;

function absoluteTimeToBrowserTime(
  valueInSec: number,
  selectedStreamingType: StreamingType,
  broadcastType: string
): number {
  if (broadcastType === 'live' && selectedStreamingType === StreamingType.HLS) {
    // using HLS on safari, the minimum value of video.currentTime() in time is now -4h
    // So we must substract this value to every date value in order to be able to use it
    const minStartoverTimeInSec: number = Date.now() / 1000 - STARTOVER_MAX_DURATION_SEC;

    return valueInSec - minStartoverTimeInSec;
  } else {
    return valueInSec;
  }
}

function browserTimeToAbsoluteTime(
  browserTime: number,
  selectedStreamingType: StreamingType,
  broadcastType: string
): number {
  if (broadcastType === 'live' && selectedStreamingType === StreamingType.HLS) {
    // opposite operation to absoluteTimeToBrowserTime
    const minStartoverTimeInSec: number = Date.now() / 1000 - STARTOVER_MAX_DURATION_SEC;

    return browserTime + minStartoverTimeInSec;
  } else {
    return browserTime;
  }
}

export { absoluteTimeToBrowserTime, browserTimeToAbsoluteTime, STARTOVER_MAX_DURATION_SEC };
