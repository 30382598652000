import React from 'react';
import { api, APIReplayPlaybackInfoResponse, StreamingType, VODTrailerPlaybackInfo } from '@oqee/core';
import { BrowserContext, BrowserContextType } from '../components/context/BrowserContextProvider';
import { getAdditionalConsentString, getConsentString } from '../utils/didomiUtils';
import { useWebStoreActions } from '../store/webStoreUtils';
import { ActionCreator } from 'easy-peasy';

type VODParams = {
  apiType: 'vod';
  trailer_ids: number[];
};

function useWebVodTrailerPlaybackInfo(params: VODParams): VODTrailerPlaybackInfo | null {
  const browserContext: BrowserContextType = React.useContext(BrowserContext);
  const triggerError: ActionCreator<any> = useWebStoreActions(actions => actions.error.trigger);
  const idParam: string = params.trailer_ids[0].toString();

  const [playbackInfo, setPlaybackInfo] = React.useState<VODTrailerPlaybackInfo | null>(null);

  const handlePlaybackInfoResponse = React.useCallback(
    (response: APIReplayPlaybackInfoResponse) => {
      if (!response.success) {
        triggerError({
          code: response.error?.code,
          data: response.error
        });
        return;
      }

      if (response.result) {
        const { media_url, position, subtitles = {}, thumbnails } = response.result;
        setPlaybackInfo({
          broadcastType: 'trailer',
          manifestUrl: media_url,
          position,
          promoToken: null,
          subtitles,
          thumbnails
        });
      }
    },
    [setPlaybackInfo, triggerError]
  );

  React.useEffect(() => {
    if (!idParam) {
      return;
    }

    const [supportedStreamType, supportedDrm, supportedCipher]: [string, string, (string | null | undefined)[]] =
      browserContext?.selectedStreamingType === StreamingType.HLS
        ? [StreamingType.HLS, 'fairplay', browserContext.availableCiphers || ['cbcs']]
        : [StreamingType.DASH, 'widevine', browserContext.availableCiphers || ['cenc']];

    const playbackInfoOptions = {
      supported_stream_types: [supportedStreamType],
      supported_drms: [supportedDrm],
      supported_ciphers: supportedCipher,
      supported_ads: ['vast', 'vmap'],
      consent_string: getConsentString(),
      additional_consent_string: getAdditionalConsentString()
    };

    // Get playbackInfos for svod or vod
    api.vod
      .requestTrailerPlaybackInfo(idParam, playbackInfoOptions)
      .then(handlePlaybackInfoResponse)
      .catch(error => {
        triggerError({ code: 'content_not_found', data: error });
      });
  }, [idParam]);

  return playbackInfo;
}

export default useWebVodTrailerPlaybackInfo;
