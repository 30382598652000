import React from 'react';
import { Button as UIButton } from '@oqee/ui';

import './Button.css';

interface ButtonProps {
  label?: string;
  onClick?: () => void;
  type?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  newWindowIcon?: boolean;
  tooltip?: string;
  icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  chipped?: boolean;
  rounded?: boolean;
  selected?: boolean;
  variant?: 'text' | 'contained' | 'outlined';
  className?: string;
}

function Button({
  label,
  onClick,
  type = 'secondary',
  size = 'large',
  disabled = false,
  tooltip,
  newWindowIcon,
  icon,
  ...attrs
}: ButtonProps) {
  return (
    <UIButton
      color={type}
      bold
      size={size}
      disabled={disabled}
      onClick={onClick}
      tooltip={tooltip}
      icon={icon}
      {...attrs}
    >
      {label}
      {newWindowIcon && <img className="ButtonNewWindowIcon" alt="" src="/img/link-new-window.svg" />}
    </UIButton>
  );
}

export default Button;
