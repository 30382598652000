import React from 'react';
import { useHomeContents } from '@oqee/core';

import MainLayout from '../../components/layout/MainLayout';
import ContentRows from '../../components/content/ContentRows';
import LiveSection from './LiveSection/LiveSection';
import { HeaderNav } from '../../utils/webUtils';
import ParentalGuard from '../../components/shared/ParentalGuard';

import './HomePage.css';

const HANDLED_TYPES = ['live', 'replay', 'collection', 'replay_collection'];

function HomePage() {
  const rows = useHomeContents({ validContentTypes: HANDLED_TYPES });

  return (
    <MainLayout activeHeaderNav={HeaderNav.FOR_YOU} fbScreenName="for_you">
      <ParentalGuard isAdult={false}>
        <LiveSection />
        <ContentRows rows={rows} hideCircularProgressInRows={['home-section-0-']} showCircularProgress />
      </ParentalGuard>
    </MainLayout>
  );
}

export default HomePage;
