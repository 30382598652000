import React from 'react';
import clsx from 'clsx';

import './Avatar.css';

interface AvatarProps {
  avatarUrl: string;
  color: string;
  size?: 'big' | 'small';
  active?: boolean;
}

function Avatar({ avatarUrl, color, size = 'big', active = false }: AvatarProps) {
  return (
    <div className={clsx('Avatar', size, { active })} style={{ backgroundColor: color }}>
      <img className="AvatarIcon" alt="" src={avatarUrl} />
    </div>
  );
}

export default Avatar;
