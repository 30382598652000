import React from 'react';
import { useAccountProfile } from '@oqee/core';

import Avatar from '../../../../components/shared/Avatar';
import Typography from '../../../../components/shared/Typography';

import './ProfileAvatarGrid.css';

interface ProfileAvatarGridProps {
  activeProfile?: any;
  onAvatarClick: (profileId: string) => void;
}

function ProfileAvatarGrid({ activeProfile, onAvatarClick }: ProfileAvatarGridProps) {
  const { profiles, getAvatarUrl } = useAccountProfile();

  return (
    <div className="ProfileAvatarGrid">
      {profiles.map((profile, profileIndex) => (
        <div className="ProfileGridElement" key={profileIndex}>
          <div className="ProfileAvatarContainer" onClick={() => onAvatarClick(profile.id)}>
            <Avatar
              avatarUrl={getAvatarUrl(profile)}
              active={activeProfile ? activeProfile.id === profile.id : false}
              color={profile.avatar_color}
            />
          </div>
          <Typography variant="h4" className="ProfileUsername">
            {profile.username}
          </Typography>
        </div>
      ))}
    </div>
  );
}

export default ProfileAvatarGrid;
