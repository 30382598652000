import React from 'react';
import clsx from 'clsx';

import { useAccountProfile } from '@oqee/core';

import './ProfileNamePicture.css';

interface ProfileNamePictureProps {
  picture: string;
  toneTag: string;
  colorTag: string;
}

function ProfileNamePicture({ picture, toneTag, colorTag }: ProfileNamePictureProps) {
  const { getAvatarUrl } = useAccountProfile();
  const pictureUrl = getAvatarUrl({
    url: picture,
    avatar_tone: toneTag
  });

  return (
    <div className={clsx('ProfileNamePicture')} style={{ backgroundColor: colorTag }}>
      <img src={pictureUrl} alt="" />
    </div>
  );
}

export default ProfileNamePicture;
