import React from 'react';

import InputText from '../../../../components/shared/InputText';

import './ProfileNameInput.css';

type ProfileNameInputProps = {
  username: string;
  handleChangeUsername: (value: string) => void;
};

function ProfileNameInput({ username, handleChangeUsername }: ProfileNameInputProps) {
  return (
    <InputText placeholder="Saisissez votre nom" maxLength={32} onChange={handleChangeUsername} value={username} />
  );
}

export default ProfileNameInput;
