import React from 'react';
import {
  api,
  APIRecordPlaybackInfoResponse,
  APIRecordContent,
  APIRecordEntry,
  RecordPlaybackInfo,
  StreamingType,
  useProgram,
  getAdditionalConsentString,
  getConsentString,
  isOqeeCustomization
} from '@oqee/core';
import { BrowserContext, BrowserContextType } from '../components/context/BrowserContextProvider';

import { useWebStoreActions } from '../store/webStoreUtils';
import { ActionCreator } from 'easy-peasy';

const { DRM_SYSTEM } = api.constant;

function useWebRecordPlaybackInfo(
  recordId: string,
  recordDetail: { isReady: boolean; programEntry: APIRecordEntry }
): RecordPlaybackInfo | null {
  const browserContext: BrowserContextType = React.useContext(BrowserContext);
  const triggerError: ActionCreator<any> = useWebStoreActions(actions => actions.error.trigger);
  const program = useProgram(recordDetail.programEntry);
  const recordContent: APIRecordContent = recordDetail.programEntry.record;
  const programTimeRange: [number, number] | undefined = program?.getTimeRange();
  const recordTimeRange: { start: number; end: number } =
    programTimeRange?.length === 2
      ? {
          start: programTimeRange[0] - recordContent.margin_before,
          end: programTimeRange[1] + recordContent.margin_after
        }
      : { start: 0, end: 0 };

  const [playbackInfo, setPlaybackInfo] = React.useState<RecordPlaybackInfo | null>(null);

  const handlePlaybackInfoResponse = React.useCallback(
    (response: APIRecordPlaybackInfoResponse) => {
      if (!response.success) {
        triggerError({
          code: response.error?.code,
          data: response.error
        });
        return;
      }

      if (response.result) {
        const {
          media_url,
          drm,
          license_server,
          media_headers,
          ads_info,
          position,
          subtitles = {},
          record_id,
          thumbnails
        } = response.result;

        setPlaybackInfo({
          broadcastType: 'record',
          manifestUrl: media_url,
          drmSystem: drm && DRM_SYSTEM?.[drm],
          drmServer: license_server,
          adsInfo: ads_info,
          position,
          promoToken: null,
          recordId: record_id,
          subtitles,
          mediaHeaders: media_headers,
          timeRange: recordTimeRange,
          thumbnails
        });
      }
    },
    [setPlaybackInfo, triggerError]
  );

  React.useEffect(() => {
    if (!recordId) {
      return;
    }

    const [supportedStreamType, supportedDrm, supportedCipher]: [string, string, (string | null | undefined)[]] =
      browserContext?.selectedStreamingType === StreamingType.HLS
        ? [StreamingType.HLS, 'fairplay', browserContext.availableCiphers || ['cbcs']]
        : [StreamingType.DASH, 'widevine', browserContext.availableCiphers || ['cenc']];

    const playbackInfoOptions = {
      supported_stream_types: [supportedStreamType],
      supported_drms: [supportedDrm],
      supported_ciphers: supportedCipher,
      supported_ads: ['vast', 'vmap'],
      consent_string: getConsentString(),
      additional_consent_string: getAdditionalConsentString()
    };

    api.npvr
      .fetchPlaybackInfo(recordId, playbackInfoOptions, isOqeeCustomization())
      .then(handlePlaybackInfoResponse)
      .catch(error => {
        triggerError({ code: 'content_not_found', data: error });
      });
  }, [recordId]);

  return playbackInfo;
}

export default useWebRecordPlaybackInfo;
