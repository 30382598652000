import { NavigateFunction, useLocation, useNavigate, Location } from 'react-router-dom';
import React from 'react';
import shaka from 'shaka-player/dist/shaka-player.ui';
import { ActionCreator } from 'easy-peasy';
import { useWebStoreActions } from '../../../../store/webStoreUtils';
import { ShakaPlayerProps } from '../ShakaPlayer';

const BackButton = class extends shaka.ui.Element {
  constructor(parent, controls, handleBack) {
    super(parent, controls);
    const this_ = this as any;
    this_.button_ = document.createElement('img');
    this_.button_.src = '/img/player/back-arrow.svg';
    this_.button_.classList.add('back-button');

    this_.video.parentNode.appendChild(this_.button_);
    this_.eventManager.listen(this_.button_, 'click', handleBack);
  }
} as any;

// Factory that will create a button at run time.
BackButton.Factory = class {
  handleBack: () => void;

  constructor(handleBack) {
    this.handleBack = handleBack;
  }

  create(rootElement, controls) {
    return new BackButton(rootElement, controls, this.handleBack);
  }
};

interface ShakaPlayerUiBackButtonProps extends ShakaPlayerProps {}

function ShakaPlayerUiBackButton({ isLivePreview }: ShakaPlayerUiBackButtonProps) {
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const setPendingPlayerExitRequest: ActionCreator<(() => void) | null> = useWebStoreActions(
    actions => actions.webPlayer.setPendingPlayerExitRequest
  );

  const handleBack = () =>
    isLivePreview
      ? document.exitFullscreen()
      : setPendingPlayerExitRequest(() => (location.key !== 'default' ? navigate(-1) : navigate('/home')));

  React.useEffect(() => {
    // Register our factory with the controls, so controls can create button instances.
    shaka.ui.Controls.registerElement(
      /* This name will serve as a reference to the button in the UI configuration object */
      'oqee_back_button',
      new BackButton.Factory(handleBack)
    );
  }, [navigate]);

  return null;
}

export default ShakaPlayerUiBackButton;
