import React, { useState, useCallback } from 'react';
import Button from '../Button';
import Typography from '../Typography';
import ModalOpaqueBackground from '../ModalOpaqueBackground';

import usePortal from '../../../hooks/usePortal';

import './Modal.css';

interface ModalProps {
  title?: string;
  description?: string | React.ReactNode;
  onCancel?: () => void;
  onValidate?: () => void;
  cancelButtonLabel?: string;
  validateButtonLabel?: string;
  hasPadding?: boolean;
}

function Modal({
  title = '',
  description = '',
  onCancel,
  onValidate,
  validateButtonLabel = 'Confirmer',
  cancelButtonLabel = 'Annuler',
  hasPadding = true
}: ModalProps) {
  return (
    <div className="ModalPortal">
      <ModalOpaqueBackground />
      <div className="ModalContainer">
        <div className={`Modal ${hasPadding ? 'hasPadding' : ''}`}>
          <div className="ModalTitle">
            <Typography variant="h4" bold maxLines={1}>
              {title}
            </Typography>
          </div>
          <div className="ModalContent">
            {typeof description === 'string' ? (
              <Typography variant="body1">
                <span
                  dangerouslySetInnerHTML={{
                    __html: description
                  }}
                ></span>
              </Typography>
            ) : (
              description
            )}
          </div>
          <div className="ModalActions">
            {onCancel && <Button label={cancelButtonLabel} onClick={onCancel} />}
            {onValidate && <Button type="primary" label={validateButtonLabel} onClick={onValidate} />}
          </div>
        </div>
      </div>
    </div>
  );
}

function useModal(props: ModalProps, autoClose = true, autoMount = false) {
  const Portal = usePortal(document.getElementById('modal-root'));
  const [state, setState] = useState({ opened: autoMount });

  const openModal = useCallback(() => setState({ ...state, opened: true }), [state]);
  const closeModal = useCallback(() => setState({ ...state, opened: false }), [state]);
  let onCancel = props.onCancel;
  let onValidate = props.onValidate;

  if (autoClose) {
    if (props.onCancel) {
      onCancel = () => {
        closeModal();
        props.onCancel && props.onCancel();
      };
    }
    onValidate = () => {
      closeModal();
      props.onValidate && props.onValidate();
    };
  }

  const isOpenedModal = state.opened;
  const isClosedModal = !state.opened;

  const ModalComponent = useCallback(() => {
    return isOpenedModal ? (
      <Portal>
        <Modal {...props} onCancel={onCancel} onValidate={onValidate} />
      </Portal>
    ) : null;
  }, [props]);

  return {
    Modal: ModalComponent,
    openModal,
    closeModal,
    isOpenedModal,
    isClosedModal
  };
}

export default Modal;
export { useModal };
