import { analyticsApi } from '@oqee/core';
import UAParser, { IOS } from 'ua-parser-js';

function initializeAnalytics() {
  if (!import.meta.env.VITE_FB_ENABLED) return;
  analyticsApi.initialize({
    firebase: {
      apiKey: import.meta.env.VITE_FB_API_KEY,
      projectId: import.meta.env.VITE_FB_PROJECT_ID,
      appId: import.meta.env.VITE_FB_APP_ID,
      measurementId: import.meta.env.VITE_FB_MESUREMENT_ID
    }
  });

  setUserProperties();
}

function setUserProperties() {
  const uaParser = new UAParser(navigator.userAgent);
  const os: IOS = uaParser.getOS();

  const appVersion = import.meta.env.VITE_RELEASE;
  const deviceType = 'web';
  const deviceModel = 'web';
  const osName = os?.name ?? 'unknown';
  const osVersion = os?.version ?? 'unknown';

  const userProperties = {
    app_version: appVersion,
    os: osName,
    os_version: osVersion,

    oqee_app_version: appVersion,
    oqee_device_type: deviceType,
    oqee_device_model: deviceModel,
    oqee_os: osName,
    oqee_os_version: osVersion,
    oqee_conn_type: 'unknown'
  };
  analyticsApi.setUserProperties(userProperties);
}

export { initializeAnalytics };
