import React from 'react';
import { BrowserRouter, Navigate, Route, Routes, useOutlet } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-dom-last-location';
import { StoreProvider } from 'easy-peasy';

import {
  api,
  CONSTANTS,
  useAccountProfile,
  useChannelEpgAll,
  useCoreStoreActions
} from '@oqee/core';

import ApplicationError from './components/application/ApplicationError';
import BrowserContextProvider from './components/context/BrowserContextProvider';
import HomePage from './routes/home';
import ChannelsPage from './routes/channels';
import ReplayHomePage from './routes/replay/ReplayHomePage';
import ReplayPortalPage from './routes/replay/ReplayPortalPage';
import LivePlayerPage from './routes/play/LivePlayerPage';
import SettingsRoute from './routes/settings/SettingsRoute';
import ReplayPlayerPage from './routes/play/ReplayPlayerPage';
import RecordPlayerPage from './routes/play/RecordPlayerPage';
import VodPortalPage from './routes/vod/VodPortalPage';
import VodGroupPage from './routes/vod/VodGroupPage';
import VodGridPage from './routes/vod/VodGridPage';
import TVodPlayerPage from './routes/play/TVodPlayerPage';
import MyListHomePage from './routes/myList/MyListHomePage';
import MyListRecordsPage from './routes/myList/MyListRecordsPage';
import MyListVodPage from './routes/myList/MyListVodPage';
import LogoutPage from './routes/login/LogoutPage';
import OffersPage from './routes/login/OffersPage';
import MobileLandingPage from './components/mobile/MobileLandingPage';
import UserOnboarding from './routes/settings/profile/UserOnboarding';
import RequireAuthentication from './components/authentication/RequireAuthentication';
import SVodPlayerPage from './routes/play/SVodPlayerPage';
import VodTrailerPlayerPage from './routes/play/TrailerPlayerPage';
import DeeplinkPlayerPage from './routes/play/DeeplinkPlayerPage';
import ReplayDetailPage from './routes/replay/ReplayDetailPage/ReplaySingle';
import DiffusionDetailPage from './routes/diffusion/DiffusionDetailPage/DiffusionSingle';
import ChannelDetailPage from './routes/channels/ChannelDetailPage';
import VodDetailPage from './routes/vod/VodDetailPage';
import RecordDetailPage from './routes/record/RecordDetailPage';
import { webStore } from './store/webStoreUtils';
import ErrorBoundary from './components/application/ErrorBoundary';
import LoginSwitchPage from './routes/login/LoginSwitchPage';
import ProfileOnboardingManage from './routes/settings/profile/ProfileOnboardingManage';
import SearchPage from './routes/search';
import MultiPage from './components/multi/MultiPage';
import Toaster from './components/shared/Toaster';

import './App.css';

function ApplicationChannelEpg() {
  useChannelEpgAll();
  return null;
}

function ApplicationConfig({ children }) {
  const [ isReady, setReady ] = React.useState(false);
  const config = useCoreStoreActions(actions => actions.config);

  React.useEffect(() => {
    api.cache.initialize();
    config.load();
    setReady(true);
  }, [ config ]);

  if (!isReady) {
    return null;
  }
  return children;
}

function ApplicationBounding({ children }) {
  return (
    <ErrorBoundary>
      <MobileLandingPage>
        <BrowserContextProvider>
          <BrowserRouter>
            <LastLocationProvider>
              <StoreProvider store={webStore}>
                <ApplicationConfig>
                  <ApplicationChannelEpg />
                </ApplicationConfig>
                {children}
              </StoreProvider>
            </LastLocationProvider>
          </BrowserRouter>
        </BrowserContextProvider>
      </MobileLandingPage>
    </ErrorBoundary>
  );
}

function RequireActiveProfile() {
  const outlet = useOutlet();
  const { profiles, activeProfileId } = useAccountProfile();

  if (activeProfileId) {
    return outlet;
  } else if (profiles.length === 0) {
    return <Navigate to="/onboarding/create" />;
  } else {
    return <ProfileOnboardingManage />;
  }
}

function App() {
  return (
    <ApplicationBounding>
      <Routes>
        <Route path="/login_switch" element={<LoginSwitchPage />} />
        <Route path="/offers" element={<OffersPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route element={<RequireAuthentication />}>
          <Route path="/onboarding/*" element={<UserOnboarding />} />
          <Route element={<RequireActiveProfile />}>
            <Route path="/home" element={<HomePage />} />
            <Route path="/home/channels" element={<ChannelsPage />}>
              <Route path=":channelId/play" element={<LivePlayerPage />} />
              <Route path=":channelId/detail" element={<ChannelDetailPage />} />
            </Route>
            <Route path="/home/favorite_channels" element={<ChannelsPage />}>
              <Route path=":channelId/play" element={<LivePlayerPage />} />
              <Route path=":channelId/detail" element={<ChannelDetailPage />} />
            </Route>
            <Route path="/home/replay/*" element={<ReplayHomePage />} />
            <Route path="/replay/:strPortalId" element={<ReplayPortalPage />} />
            <Route path={`/vod/portal_home/${CONSTANTS.OQEE_CINE_PORTAL_ID}`} element={<VodPortalPage />} />
            <Route path={`/vod/${CONSTANTS.OQEE_CINE_PORTAL_ID}`} element={<VodPortalPage />} />
            <Route
              path="/replay_collection/:collectionId/:segmentedId?"
              element={<MultiPage contentType="collection_replay" baseUrl="replay_collection" />}
            />
            <Route
              path="/replay_collection/:collectionId/detail/:segmentedId?"
              element={<MultiPage contentType="collection_replay" baseUrl="replay_collection" />}
            />
            <Route
              path="/collection/:collectionId/:segmentedId?"
              element={<MultiPage contentType="collection" baseUrl="collection" />}
            />
            <Route
              path="/collection/:collectionId/detail/:segmentedId?"
              element={<MultiPage contentType="collection" baseUrl="collection" />}
            />
            <Route path="/vod/group/:id" element={<VodGroupPage />} />
            <Route path="/vod/grid/:id" element={<VodGridPage />} />
            <Route path="/home/my_list" element={<MyListHomePage />} />
            <Route path="/home/npvr" element={<MyListHomePage />} />
            <Route path="/my_list/records" element={<MyListRecordsPage />} />
            <Route path="/my_list/vod" element={<MyListVodPage />} />

            <Route path="/replay/:contentId/detail" element={<ReplayDetailPage />} />
            <Route path="/diffusion/:contentId/detail" element={<DiffusionDetailPage />} />
            <Route path="/svod/:contentId" element={<VodDetailPage type="vod" />} />
            <Route path="/vod/contents/:contentId" element={<VodDetailPage type="vod" />} />
            <Route path="/vod/portal/:portalId/contents/:contentId" element={<VodDetailPage type="vod" />} />
            <Route path="/tvod/:portalId/:contentId" element={<VodDetailPage type="vod_portal" />} />
            <Route path="/record/:recordId" element={<RecordDetailPage />} />

            <Route path="/channel/:channelId/play" element={<LivePlayerPage />} />
            <Route path="/replay/:contentId/play" element={<ReplayPlayerPage />} />
            <Route path="/svod/:contentId/play" element={<SVodPlayerPage />} />
            <Route path="/tvod/:purchaseId/play" element={<TVodPlayerPage />} />
            <Route path="/vod/:providerId/:contentId/trailer" element={<VodTrailerPlayerPage />} />
            <Route path="/record/:recordId/play" element={<RecordPlayerPage />} />
            <Route path="/content/:id/play" element={<DeeplinkPlayerPage />} />

            <Route path="/settings/*" element={<SettingsRoute />} />
            <Route path="/home/search" element={<SearchPage />} />
            <Route
              path="/search-collection/:collectionId/:segmentedId?"
              element={<MultiPage contentType="collection" baseUrl="search-collection" />}
            />
            <Route
              path="/search-multiprogram/:collectionId/:segmentedId?"
              element={<MultiPage contentType="multiprogram" baseUrl="search-multiprogram" />}
            />
            <Route
              path="/content/:collectionId/:segmentedId?"
              element={<MultiPage contentType="multiprogram" baseUrl="search-multiprogram" />}
            />
            <Route
              path="/search-replay_collection/:collectionId/:segmentedId?"
              element={<MultiPage contentType="collection_replay" baseUrl="search-replay_collection" />}
            />
            {/* Redirect to HomePage if route does not exist */}
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Route>
        </Route>
      </Routes>
      <ApplicationError />
      <Toaster className="ToasterApp" />
    </ApplicationBounding>
  );
}

export default App;
