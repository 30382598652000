import React from 'react';
import Logger from 'js-logger';
import shaka from 'shaka-player/dist/shaka-player.ui';
import {
  AuthModelState,
  OAuth,
  addDrmMessage,
  api,
  base64Encode,
  base64ToUint8Array,
  useAccountProfile
} from '@oqee/core';

import { ShakaPlayerProps } from './ShakaPlayer';
import { useWebStoreActions, useWebStoreState } from '../../../store/webStoreUtils';
import { ActionCreator } from 'easy-peasy';

const logger = Logger.get('ShakaPlayerNetwork');

interface LicenseResponseResult {
  license: string;
  expiration: number;
}

interface LicenseResponseError {
  code: string;
}

interface LicenseResponseSuccess {
  success: true;
  result: LicenseResponseResult;
}

interface LicenseResponseFailure {
  success: false;
  error: LicenseResponseError;
}

type LicenseResponse = LicenseResponseSuccess | LicenseResponseFailure;

interface ShakaPlayerNetworkProps extends ShakaPlayerProps {
  player: shaka.Player;
}

function ShakaPlayerNetwork({ player, playbackInfo, streamRef }: ShakaPlayerNetworkProps) {
  const { promoToken, broadcastType } = playbackInfo;
  const mediaHeaders: any = (playbackInfo.broadcastType === 'record' && playbackInfo.mediaHeaders) ?? {};
  const authState: AuthModelState = useWebStoreState(actions => actions.auth);
  const triggerError: ActionCreator<any> = useWebStoreActions(actions => actions.error.trigger);
  const { activeProfile } = useAccountProfile();
  const oauth: OAuth | null = api.auth.getOAuth();

  /**
   * Handling license requests
   */
  React.useEffect(() => {
    const networkEngine = player.getNetworkingEngine();
    if (!networkEngine) return;

    function licenseRequestFilter(requestType, request) {
      const { body } = request;
      const isManifestRequest = requestType === shaka.net.NetworkingEngine.RequestType.MANIFEST;
      const isLicenseRequest = requestType === shaka.net.NetworkingEngine.RequestType.LICENSE;

      if ((!isManifestRequest && !isLicenseRequest) || (isManifestRequest && !body && broadcastType !== 'record')) {
        return;
      }

      const { oqee, cat5Token, tvRights } = authState;
      const { token: oqeeToken } = oqee;
      const { token: tvRightsToken } = tvRights;
      if (!oqeeToken) {
        logger.error('oqeeToken is missing !');
        return;
      }
      if (broadcastType === 'replay' && !tvRightsToken) {
        logger.error('tvRightsToken is missing !');
        return;
      }

      if (isLicenseRequest) {
        if (!body) {
          return;
        }

        const base64License = base64Encode(new Uint8Array(body));
        if (!base64License) {
          logger.error('license is empty !');
          return;
        }
        // Log license message
        const currentMessage = [new Date().toISOString(), base64License];
        streamRef.current.drmMessages = addDrmMessage(streamRef.current.drmMessages, currentMessage);

        const wrappedJson = JSON.stringify({
          licenseRequest: base64License
        });
        request.body = shaka.util.StringUtils.toUTF8(wrappedJson);
      }

      request.headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oqeeToken}`,
        'X-Fbx-Rights-Token': tvRightsToken,
        'X-Oqee-Platform': 'web',
        ...(cat5Token && {
          'X-Fbx-Cat-5-Token': cat5Token
        }),
        ...(promoToken && {
          'X-Fbx-Promo-Token': promoToken
        }),
        ...(mediaHeaders && { ...mediaHeaders }),
        'X-Oqee-Profile': activeProfile.id,
        'X-Oqee-Customization': 1,
        'X-Oqee-Account-Provider': oauth ? oauth.provider : 'free'
      };
    }

    networkEngine.registerRequestFilter(licenseRequestFilter);
    return () => {
      networkEngine.unregisterRequestFilter(licenseRequestFilter);
    };
  }, [player, promoToken]);

  /**
   * Handling license responses
   */
  React.useEffect(() => {
    const networkEngine: shaka.net.NetworkingEngine | null = player.getNetworkingEngine();
    if (!networkEngine) return;

    function licenseResponseFilter(type, response) {
      if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) return;

      const wrappedString = shaka.util.StringUtils.fromUTF8(response.data);
      const wrapped: LicenseResponse = JSON.parse(wrappedString);
      const { success } = wrapped;

      if (success) {
        const { result } = wrapped;
        const { license: rawLicenseBase64 } = result;
        response.data = base64ToUint8Array(rawLicenseBase64);
      } else {
        const { error } = wrapped;
        const { code } = error;
        triggerError({
          code,
          data:
            broadcastType === 'live' && code === 'need_subscription'
              ? { channelNumber: playbackInfo.liveChannel.number }
              : error[code],
          description: `Une erreur de licence est survenue`
        });
      }
    }

    networkEngine.registerResponseFilter(licenseResponseFilter);
    return () => {
      networkEngine.unregisterResponseFilter(licenseResponseFilter);
    };
  }, [player]);

  return null;
}

export default ShakaPlayerNetwork;
