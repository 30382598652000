import React, { MutableRefObject } from 'react';
import shaka from 'shaka-player/dist/shaka-player.ui';
import { PlaybackInfo } from '@oqee/core';
import { ActionCreator } from 'easy-peasy';

import { BrowserContext, BrowserContextType } from '../../context/BrowserContextProvider';
import { useWebStoreActions } from '../../../store/webStoreUtils';
import ShakaPlayerUi from './ui/ShakaPlayerUi';
import ShakaPlayerController from './ShakaPlayerController';
import ShakaPlayerNetwork from './ShakaPlayerNetwork';
import ShakaPlayerInterstices from './ShakaPlayerInterstices';
import ShakaPlayerLoading from './ShakaPlayerLoading';

import 'shaka-player/dist/controls.css';
import './ShakaPlayer.css';

interface ShakaPlayerProps {
  videoEl: HTMLVideoElement;
  uiContainerEl: HTMLDivElement;
  playbackInfo: PlaybackInfo;
  streamRef: MutableRefObject<any>;
  isLivePreview: boolean;
  preScreenImageEl: HTMLImageElement;
  preScreenVideoEl: HTMLVideoElement;
  startOver?: boolean;
}

function ShakaPlayer(props: ShakaPlayerProps) {
  const { videoEl } = props;
  const triggerError: ActionCreator<any> = useWebStoreActions(actions => actions.error.trigger);
  const browserContext: BrowserContextType = React.useContext(BrowserContext);
  const [player, setPlayer] = React.useState<shaka.Player | null>(null);

  const isBrowserSupported: boolean = browserContext.isBrowserSupported;

  React.useEffect(() => {
    /* Creates a shaka.Player instance */
    if (!isBrowserSupported) {
      triggerError({ code: 'unsupported_browser' });
    } else {
      // NO IDEA WHY BUT THIS ORIENTATION POLYFILL THAT FIXES FULLSCREEN EXIT ON SAFARI
      // IS EFFECTIVE ONLY WHEN INSTALLED BEFORE shaka.Player OBJECT CREATION ?!?!
      shaka.polyfill.Orientation.install();
      // disable documentPictureInPicture and revert back to video-only PIP
      if ('documentPictureInPicture' in window) {
        delete window.documentPictureInPicture;
      }

      const _player = new shaka.Player();
      _player.attach(videoEl);

      setPlayer(_player);
      // Exported for debug
      window['player'] = _player;

      return () => {
        _player?.destroy();
      };
    }
  }, [isBrowserSupported]);

  if (!player) {
    return null;
  }
  return (
    <>
      <ShakaPlayerLoading player={player} />
      <ShakaPlayerController player={player} {...props} />
      <ShakaPlayerNetwork player={player} {...props} />
      <ShakaPlayerUi player={player} {...props} />

      {browserContext?.ima ? <ShakaPlayerInterstices player={player} ima={browserContext.ima} {...props} /> : null}
    </>
  );
}

export default ShakaPlayer;
export type { ShakaPlayerProps };
