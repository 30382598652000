import React from 'react';

import { MyListNav } from '../../utils/webUtils';

import MyListLayout from '../../components/layout/MyListLayout';
import EmptyResult from '../../components/shared/EmptyResult';

const MyListRecordsPage = () => (
  <MyListLayout
    pageId={MyListNav.RECORDS}
    filter={['record']}
    fallback={<EmptyResult title="Vous n'avez pas encore d'enregistrement" />}
  />
);

export default MyListRecordsPage;
