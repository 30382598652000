import React from 'react';
import * as Sentry from '@sentry/react';
import ErrorTemplate from '../shared/ErrorTemplate';
import Button from '../shared/Button';

function FatalError({ error }) {
  React.useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <ErrorTemplate>
      <ErrorTemplate.Title>Erreur interne</ErrorTemplate.Title>

      <ErrorTemplate.Description>
        Un probl&egrave;me est survenu, un red&eacute;marrage de l&apos;application est n&eacute;cessaire.
      </ErrorTemplate.Description>

      <ErrorTemplate.Menu>
        <Button
          label="OK"
          type="primary"
          size="large"
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        />
      </ErrorTemplate.Menu>
    </ErrorTemplate>
  );
}

function fatalError({ error }) {
  return <FatalError error={error} />;
}

function ErrorBoundary({ children }: React.PropsWithChildren) {
  return <Sentry.ErrorBoundary fallback={fatalError}>{children}</Sentry.ErrorBoundary>;
}

export default ErrorBoundary;
