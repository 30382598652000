import React from 'react';

import './Lock.css';

interface LockProps {
  isOpen: boolean;
}

function Lock({ isOpen }: LockProps) {
  return (
    <div className="LockDiv">
      <div className="LockRectangle" />
      <img className="LockImage" alt="" src={`/img/lock-${isOpen ? 'open' : 'closed'}.svg`} />
    </div>
  );
}

export default Lock;
