import React from 'react';
import { useWebStoreActions } from '../../store/webStoreUtils';
import { useModal } from '../shared/Modal';
import { ErrorModelState } from '@oqee/core';


type ProgramAlreadyStartedWarningProps = {
  error: ErrorModelState;
}

function ProgramAlreadyStartedWarning({ error }: ProgramAlreadyStartedWarningProps) {
  const resetError = useWebStoreActions(actions => actions.error.reset);

  const { Modal } = useModal(
    {
      title: error?.title || 'Enregistrement',
      description: 'Votre programme a déjà débuté, l’enregistrement sera incomplet.',
      validateButtonLabel: `D'accord`,
      onValidate() {
        resetError();
      }
    },
    false,
    true
  );

  return <Modal />;
}

export default ProgramAlreadyStartedWarning;
