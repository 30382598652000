import React from 'react';
import { useAccountProfile } from '@oqee/core';

import Typography from '../../../../components/shared/Typography';
import Toggle from '../../../../components/shared/Toggle';

import ProfileAvatarGrid from '../ProfileAvatarGrid';

import PageLayout from '../../../../components/layout/PageLayout';

import './ProfileOnboardingManage.css';

function ProfileOnboardingManage() {
  const { activeProfileId, profiles, lastActiveProfiles, setActive, selectProfileOnStart, setSelectProfileOnStart } = useAccountProfile();

  // Keep the initial value
  const [ shouldSelectProfileOnStart ] = React.useState(selectProfileOnStart);

  const onToggle = () => setSelectProfileOnStart(!selectProfileOnStart);

  /**
   * Auto select profile
   */
  React.useEffect(() => {
    if (shouldSelectProfileOnStart || activeProfileId) return;

    const lastActiveProfile = lastActiveProfiles && lastActiveProfiles.length && lastActiveProfiles[0];
    const [ firstProfile ] = profiles;
    const autoSelectedProfile = lastActiveProfile || firstProfile;
    setActive(autoSelectedProfile.id);
  }, [ profiles, shouldSelectProfileOnStart, activeProfileId, lastActiveProfiles, setActive ]);

  return (
    <PageLayout>
      <div className="ProfileOnboardingManage">
        <div className="ProfileOnboardingManageTitle">
          <Typography variant="h1">Choisissez un profil</Typography>
          <Typography variant="h4">Qui regarde OQEE by Free aujourd’hui ?</Typography>
        </div>
        <ProfileAvatarGrid onAvatarClick={setActive} />

        <div className="ProfileOnboardingManageSwitchSection">
          <Toggle label="Afficher cet écran au démarrage" value={selectProfileOnStart} onToggle={onToggle} />

          <Typography variant="body1" color="grey-3" className="ProfileOnboardingManageSwitchSectionTextInfo">
            Vous pourrez modifier ce choix à tout moment dans vos réglages
          </Typography>
        </div>
      </div>
    </PageLayout>
  );
}

export default ProfileOnboardingManage;
