import React from 'react';
import { PICTURE_FORMAT_WEB } from '@oqee/core';
import { Metadata, MixedLine, Typography } from '@oqee/ui';
import SinglePageLayout from '../layout/SinglePageLayout';
import ErrorHandler from '../shared/ErrorHandler';
import ProgramCTA from '../cta/ProgramCTA';
import ProgramTitle from '../shared/ProgramTitle';

type ReplaySinglePageProps = {
  program: any;
  isResumable?: boolean;
  onResumableDelete?: () => void;
};

const ReplaySinglePage: React.FC<ReplaySinglePageProps> = ({ program, isResumable, onResumableDelete }) => {
  const providerIconUrl = program.getIcon('light');

  const { content } = program;
  const thumbnailUrl = program.getThumbnailUrl(PICTURE_FORMAT_WEB.THUMBNAIL);
  const { title, sub_title, description, meta, global_data } = content;

  return (
    <>
      <SinglePageLayout
        thumbnailUrl={thumbnailUrl}
        title={<ProgramTitle progressRing={{ logoUrl: providerIconUrl }} title={title} subtitle={sub_title} />}
      >
        {global_data && <MixedLine mixedLine={global_data} program={program} />}
        <ProgramCTA program={program} isResumable={isResumable} onResumableDelete={onResumableDelete} />
        <Typography>{description}</Typography>
        <Metadata variant="body1" metadata={meta} />
      </SinglePageLayout>
      <ErrorHandler />
    </>
  );
};

export default ReplaySinglePage;
