import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useToaster } from '@oqee/core';

import { useWebStoreActions, useWebStoreState } from '../../store/webStoreUtils';
import errorRegistry, { WebErrorRegistryEntry } from '../../lib/errorRegistry';
import ErrorTemplate from '../shared/ErrorTemplate';
import Button from '../shared/Button';

function useApplicationErrorHook() {
  const navigate = useNavigate();

  return {
    navigate
  };
}

interface ComponentWithErrorProps {
  error: WebErrorRegistryEntry;
}
function ApplicationErrorScreen({ error }: ComponentWithErrorProps) {
  const actionProps = useApplicationErrorHook();

  const description =
    typeof error.description === 'string' ? error.description : error.description ? error.description({ error }) : '';

  React.useEffect(() => {
    if (!description || typeof description !== 'string') return;

    Sentry.captureMessage(description);
  }, [description]);

  return (
    <ErrorTemplate>
      <ErrorTemplate.Title>{error.title}</ErrorTemplate.Title>
      <ErrorTemplate.Description>{description}</ErrorTemplate.Description>

      <ErrorTemplate.Menu>
        {(error.menuItems || []).map((menuItem, index) => (
          <Button
            key={index}
            label={menuItem.label}
            type={index === 0 ? 'primary' : 'secondary'}
            size="large"
            onClick={() => menuItem.action(actionProps)}
          />
        ))}
      </ErrorTemplate.Menu>
    </ErrorTemplate>
  );
}

function ApplicationErrorToast({ error }: ComponentWithErrorProps) {
  const { title = '' } = error;
  const resetError = useWebStoreActions(actions => actions.error.reset);
  const { addMessage } = useToaster();

  React.useEffect(() => {
    addMessage(title);
    resetError();
  }, [addMessage, title]);

  return null;
}

function ApplicationError() {
  const errorCode: string | null = useWebStoreState(state => state.error.code);

  const error = errorCode ? errorRegistry[errorCode] : null;

  if (error?.component) return error.component();
  if (error?.isApplicationError) return <ApplicationErrorScreen error={error} />;
  if (error?.isApplicationWarning) return <ApplicationErrorToast error={error} />;

  return null;
}

export default ApplicationError;
