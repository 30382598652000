import React from 'react';
import { PICTURE_FORMAT_WEB } from '@oqee/core';
import { Metadata, Typography, castToMetadata, MixedLine } from '@oqee/ui';
import SinglePageLayout from '../layout/SinglePageLayout';
import { useWebStoreState } from '../../store/webStoreUtils';
import ErrorHandler from '../shared/ErrorHandler';
import ProgramCTA from '../cta/ProgramCTA';
import ProgramTitle from '../shared/ProgramTitle';

interface ChannelSinglePageProps {
  program: any;
}

const ChannelSinglePage: React.FC<ChannelSinglePageProps> = ({ program }) => {
  const timestamp: number = useWebStoreState(state => state.channel.timestamp);
  const thumbnailUrl = program.getThumbnailUrl(PICTURE_FORMAT_WEB.THUMBNAIL);
  const providerIconUrl = program.getIcon('light');

  const [start, end] = program.getTimeRange();
  const progress = (timestamp - start) / (end - start);

  const { channel, content } = program;

  const { title, description, casting, sub_title, global_data } = content;

  const meta = castToMetadata(casting);

  return (
    <>
      <SinglePageLayout
        thumbnailUrl={thumbnailUrl}
        title={
          <ProgramTitle
            progressRing={{ logoUrl: providerIconUrl, progress, circleColor: channel?.color }}
            title={title}
            subtitle={sub_title}
          />
        }
      >
        {global_data && <MixedLine mixedLine={global_data} />}
        <ProgramCTA program={program} />
        <Typography>{description}</Typography>
        <Metadata variant="body1" metadata={meta} />
      </SinglePageLayout>
      <ErrorHandler />
    </>
  );
};

export default ChannelSinglePage;
