import React from 'react';
import { APIVodPortalHomeLine, CONSTANTS } from '@oqee/core';

import MainLayout from '../../components/layout/MainLayout';
import { HeaderNav } from '../../utils/webUtils';
import ContentRows from '../../components/content/ContentRows';
import useWebVodPortal from '../../hooks/useWebVodPortal';

function VodPortalPage() {
  const vodPortalLines: APIVodPortalHomeLine[] | undefined = useWebVodPortal(CONSTANTS.OQEE_CINE_PORTAL_ID);

  return (
    <MainLayout activeHeaderNav={HeaderNav.VOD}>
      <ContentRows rows={vodPortalLines || []} showTileTitle={false} />
    </MainLayout>
  );
}

export default VodPortalPage;
