import React from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { FreeLetterIcon, Typography } from '@oqee/ui';
import { OAuthError } from '@oqee/core';

import PageLayout from '../../../components/layout/PageLayout';
import Button from '../../../components/shared/Button';
import LandingHeader from '../LandingHeader';
import LandingFooter from '../../../components/layout/Footer';

import './LoginErrorPage.css';

interface LoginErrorPageProps {
  oauthError: OAuthError;
}

function LoginErrorPage({ oauthError: { errorCode, provider, errorData } }: LoginErrorPageProps) {
  const navigate: NavigateFunction = useNavigate();
  const providerName: string = provider === 'free' ? 'Freebox' : 'Free mobile';

  return (
    <PageLayout>
      <div className="LoginErrorPage">
        <LandingHeader />
        <div className="w-container">
          <div className="fil-dariane">
            <Link to="/login_switch">
              <div className="BackLinkContainer">
                <div className={`BackArrow`}></div>
                <Typography variant="body2">Retour</Typography>
              </div>
            </Link>
          </div>
        </div>
        <div className="LoginErrorLogo">
          <img src="/img/oqee-by-free.svg" width="150" alt="logo OQEE" />
        </div>
        <div className="LoginErrorColumn">
          {errorCode === 'enforce_freebox' && errorData ? (
            <div className="LoginErrorSection">
              <div className="LoginErrorSectionHead">
                <Typography variant="h3">Compte Freebox associé</Typography>
                <Typography variant="body1" className="LoginErrorDescription">
                  <p>
                    Votre compte Free Mobile est rattaché au compte Freebox&nbsp;
                    {errorData?.enforce_freebox?.freebox_id}.
                  </p>
                  <p>
                    Connectez-vous avec vos identifiants Freebox afin de bénéficier de <br />
                    toutes les fonctionnalités d&apos;OQEE.
                  </p>
                </Typography>
              </div>
              <div className="LoginErrorSectionBody">
                <div className="LoginErrorButtons">
                  <Button
                    label="Se connecter avec Freebox"
                    size="large"
                    type="primary"
                    icon={FreeLetterIcon}
                    onClick={() => location.assign(errorData.enforce_freebox.redirect_url)}
                  />
                </div>
              </div>
            </div>
          ) : errorCode === 'not_eligible' ? (
            <div className="LoginErrorSection">
              <div className="LoginErrorSectionHead">
                <Typography variant="h3">Votre offre {providerName}</Typography>
                <Typography variant="body1" className="LoginErrorDescription">
                  Votre offre {providerName} ne permet pas d’accéder à l’application OQEE.
                </Typography>
              </div>
              <div className="LoginErrorSectionBody">
                <div className="LoginErrorButtons">
                  <Button
                    label="Retour à l'accueil"
                    size="large"
                    type="secondary"
                    onClick={() => navigate('/logout')}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <LandingFooter />
      </div>
    </PageLayout>
  );
}

export default LoginErrorPage;
