import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import 'react-tooltip/dist/react-tooltip.css';

interface TooltipProps {
  id: string;
}

function Tooltip({ id }: TooltipProps) {
  return (
    <ReactTooltip
      id={id}
      noArrow
      offset={3}
      opacity={1}
      border="1px solid #505050"
      style={{
        zIndex: 10000,
        fontSize: '12px',
        padding: '4px 6px',
        borderRadius: 0,
        background: '#252525'
      }}
    />
  );
}

export default Tooltip;
