import { CoreModel, coreModel } from '@oqee/core';
import webPlayer from './model/webPlayer';
import WebPlayerModel from './types/WebPlayerModel';

interface WebModel extends CoreModel {
  webPlayer: WebPlayerModel;
}

const webModel: WebModel = {
  ...coreModel,
  webPlayer
};

export { webModel };
export type { WebModel };
