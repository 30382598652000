import React from 'react';
import { useChannelSubscription, usePurchaseCode } from '@oqee/core';
import PurchaseCodeModal from '../../shared/PurchaseCodeModal';

function ChannelSubscriptionCheckout() {
  const { isReady, purchaseCode, codeLocked } = usePurchaseCode();

  const { selectedOffer, subscribeOffer, onCancel } = useChannelSubscription();

  const handleValidate = (code: string) => {
    return subscribeOffer({ code });
  };

  return (
    isReady && (
      <PurchaseCodeModal
        title={selectedOffer.name}
        subtitle={`Rentrez votre code d’achat à 4 chiffres pour vous abonner à cette offre : ${selectedOffer.title}`}
        showLostCodeTip={true}
        codeAttemptLeft={purchaseCode.codeAttemptLeft}
        codeLocked={codeLocked}
        onValidate={handleValidate}
        onCancel={onCancel}
      />
    )
  );
}

export default ChannelSubscriptionCheckout;
