import React from 'react';
import { FormattedChannel, useChannelById } from '@oqee/core';
import { useLocation, useParams } from 'react-router-dom';

import LivePlayer from '../../components/player/LivePlayer';
import ParentalGuard from '../../components/shared/ParentalGuard';
import PlayerPageLayout from '../../components/layout/PlayerPageLayout';

type LivePlayerPageParams = {
  channelId: string;
};

type LivePlayerPageLocation = {
  state: {
    startOver: boolean;
  };
};

function LivePlayerPage() {
  const { channelId } = useParams() as LivePlayerPageParams;
  const { state } = useLocation() as LivePlayerPageLocation;

  const liveChannel: FormattedChannel | undefined = useChannelById(Number(channelId));

  return (
    <PlayerPageLayout>
      {liveChannel && (
        <ParentalGuard isAdult={liveChannel.isAdult} requireNonExpiredToken={true}>
          <LivePlayer liveChannel={liveChannel} startOver={state?.startOver} />
        </ParentalGuard>
      )}
    </PlayerPageLayout>
  );
}

export default LivePlayerPage;
