import React from 'react';
import shaka from 'shaka-player/dist/shaka-player.ui';
import { google } from '@alugha/ima';

import { PlaybackInfo, APIPlaybackInfoMedia } from '@oqee/core';
import { ShakaPlayerProps } from './ShakaPlayer';

interface ShakaPlayerIntersticesProps extends ShakaPlayerProps {
  player: shaka.Player;
  ima: typeof google.ima;
}

type Interstice = APIPlaybackInfoMedia | { type: 'ads' };

function ShakaPlayerInterstices({
  player,
  ima,
  videoEl,
  uiContainerEl,
  playbackInfo,
  preScreenImageEl,
  preScreenVideoEl
}: ShakaPlayerIntersticesProps) {
  const adManager: shaka.extern.IAdManager | null | undefined = player?.getAdManager();
  const eventManager = new shaka.util.EventManager();

  function loadInterstices() {
    const intersicesSequence: Interstice[] = [
      ...(playbackInfo.preAds ? playbackInfo.preAds : []),
      ...(playbackHasAds(playbackInfo) ? [{ type: 'ads' }] : []),
      ...(playbackInfo.preMedias ? playbackInfo.preMedias : [])
    ] as Interstice[];

    const launchInterstices = intersicesSequence.reduceRight((acc: (() => void) | null, interstice: Interstice) => {
      return () => {
        if (interstice.type === 'image') {
          // hide and pause content
          uiContainerEl.style.display = 'none';
          videoEl.pause();
          // show image
          preScreenImageEl.style.display = 'block';
          preScreenImageEl.src = interstice.url;
        } else if (interstice.type === 'video') {
          // hide and pause content
          uiContainerEl.style.display = 'none';
          videoEl.pause();
          // show and play video
          preScreenVideoEl.style.display = 'block';
          preScreenVideoEl.src = interstice.url;
          preScreenVideoEl.play();
        } else if (interstice.type === 'ads') {
          uiContainerEl.style.display = 'block';
          launchAds();
        }

        // function executed on current interstice end
        const onEnded = () => {
          if (interstice.type === 'image') {
            preScreenImageEl.style.display = 'none';
          } else if (interstice.type === 'video') {
            preScreenVideoEl.style.display = 'none';
          }

          if (acc !== null) {
            // launch next interstice
            acc();
          } else {
            // End of interstices : show and play content
            uiContainerEl.style.display = 'block';
            videoEl.play();
          }
        };

        if (interstice.type === 'image') {
          setTimeout(onEnded, interstice.image.display_duration_s * 1000);
        } else if (interstice.type === 'video') {
          eventManager.listen(preScreenVideoEl, 'ended', onEnded);
        } else if (interstice.type === 'ads') {
          adManager &&
            eventManager.listen(adManager, shaka.ads.AdManager.AD_COMPLETE, (evt: any) => {
              const adPodInfo = evt?.originalEvent?.ad?.data?.adPodInfo;
              if (adPodInfo && adPodInfo.podIndex === 0 && adPodInfo.adPosition === adPodInfo.totalAds) {
                // call next interstice at the end of first's adPod's last ad
                onEnded();
              }
            });
        }
      };
    }, null);

    launchInterstices && launchInterstices();
  }

  function launchAds() {
    // content has vast/vmap ads : load its payload
    if (!adManager) return;

    adManager.initClientSide(uiContainerEl, videoEl, null);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const adsRequest: google.ima.AdsRequest = new ima.AdsRequest();
    adsRequest.adsResponse = playbackInfo?.adsInfo?.payload;
    adManager.requestClientSideAds(adsRequest);
  }

  React.useEffect(() => {
    eventManager.listenOnce(videoEl, `play`, loadInterstices);

    return () => {
      eventManager.unlisten(videoEl, `play`, loadInterstices);
    };
  }, [playbackInfo]);

  return null;
}

/**
 * Check that we have <Ad /> nodes in payload
 * @param playbackInfo
 */
function playbackHasAds(playbackInfo: PlaybackInfo): boolean {
  if (!playbackInfo?.adsInfo?.payload) return false;

  const parser = new DOMParser();
  const node: Document = parser.parseFromString(playbackInfo.adsInfo.payload, 'application/xml');
  const errorNode: Element | null = node.querySelector('parsererror');

  if (errorNode) return false;

  const nodeList: NodeListOf<Element> = node.querySelectorAll('Ad');
  return nodeList && nodeList.length > 0;
}

export default ShakaPlayerInterstices;
