import React from 'react';
import { PlaybackInfo } from '@oqee/core';

import PlayerErrorNeedSubscription from './PlayerErrorNeedSubscription';
import Modal from '../shared/Modal';
import useWebError from '../../hooks/useWebError';
import { BrowserContext, BrowserContextType } from '../context/BrowserContextProvider';
import { AdBlockPlayerMessage, SimplePlayerMessage } from './PlayerMessage';
import ShakaPlayer from './shaka/ShakaPlayer';
import { NavigateFunction, useLocation, useNavigate, Location } from 'react-router-dom';
import { PendingPlayerExitHandler } from './PlayerMessage/PlayerMessage';

interface PlayerProps {
  playbackInfo: PlaybackInfo | null;
  handleBack?: () => void;
  isLivePreview?: boolean;
  startOver?: boolean;
}

function Player({ playbackInfo, handleBack, isLivePreview = false, startOver = false }: PlayerProps) {
  const browserContext: BrowserContextType = React.useContext(BrowserContext);
  const uiContainerRef = React.useRef<HTMLDivElement>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const streamRef = React.useRef<any>(null);
  const preScreenImageRef = React.useRef<HTMLImageElement>(null);
  const preScreenVideoRef = React.useRef<HTMLVideoElement>(null);

  const error = useWebError();
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const defaultHandleBack = () => (location.key !== 'default' ? navigate(-1) : navigate('/home'));
  handleBack = handleBack ?? defaultHandleBack;

  const [isVideoElementReady, setVideoElementReady] = React.useState(false);

  React.useEffect(() => {
    setVideoElementReady(true);
  }, []);

  React.useEffect(() => {
    // reset errors on manifest change and unmount
    return () => error.reset();
  }, [playbackInfo?.manifestUrl]);

  let varElements;

  if (!browserContext?.ima || error?.shownComponentInPlayer === 'AdBlockPlayerMessage') {
    // show adblock message in player
    varElements = <AdBlockPlayerMessage />;
  } else if (error?.shownComponentInPlayer === 'PlayerErrorNeedSubscription') {
    // show channel subscription modal
    varElements = (
      <PlayerErrorNeedSubscription
        channelNumber={error.data?.channelNumber}
        onSuccess={() => {
          return;
        }} // not used
        onCancel={handleBack}
      />
    );
  } else if (error?.shownComponentInPlayer === 'Modal' && !isLivePreview && error?.title) {
    // show message in simple modal
    varElements = (
      <Modal
        title={error.title}
        description={error.description as string}
        onValidate={handleBack}
        validateButtonLabel="Fermer"
      />
    );
  } else if ((error?.shownComponentInPlayer === 'SimplePlayerMessage' || error?.code) && !error?.isRecoverable) {
    // show message in player (default behaviour for an error with a code, which is not recoverable)
    varElements = <SimplePlayerMessage error={error} isLivePreview={isLivePreview} />;
  } else {
    // show <video> element & shaka player component
    varElements = (
      <>
        <div ref={uiContainerRef}>
          <video ref={videoRef} id="PlayerVideoElement" />
          {isVideoElementReady &&
            videoRef.current &&
            uiContainerRef.current &&
            preScreenImageRef.current &&
            preScreenVideoRef.current &&
            playbackInfo && (
              <ShakaPlayer
                videoEl={videoRef.current}
                uiContainerEl={uiContainerRef.current}
                streamRef={streamRef}
                preScreenImageEl={preScreenImageRef.current}
                preScreenVideoEl={preScreenVideoRef.current}
                playbackInfo={playbackInfo}
                isLivePreview={isLivePreview}
                startOver={startOver}
          />
            )}
        </div>
        <img src="" alt="" ref={preScreenImageRef} style={{ display: 'none' }} />
        <video ref={preScreenVideoRef} src="" style={{ display: 'none' }} />
      </>
    );
  }

  return (
    <>
      {varElements} <PendingPlayerExitHandler />
    </>
  );
}

export default Player;
