import React from 'react';
import { useParams } from 'react-router-dom';
import { useChannelById, useChannelProgramDetails } from '@oqee/core';

import MainLayout from '../../components/layout/MainLayout';
import SinglePage from '../../components/single/SinglePage';

type ChannelDetailPageParams = {
  channelId: string;
};

const ChannelDetailPage: React.FC = () => {
  const { channelId } = useParams<ChannelDetailPageParams>();
  const channel = useChannelById(Number(channelId));

  const { isReady, programEntry } = useChannelProgramDetails(channel.number, null, true);
  const pageName = programEntry.type === 'live' ? 'single_live' : 'single_upcoming';

  return (
    <MainLayout
      fbScreenName={pageName}
      program={programEntry}
      withBackdrop
    >
      <SinglePage isReady={isReady} program={programEntry} />
    </MainLayout>
  );
};

export default ChannelDetailPage;
