import React, { useEffect, useState } from 'react';

import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';

import { usePurchaseCode, useSubscriptionManager } from '@oqee/core';

import { HeaderNav } from '../../../../utils/webUtils';

import MainLayout from '../../../../components/layout/MainLayout';
import PurchaseCodeModal from '../../../../components/shared/PurchaseCodeModal';
import Button from '../../../../components/shared/Button';
import Spinner from '../../../../components/shared/Spinner';

import OfferDetailsSection from '../OfferDetailsSection';


function SubscriptionsUnsubscribe() {
  const { state: locationState } = useLocation();
  const { offerType, offerId } = useParams();
  const navigate: NavigateFunction = useNavigate();
  const { purchaseCode, codeLocked, resetCodeAttempt } = usePurchaseCode();
  const subscriptionManager = useSubscriptionManager(offerType, Number(offerId));
  const { currentOffer, currentOfferedUnSubscribed, unsubscribeOffer, reset } = subscriptionManager;
  const [isUnsubscribing, setIsUnsubscribing] = useState(false);

  useEffect(() => {
    return resetCodeAttempt;
  }, []);

  useEffect(() => {
    if (currentOfferedUnSubscribed) {
      setIsUnsubscribing(false);
    }
  }, [currentOfferedUnSubscribed]);

  useEffect(() => {
    // Fetched and not exist, go back to list
    if (currentOffer === undefined) {
      return navigate('/settings/subscriptions');
    }
  }, [currentOffer]);

  if (!currentOffer || isUnsubscribing) {
    return <Spinner />;
  }

  const handleValidate = (purchaseCode: string) => {
    const offer = {
      id: Number(offerId),
      type: offerType
    };
    const payload = {
      offer,
      immediate: locationState.terminationMode === 'now',
      purchaseCode
    };

    setIsUnsubscribing(true);

    return unsubscribeOffer(payload)
      .then(response => {
        setIsUnsubscribing(false);
        return response;
      })
      .catch(() => setIsUnsubscribing(false));
  };

  const onCancel = () => navigate(-1);

  if (!currentOfferedUnSubscribed) {
    return (
      <MainLayout activeHeaderNav={HeaderNav.SETTINGS}>
        <PurchaseCodeModal
          title={`Résilier`}
          subtitle="Saisissez votre code d'achat à 4 chiffres pour vous désabonner de ce contenu :"
          showLostCodeTip
          codeLocked={codeLocked}
          onValidate={handleValidate}
          onSuccess={reset}
          codeAttemptLeft={purchaseCode.codeAttemptLeft}
          onCancel={onCancel}
        />
      </MainLayout>
    );
  }
  const { title } = currentOffer;

  const ButtonsSlot = () => {
    return (
      <>
        <Button
          label="Se réabonner"
          onClick={() => navigate(`/settings/subscriptions/offer/${offerType}/${offerId}/subscribe`)}
          size="large"
          type="primary"
        />
        <Button label="Terminer" onClick={() => navigate('/settings/subscriptions')} size="large" />
      </>
    );
  };

  return <OfferDetailsSection showBackButton={false} title={`${title} résilié`} ButtonsSlot={ButtonsSlot} />;
}

export default SubscriptionsUnsubscribe;
