import React from 'react';
import { APIReplayProgramDetail, PlaybackInfo } from '@oqee/core';

import useWebReplayPlaybackInfo from '../../hooks/useWebReplayPlaybackInfo';
import Player from './Player';

interface ReplayPlayerProps {
  programEntry: APIReplayProgramDetail;
}

function ReplayPlayer({ programEntry }: ReplayPlayerProps) {
  const playbackInfo: PlaybackInfo | null = useWebReplayPlaybackInfo(programEntry);

  return <Player playbackInfo={playbackInfo} />;
}

export default ReplayPlayer;
