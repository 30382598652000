import React from 'react';
import { useParams } from 'react-router-dom';
import { usePageSingle } from '@oqee/core';

import MainLayout from '../../components/layout/MainLayout';
import SinglePage from '../../components/single/SinglePage';

type RecordDetailPageParams = {
  recordId: string;
};

const RecordDetailPage: React.FC = () => {
  const { recordId } = useParams<RecordDetailPageParams>();
  const { isReady, programEntry } = usePageSingle('record', recordId);

  return (
    <MainLayout fbScreenName="single_record" program={programEntry} withBackdrop>
      <SinglePage isReady={isReady} program={programEntry} />
    </MainLayout>
  );
};

export default RecordDetailPage;
