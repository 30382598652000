import React from 'react';
import { useParams, useNavigate, NavigateFunction } from 'react-router-dom';

import { useAccountProfile } from '@oqee/core';

import Typography from '../../../../components/shared/Typography';
import Button from '../../../../components/shared/Button';
import SettingsNavHeader from '../../SettingsNavHeader';

import UserProfileLayout from '../../../../components/layout/UserProfileLayout';
import ProfileNameInput from '../ProfileNameInput';
import ProfileNamePicture from './ProfileNamePicture';

import './ProfileName.css';

type ProfileNameParams = {
  profileId?: string;
  ageRange?: string;
  avatarShapeKey?: string;
  avatarTone?: string;
  color?: string;
};

function ProfileName() {
  const navigate: NavigateFunction = useNavigate();
  const { profileId, ageRange, avatarTone, avatarShapeKey, color } = useParams() as ProfileNameParams;

  const { validateUsername, getProfile, avatars } = useAccountProfile();

  const currentProfile = profileId
    ? getProfile(profileId)
    : {
        username: '',
        age_range: ageRange,
        avatar_color: `#${color}`,
        avatar_tone: `#${avatarTone}`,
        avatar_shape: avatarShapeKey
      };
  const ageRangeAvatars = avatars?.age_ranges?.find(age => age.key === currentProfile.age_range);
  const avatarShape = ageRangeAvatars?.avatar_shapes?.find(shape => shape.key === currentProfile.avatar_shape);

  const [username, setUsername] = React.useState<string>(currentProfile.username);

  const isUsernameValid: boolean = validateUsername(username) && username !== currentProfile.username;

  // profile with modified username
  const profile = React.useMemo(
    () => ({
      ...currentProfile,
      username
    }),
    [currentProfile, username]
  );

  const handleSubmit = () => navigate('commit', { state: { profile } });
  const handleBack = () => navigate('..');

  return (
    <UserProfileLayout>
      <div className="ProfileName">
        <SettingsNavHeader onBack={handleBack} />

        <div className="ProfileNameContent">
          <div className="ProfileNameTitle">
            <Typography variant="h1">Votre nom</Typography>
          </div>

          <div className="ProfileNameField">
            <ProfileNamePicture
              picture={avatarShape.picture}
              colorTag={currentProfile.avatar_color}
              toneTag={currentProfile.avatar_tone}
            />

            <ProfileNameInput username={username} handleChangeUsername={setUsername} />
          </div>

          <div className="ProfileNameSubmitButton">
            <Button label="Valider" type="primary" onClick={handleSubmit} disabled={!isUsernameValid} />
          </div>
        </div>
      </div>
    </UserProfileLayout>
  );
}

export default ProfileName;
