import React from 'react';
import { useWebStoreActions, webStore } from '../../store/webStoreUtils';
import { useModal } from '../shared/Modal';
import { Typography } from '@oqee/ui';
import { ErrorModelState } from '@oqee/core';

type NpvrDeniedWarningProps = {
  error: ErrorModelState;
};

function NpvrDeniedWarning({ error }: NpvrDeniedWarningProps) {
  const resetError = useWebStoreActions(actions => actions.error.reset);

  const { channel: channelState } = webStore.getState();
  const { data } = error;
  const { payload = {} } = data || {};
  const { channelId, channelNumber } = payload || data;
  const channel: any =
    channelState.list.find(c =>
      channelNumber !== undefined ? c.number.toString() === channelNumber.toString() : c.id === channelId
    ) ?? {};
  const title = `Enregistrement «${channel?.name}» impossible`;

  const { Modal } = useModal(
    {
      title: title,
      description: (
        <Typography variant="body2">
          Cette chaine n’autorise pas l’enregistrement de ses programmes.
          <br />
          Veuillez vous rapprocher du service client de cette chaine.
        </Typography>
      ),
      validateButtonLabel: `OK`,
      onValidate() {
        resetError();
      }
    },
    false,
    true
  );

  return <Modal />;
}

export default NpvrDeniedWarning;
