import React from 'react';
import { usePurchaseCode } from '@oqee/core';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { HeaderNav } from '../../../utils/webUtils';

import MainLayout from '../../../components/layout/MainLayout';
import Spinner from '../../../components/shared/Spinner/Spinner';
import PurchaseCodeEditor from './PurchaseCodeEditor';

function SettingsPurchaseCode() {
  const navigate: NavigateFunction = useNavigate();
  const { isReady, isCodeCreation, codeLocked, purchaseCode } = usePurchaseCode();

  const handleBack = () => navigate('/settings');

  return (
    <MainLayout activeHeaderNav={HeaderNav.SETTINGS}>
      {isReady ? (
        <PurchaseCodeEditor
          codeCreation={isCodeCreation}
          purchaseCode={purchaseCode}
          codeLocked={codeLocked}
          onSave={handleBack}
          onCancel={handleBack}
        />
      ) : (
        <Spinner testId="settings-purchase-code-pending" />
      )}
    </MainLayout>
  );
}

export default SettingsPurchaseCode;
