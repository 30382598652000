import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { api, useSearch } from '@oqee/core';

import { HeaderNav } from '../../utils/webUtils';
import MainLayout from '../../components/layout/MainLayout';
import Typography from '../../components/shared/Typography';
import ContentGrid from '../../components/content/ContentGrid';
import SearchBar from '../../components/shared/SearchBar';

import './SearchPage.css';

import EmptyResult from '../../components/shared/EmptyResult';

function SearchEmpty() {
  return (
    <div className="SearchEmpty">
      <EmptyResult title="Aucun résultat pour cette recherche" />
    </div>
  );
}

function SearchPage() {
  const initialSearchHistory = api.cache.getFromCache(api.constant.LOCAL_STORAGE_KEY.SEARCH).data || [];
  const [searchHistory, setSearchHistory] = React.useState(initialSearchHistory);
  const [searchParams] = useSearchParams();

  const searchTerm = searchParams.get('term') || '';

  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);

  React.useEffect(() => {
    api.cache.commit(api.constant.LOCAL_STORAGE_KEY.SEARCH, searchHistory);
  }, [searchHistory]);

  const { query, data, resetSearch } = useSearch(null, null, { filterResults: false });

  const PageTitle = searchTerm ? 'Résultat(s) de la recherche' : 'Nos suggestions';

  const entries = React.useMemo(
    () =>
      data.map(entry => {
        // TODO FIXME: temporary object signature as soon as we refactor search completly in TV
        const { content, contentType } = entry;
        const isMultiProgram = entry.isMultiProgram || content.program_count > 1;
        const type = isMultiProgram ? 'content' : content.display_as || contentType;
        const { diffusions } = content;

        const firstDiffusion = diffusions?.length && content.display_as === 'live' ? diffusions[0] : {};
        const programEntry = {
          ...entry,
          content: entry.content,
          type,
          [type]: {
            contentType,
            ...entry.content,
            id:
              content.display_as === 'replay'
                ? content.replay_program_ids[0]
                : content.display_as === 'diffusion'
                ? diffusions[0].id
                : entry.content.id,
            ...firstDiffusion
          },
          app_badges: content.display_as === 'vod'
        };
        return programEntry;
      }),
    [data]
  );

  const handleThumbnailClick = React.useCallback(() => {
    let oldSearchHistory = searchHistory.length === 5 ? searchHistory.slice(0, -1) : searchHistory;
    if (searchHistory.includes(searchTerm)) {
      oldSearchHistory = [...searchHistory.filter(historyValue => historyValue !== searchTerm)];
    }
    if (searchTerm) {
      const newSearchHistory = [searchTerm, ...oldSearchHistory];
      api.cache.commit(api.constant.LOCAL_STORAGE_KEY.SEARCH, newSearchHistory);
    }
  }, [searchTerm, searchHistory]);

  React.useEffect(() => {
    resetSearch();
    query(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <>
      <MainLayout activeHeaderNav={HeaderNav.SEARCH}>
        <SearchBar searchHistory={searchHistory} setSearchHistory={setSearchHistory} searchTerm={searchTerm} />

        <div className="SearchPage">
          {!data.length ? (
            <SearchEmpty />
          ) : (
            <>
              <Typography variant="h3" className="SearchPageTitle">
                {PageTitle}
              </Typography>
              <ContentGrid
                entries={entries}
                showTileTitle
                displayVodAsLandscape
                onThumbnailClick={handleThumbnailClick}
              />
            </>
          )}
        </div>
      </MainLayout>
    </>
  );
}

export default SearchPage;
