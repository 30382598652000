import React from 'react';

import ChannelCTA from './ChannelCTA';
import ReplayCTA from './ReplayCTA';
import VodCTA from './VodCTA';

import './ProgramCTA.css';
import InformationCTA from './InformationCTA';
import RecordCTA from './RecordCTA';

const components = {
  live: ChannelCTA,
  record: RecordCTA,
  replay: ReplayCTA,
  vod: VodCTA
};

type ProgramCTAProps = {
  program: any;
  rounded?: boolean;
  displayInformation?: boolean;
  mainProgram?: any;
  isResumable?: boolean;
  onResumableDelete?: () => void;
};

function ProgramCTA({
  program,
  mainProgram,
  rounded,
  displayInformation = false,
  isResumable,
  onResumableDelete
}: ProgramCTAProps) {
  const CTA = components[program.contentType];

  return (
    <div className="ProgramCTA">
      <CTA program={program} rounded={rounded} isResumable={isResumable} onResumableDelete={onResumableDelete} />
      {displayInformation && <InformationCTA program={program} mainProgram={mainProgram} rounded={rounded} />}
    </div>
  );
}

export default ProgramCTA;
