import React, { useMemo } from 'react';
import { useRecordRemove, useToaster } from '@oqee/core';
import { Button, ButtonProps, CheckmarkColorIcon, BinIcon, Typography } from '@oqee/ui';
import { useModal } from '../../shared/Modal';

interface CancelRecordButtonProps extends ButtonProps {
  program: any;
  hideLabel?: boolean;
  onRemoveRecord?: () => void;
}

function DeleteRecordButton({
  program,
  hideLabel = false,
  onRemoveRecord,
  rounded,
  color,
  ...buttonProps
}: CancelRecordButtonProps) {
  const { addMessage } = useToaster();
  const { removeRecord, isRemoved } = useRecordRemove({
    onRemoved: () => {
      addMessage('Enregistrement supprimé!');
      return null;
    }
  });
  const { id, status } = program.content;
  const isAvailable = status === 'available';
  const hideText = React.useMemo(() => {
    return rounded || color === 'secondary' || hideLabel;
  }, [rounded, color, hideLabel]);

  React.useEffect(() => {
    if (isRemoved) {
      onRemoveRecord && onRemoveRecord();
    }
  }, [isRemoved]);

  const remove = React.useCallback(() => {
    removeRecord(id);
    return false;
  }, [removeRecord, id]);

  const label = useMemo(
    () => (isAvailable ? "Suppression de l'enregistrement" : "Annuler l'enregistrement"),
    [isAvailable]
  );

  const icon = useMemo(() => (isAvailable ? BinIcon : CheckmarkColorIcon), [isAvailable]);
  const iconColor = useMemo(() => (isAvailable ? undefined : 'info'), [isAvailable]);

  const { Modal, openModal, closeModal } = useModal({
    title: label,
    description: isAvailable
      ? 'Êtes-vous sûr de vouloir supprimer cet enregistrement ?<br /> Cette action est irréversible.'
      : `Êtes-vous sûr de vouloir annuler cet enregistrement ?`,
    onCancel: () => {
      closeModal();
    },
    cancelButtonLabel: 'Fermer',
    validateButtonLabel: isAvailable ? 'Confirmer la suppression' : `Confirmer l'annulation`,
    onValidate: remove,
    hasPadding: false
  });

  return (
    <>
      <Button
        {...buttonProps}
        icon={icon}
        iconColor={iconColor}
        color={color}
        onClick={openModal}
        rounded={rounded}
        tooltip={hideText ? label : undefined}
      >
        {!hideText && <Typography variant="body1">{label}</Typography>}
      </Button>
      <Modal />
    </>
  );
}

export default DeleteRecordButton;
