import React from 'react';
import clsx from 'clsx';

import './ContentList.css';
import ContentListItem from '../ContentListItem';

interface ContentListProps {
  entries: any[];
  className?: string;
  program?: any;
  showCircularProgress?: boolean;
}

function ContentList({ program, entries, className, showCircularProgress = false}: ContentListProps) {
  return (
    <div className={clsx('ContentList', className)}>
      {entries?.map((entry, entryIndex) => (
        <ContentListItem entry={entry} key={entryIndex} program={program} showCircularProgress={showCircularProgress} />
      ))}
    </div>
  );
}

export default ContentList;
