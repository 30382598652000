import React, { PropsWithChildren } from 'react';
import UAParser from 'ua-parser-js';
import Typography from '../shared/Typography';

import './MobileLandingPage.css';

function MobileLandingPage({ children }: PropsWithChildren) {
  const isAppQrCodeUrl: boolean = window.location.pathname === '/app/';
  const uaParser = new UAParser(navigator.userAgent);
  const osName: string | undefined = uaParser.getOS().name;
  const isiOS: boolean = osName === 'iOS';
  const isAndroid: boolean = osName === 'Android';
  const showMobileLandingPage: boolean = isiOS || isAndroid || isAppQrCodeUrl;
  const landscape = window.matchMedia('(orientation: landscape)').matches;

  if (!showMobileLandingPage || landscape) {
    return <>{children}</>;
  }
  return (
    <div className="MobileLandingPage">
      <div className="circle-left">
        <img alt="" src="/img/mobile/circle-left.svg" />
      </div>
      <div className="force-download-container">
        <div className="force-download">
          <a href="/landing/index.html">
            <img className="oqee-by-free" alt="" src="/img/mobile/oqee-by-free.svg" />
          </a>
          <div className="pour-une-meilleure-exprience-parent">
            {isiOS ? (
              <>
                <div className="pour-une-meilleure">
                  <Typography variant="body2">
                    Pour une meilleure expérience,
                    <br />
                    OQEE by Free est disponible en téléchargement
                    <br />
                    sur l’App Store.
                  </Typography>
                </div>
                <a href="https://apps.apple.com/fr/app/oqee-by-free/id1542614107">
                  <img className="download-on-the-app-store-badg-icon" alt="" src="/img/mobile/appstore.svg" />
                </a>
              </>
            ) : isAndroid ? (
              <>
                <div className="pour-une-meilleure">
                  <Typography variant="body2">
                    Pour une meilleure expérience,
                    <br />
                    OQEE by Free est disponible en téléchargement
                    <br />
                    sur le Play Store.
                  </Typography>
                </div>
                <div className="group-parent">
                  <a href="https://play.google.com/store/apps/details?id=net.oqee.androidmobile&hl=fr">
                    <img className="frame-child" alt="" src="/img/mobile/playstore.svg" />
                  </a>
                  <div className="google-play-et">
                    <Typography variant="body4">
                      Google Play et le logo Google Play sont des marques de Google LLC.
                    </Typography>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="pour-une-meilleure">
                  <Typography variant="body2">T&eacute;l&eacute;chargez gratuitement OQEE</Typography>
                </div>
                <a href="https://apps.apple.com/fr/app/oqee-by-free/id1542614107">
                  <img className="download-on-the-app-store-badg-icon" alt="" src="/img/mobile/appstore.svg" />
                </a>
                <div className="group-parent">
                  <a href="https://play.google.com/store/apps/details?id=net.oqee.androidmobile&hl=fr">
                    <img className="frame-child" alt="" src="/img/mobile/playstore.svg" />
                  </a>
                  <div className="google-play-et">
                    <Typography variant="body4">
                      Google Play et le logo Google Play sont des marques de Google LLC.
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="circle-right">
        <img alt="" src="/img/mobile/circle-right.svg" />
      </div>
    </div>
  );
}

export default MobileLandingPage;
