import React from 'react';
import { NavigateFunction, useLocation, useNavigate, Location } from 'react-router-dom';
import Typography from '../../shared/Typography';
import { ActionCreator } from 'easy-peasy';

import Button from '../../shared/Button';
import { useWebStoreActions, useWebStoreState } from '../../../store/webStoreUtils';
import Modal from '../../shared/Modal';

import './PlayerMessage.css';

interface SimplePlayerMessageProps {
  error: any;
  isLivePreview: boolean;
}

function SimplePlayerMessage({ error, isLivePreview }: SimplePlayerMessageProps) {
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const handleBack = () => (location.key !== 'default' ? navigate(-1) : navigate('/home'));
  const Description = typeof error.description === 'string' ? () => error.description : error.description;

  return (
    <div className="PlayerMessageContainer">
      {!isLivePreview && <img src="/img/player/back-arrow.svg" className="back-button" onClick={handleBack} />}

      <div className="PlayerMessage">
        <Typography variant="h3">{error?.title ?? 'Une erreur est survenue 😰'}</Typography>
        <Typography variant="h4">
          <Description error={error} />
        </Typography>
        <div className="PlayerMessageButtons">
          <div className="PlayerMessageCloseButton">
            <Button label="Fermer" size="medium" type="secondary" onClick={handleBack} />
          </div>
        </div>
      </div>

      <Typography variant="body3" className="PlayerMessageCode">
        {error?.code && `[${error.code}]`}
      </Typography>
    </div>
  );
}

function AdBlockPlayerMessage() {
  return (
    <div className="PlayerMessageContainer">
      <div className="PlayerMessage">
        <Typography variant="h3">Alerte</Typography>
        <Typography variant="body1" bold>
          Adblock est intégré à votre navigateur, un anti-malware (souvent inclus dans une protection anti-virus)
          <br />
          ou bien vous avez une configuration réseau spécifique.
          <br />
          Pour continuer l’expérience OQEE by free, vérifiez vos paramètres.
        </Typography>
        <div className="PlayerMessageButtons">
          <Button label="Rafraîchir la page" size="large" type="primary" onClick={() => window.location.reload()} />
          <Button
            label="Comment autoriser l’application ?"
            size="large"
            type="secondary"
            newWindowIcon
            onClick={() =>
              window.open(
                'https://assistance.oqee.tv/hc/fr/articles/18655239290769-Message-d-alerte-AdBlock-anti-malware-et-configuration-r%C3%A9seau-'
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

interface LockedChannelPlayerMessageProps {
  openChannelSubscription: () => void;
}

function LockedChannelPlayerMessage({ openChannelSubscription }: LockedChannelPlayerMessageProps) {
  return (
    <div className="PlayerMessageContainer">
      <div className="PlayerMessage">
        <Typography variant="h3">Programme réservé aux abonnés</Typography>
        <Typography variant="body1" bold>
          Cette chaîne nécessite un abonnement payant.
        </Typography>
        <div className="PlayerMessageButtons">
          <Button label="Voir les offres" size="large" type="primary" onClick={openChannelSubscription} />
        </div>
      </div>
    </div>
  );
}

function PendingPlayerExitHandler() {
  const isTimeshifting: boolean = useWebStoreState(state => state.webPlayer.isTimeshifting);
  const pendingPlayerExitRequest: (() => void) | null = useWebStoreState(
    state => state.webPlayer.pendingPlayerExitRequest
  );
  const resetPendingPlayerExitRequest: ActionCreator<void> = useWebStoreActions(
    state => state.webPlayer.resetPendingPlayerExitRequest
  );

  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    if (pendingPlayerExitRequest) {
      if (isTimeshifting) {
        setShowModal(true);
      } else {
        pendingPlayerExitRequest();
        resetPendingPlayerExitRequest();
      }
    } else {
      setShowModal(false);
    }
  }, [pendingPlayerExitRequest, isTimeshifting]);

  if (showModal) {
    const handleCancel = () => resetPendingPlayerExitRequest();

    const handleValidate = () => {
      pendingPlayerExitRequest && pendingPlayerExitRequest();
      resetPendingPlayerExitRequest();
    };

    return (
      <Modal
        title="Quitter la lecture en cours"
        description="Vous allez quitter votre lecture en différé sur cette chaîne, voulez&#8209;vous continuer ?"
        onCancel={handleCancel}
        onValidate={handleValidate}
        cancelButtonLabel="Non"
        validateButtonLabel="Oui"
      />
    );
  } else {
    return null;
  }
}

export { SimplePlayerMessage, AdBlockPlayerMessage, LockedChannelPlayerMessage, PendingPlayerExitHandler };
