import React from 'react';
import { Typography } from '@oqee/ui';

import './Toggle.css';

type ToggleProps = {
  label: string;
  value: any;
  onToggle: () => void;
};

function Toggle({ label, value, onToggle }: ToggleProps) {
  return (
    <div className="Toggle">
      <Typography variant="body1" bold className="ToggleLabel">
        {label}
      </Typography>
      <img className="ToggleIcon" alt="" src={`/img/switch_${value ? 'on' : 'off'}.svg`} onClick={onToggle} />
    </div>
  );
}

export default Toggle;
