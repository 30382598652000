import React from 'react';
import { useParams, NavigateFunction, useNavigate, Navigate } from 'react-router-dom';
import { useAccountProfile } from '@oqee/core';

import UserProfileLayout from '../../../../components/layout/UserProfileLayout';
import Modal from '../../../../components/shared/Modal';

import './ProfileRemove.css';
import Spinner from '../../../../components/shared/Spinner/Spinner';

type ProfileRemoveParams = {
  profileId: string;
};

function ProfileRemove() {
  const { profileId } = useParams() as ProfileRemoveParams;
  const navigate: NavigateFunction = useNavigate();

  const { profiles, activeProfile, removeProfile } = useAccountProfile();

  const [isDeleting, setDeleting] = React.useState(false);

  const profile = React.useMemo(() => {
    return profiles.find(profile => profile.id === profileId);
  }, [profiles, profileId]);
  const isActive = activeProfile && profile && profile.id === activeProfile.id;

  const handleCancel = () => navigate(-1);

  const handleValidate = () => {
    setDeleting(true);
    removeProfile(profileId);
  };

  if (!profile) {
    return <Navigate to=".." />;
  }

  if (isDeleting) {
    return (
      <UserProfileLayout>
        <Spinner testId="profile-removal-pending" />
      </UserProfileLayout>
    );
  }

  const modalProps = isActive
    ? {
        title: 'Supprimer le profil',
        description:
          'Vous ne pouvez supprimer le profil sur lequel vous êtes connecté, changez de profil avant de pouvoir supprimer ce profil.',
        onCancel: handleCancel
      }
    : {
        title: 'Supprimer le profil',
        description:
          'Souhaitez-vous vraiment supprimer ce profil ainsi que toutes les informations et préférences qu’il contient ? Vos enregistrements seront rattachés au profil courant.',
        onCancel: handleCancel,
        onValidate: handleValidate,
        validateButtonLabel: 'Supprimer'
      };

  return (
    <UserProfileLayout>
      <Modal {...modalProps} />
    </UserProfileLayout>
  );
}

export default ProfileRemove;
