import React from 'react';

import PageLayout from '../PageLayout';

import './PlayerPageLayout.css';

interface PlayerPageLayoutProps extends React.PropsWithChildren {}

function PlayerPageLayout({ children }: PlayerPageLayoutProps) {
  return (
    <PageLayout>
      <div className="PlayerPageLayout">{children}</div>
    </PageLayout>
  );
}

export default PlayerPageLayout;
