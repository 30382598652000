import React from 'react';

import ListItem from './ListItem';
import { ListItemData } from './ListItem';

import './List.css';

interface ListProps {
  items: ListItemData[];
}

function List({ items = [] }: ListProps) {
  return (
    <div className="List">
      {items.map((item: ListItemData) => (
        <ListItem key={item.id} item={item} />
      ))}
    </div>
  );
}

export default List;
