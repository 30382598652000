import React from 'react';
import Typography from '../../shared/Typography';

import './Footer.css';

function Footer() {
  return (
    <footer className="Footer">
      <div className="FooterInner">
        <div className="FooterLinks">
          <a href="https://assistance.oqee.tv/" target="_blank" rel="noreferrer">
            <Typography variant="body2">Assistance</Typography>
          </a>
          <a href="/privacy/index.html" target="_blank" rel="noreferrer">
            <Typography variant="body2">Politique de confidentialité</Typography>
          </a>
          <a href="https://www.free.fr/freebox/cookies/" target="_blank" rel="noreferrer">
            <Typography variant="body2">Politique de cookies</Typography>
          </a>
          <a href="/legal/index.html" target="_blank" rel="noreferrer">
            <Typography variant="body2">Mentions légales</Typography>
          </a>
        </div>
        <div className="FooterRight">
          <div className="FooterRightContent">
            <div className="FooterSocial">
              <a href="https://twitter.com/OQEEbyFree" target="_blank" rel="noreferrer">
                <img className="FlexIcon" alt="" src="/img/footer-x.svg" />
              </a>
              <a href="https://www.instagram.com/oqee_byfree/" target="_blank" rel="noreferrer">
                <img className="FlexIcon" alt="" src="/img/footer-instagram.svg" />
              </a>
              <a href="https://www.tiktok.com/@oqeebyfree" target="_blank" rel="noreferrer">
                <img className="FlexIcon" alt="" src="/img/footer-tiktok.svg" />
              </a>
              <a
                href="https://www.facebook.com/OQEEbyFree"
                title="Instagram OQEEbyFree"
                target="_blank"
                rel="noreferrer"
                aria-label="facebook"
              >
                <img className="FlexIcon" alt="" src="/img/footer-facebook.svg" width={24} height={24} />
              </a>
            </div>
            <Typography variant="body3">Tous droits réservés TRAX - OQEE by Free {new Date().getFullYear()}</Typography>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
