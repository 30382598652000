import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProfileManage from './ProfileManage';
import ProfileCreation from './ProfileCreation';
import ProfileEdition from './ProfileEdition';
import ProfileRemove from './ProfileRemove';
import ProfileDefault from './ProfileDefault';

import './UserProfile.css';

function UserProfile() {
  return (
    <Routes>
      <Route path="create/*" element={<ProfileCreation />} />

      <Route path="edit/:profileId/*" element={<ProfileEdition />} />
      <Route path="remove/:profileId" element={<ProfileRemove />} />
      <Route path="default" element={<ProfileDefault />} />
      <Route index element={<ProfileManage />} />
    </Routes>
  );
}

export default UserProfile;
