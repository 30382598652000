import React from 'react';
import { api, getPromoToken, StreamingType, FormattedChannel, ChannelPlaybackInfo } from '@oqee/core';
import { BrowserContext, BrowserContextType } from '../components/context/BrowserContextProvider';
import useWebError from './useWebError';
import { useWebStoreState } from '../store/webStoreUtils';

const { DRM_SYSTEM } = api.constant;
const LIVE_DRM_SERVERS = {
  [DRM_SYSTEM.widevine]: import.meta.env.VITE_LIVE_WIDEVINE_URL,
  [DRM_SYSTEM.fairplay]: import.meta.env.VITE_LIVE_FAIRPLAY_URL
};
function useWebChannelPlaybackInfo(liveChannel: FormattedChannel): ChannelPlaybackInfo {
  const browserContext: BrowserContextType = React.useContext(BrowserContext);
  const { trigger: triggerError, reset: resetError } = useWebError();
  const timestamp: number = useWebStoreState(state => state.channel.timestamp);
  const promoToken = getPromoToken(liveChannel, timestamp);
  const { number: channelNumber } = liveChannel;
  const isLocked = liveChannel.isLocked && !promoToken;

  React.useEffect(() => {
    if (isLocked) {
      triggerError({
        code: 'need_subscription',
        data: { channelNumber }
      });
      return () => {
        resetError();
      };
    }
  }, [channelNumber, triggerError, isLocked, resetError]);

  const [manifestUrlPattern, serviceId, drmSystem, drmServer]: [string, number, string, string] =
    browserContext?.selectedStreamingType === StreamingType.HLS
      ? [
          import.meta.env.VITE_LIVE_HLS_URL,
          liveChannel.streams.hls,
          DRM_SYSTEM.fairplay,
          LIVE_DRM_SERVERS[DRM_SYSTEM.fairplay]
        ]
      : [
          import.meta.env.VITE_LIVE_DASH_URL,
          liveChannel.streams.dash,
          DRM_SYSTEM.widevine,
          LIVE_DRM_SERVERS[DRM_SYSTEM.widevine]
        ];

  const manifestUrl: string = manifestUrlPattern.replace('<service_id>', `${serviceId}`).replace('<format_id>', '1');

  const playbackInfo: ChannelPlaybackInfo = {
    broadcastType: 'live',
    manifestUrl,
    drmSystem,
    drmServer,
    promoToken,
    liveChannel
  };

  return playbackInfo;
}

export default useWebChannelPlaybackInfo;
