import React from 'react';
import shaka from 'shaka-player/dist/shaka-player.ui';
import Loading from '../../shared/Loading';

interface ShakaPlayerUiProps {
  player: shaka.Player;
}

function ShakaPlayerLoading(props: ShakaPlayerUiProps) {
  const { player } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [showElement, setShowElement] = React.useState(false);

  React.useEffect(() => {
    const handleLoading = setTimeout(() => {
      setShowElement(isLoading);
    }, 500);
    return () => clearTimeout(handleLoading);
  }, [isLoading]);

  React.useEffect(() => {
    function handleLoading(event) {
      setIsLoading(event.type === 'loading');
    }
    function handleBuffering(event) {
      setIsLoading(event.buffering);
    }
    player.addEventListener('buffering', handleBuffering);
    player.addEventListener('loading', handleLoading);
    return () => {
      player.removeEventListener('loading', handleLoading);
      player.removeEventListener('buffering', handleBuffering);
    };
  }, [player]);

  return showElement ? <Loading /> : null;
}

export default ShakaPlayerLoading;
