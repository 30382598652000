import shaka from 'shaka-player/dist/shaka-player.ui';

import './ShakaPlayerUiVerticalVolume.css';
import React from 'react';

const VerticalVolume = class extends shaka.ui.Element {
  constructor(parent, controls) {
    super(parent, controls);
    const this_ = this as any;

    // The actual button that will be displayed
    this_.container_ = document.createElement('div');
    this_.container_.classList.add('oqee-vertical-volume-container');
    new shaka.ui.MuteButton(this_.container_, controls);
    new shaka.ui.VolumeBar(this_.container_, controls);
    this_.parent.appendChild(this_.container_);

    // Listen for clicks on the container
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this_.eventManager.listen(this_.container_, 'click', () => {});
  }
} as any;

// Factory that will create a button at run time.
VerticalVolume.Factory = class {
  create(rootElement, controls) {
    return new VerticalVolume(rootElement, controls);
  }
};

function ShakaPlayerUiVerticalVolume() {
  React.useEffect(() => {
    shaka.ui.Controls.registerElement(
      /* This name will serve as a reference to the button in the UI configuration object */
      'oqee_vertical_volume',
      new VerticalVolume.Factory()
    );
  }, []);

  return null;
}

export default ShakaPlayerUiVerticalVolume;
