import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProfileCreation from './ProfileCreation';
import PageLayout from '../../../components/layout/PageLayout';

function UserOnboarding() {
  return (
    <PageLayout>
      <Routes>
        <Route path="create/*" element={<ProfileCreation />} />
      </Routes>
    </PageLayout>
  );
}

export default UserOnboarding;
