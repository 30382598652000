import React from 'react';

import Spinner from '../../components/shared/Spinner';
import LandingPage from './LandingPage';
import { useWebStoreActions, useWebStoreState } from '../../store/webStoreUtils';
import { ActionCreator } from 'easy-peasy';
import { api, apiConstant } from '@oqee/core';

function LogoutPage() {
  const isAuthenticated: boolean = useWebStoreState(actions => actions.auth.oqee.payload !== null);
  const resetLoginInfo: ActionCreator<void> = useWebStoreActions(actions => actions.auth.reset);

  React.useEffect(() => {
    // Remove tokens from localstorage
    api.cache.clearAllExcept([
        apiConstant.LOCAL_STORAGE_KEY.WHATSNEW
    ]);
    resetLoginInfo();
  }, []);

  if (isAuthenticated) {
    return <Spinner testId="logout-pending" />;
  } else {
    return <LandingPage />;
  }
}

export default LogoutPage;
