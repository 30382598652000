import useNavigationState from './useNavigationState';

const SCROLL_MARGIN_TOP = -32;

function useScrollRestoration() {
  const { navigationState } = useNavigationState();

  const restoreWindowScrollPosition = (reset: boolean) => {
    const MainDivElement = document.getElementById('MainDiv');
    MainDivElement && MainDivElement.scrollTo(0, reset ? 0 : navigationState.scrollY);
  };

  const scrollToRowDiv = swiperRef => swiperRef.wrapperEl.parentNode.parentNode.parentNode.scrollIntoView(true);

  // Target RowDiv from swiper [Y]
  const restoreRowScrollPosition = (swiperRef, rowId, rowEntries) => {
    if (swiperRef && rowId === navigationState.rowId) {
      // Scroll to RowSlide evoiding an issue in swiper-react position
      scrollToRowDiv(swiperRef);
      scrollToSwiperSlideIndex(swiperRef, rowEntries);

      const MainDivElement = document.getElementById('MainDiv');

      // Restore a little par of viewport
      if (MainDivElement && MainDivElement.scrollTop <= 150) {
        MainDivElement.scrollTo(0, 0);
      } else {
        MainDivElement && MainDivElement.scrollBy(0, SCROLL_MARGIN_TOP);
      }
    }
  };

  const scrollToSwiperSlideIndex = (swiperRef, rowEntries) => {
    const tileIndex = rowEntries.findIndex(entry => {
      const entryChild = entry?.[entry?.type];
      const contentId = entryChild?.content_id ?? entryChild?.id;
      return contentId === navigationState.entryId;
    });

    // If tile continue to exist
    if (tileIndex >= 0) {
      // InitialSlide <Swipper /> property cannot be called at initialized because rowRef is initalized after
      // Force updating swipper index manually
      swiperRef.slideTo(tileIndex, 0);
    }
  };

  const scrollToEntryId = thumbnailRef => thumbnailRef.scrollIntoView();

  // On grid [X, Y], onSwiper [X]
  const restoreThumbnailScrollPosition = (thumbnailRef, entryId) => {
    // For gridContent
    if (thumbnailRef && entryId === navigationState.entryId && !navigationState.rowId) {
      scrollToEntryId(thumbnailRef);

      const MainDivElement = document.getElementById('MainDiv');

      // Restore a little par of viewport
      if (MainDivElement && MainDivElement.scrollTop <= 150) {
        MainDivElement.scrollTo(0, 0);
      } else {
        MainDivElement && MainDivElement.scrollBy(0, SCROLL_MARGIN_TOP);
      }
    }
  };

  return {
    restoreRowScrollPosition,
    restoreThumbnailScrollPosition,
    restoreWindowScrollPosition
  };
}

export default useScrollRestoration;
