import React from 'react';

import ModalOpaqueBackground from '../ModalOpaqueBackground';

import './Spinner.css';

interface SpinnerProps {
  testId: string;
}

function Spinner({ testId }: SpinnerProps) {
  return (
    <>
      <div className="Spinner" data-testid={testId}>
        <div className="SpinnerLogo"></div>
      </div>
      <ModalOpaqueBackground />
    </>
  );
}

export default Spinner;
