import { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';

function usePortal(el) {
  const [portal, setPortal] = useState({
    render: ({ children }) => ReactDOM.createPortal(children, el),
    remove: () => {
      return;
    }
  });

  const createPortal = useCallback(el => {
    //render a portal at the given DOM node:

    const Portal = ({ children }) => ReactDOM.createPortal(children, el);
    //delete the portal from memory:
    const remove = () => {
      ReactDOM.unmountComponentAtNode(el);
      return;
    };
    return { render: Portal, remove };
  }, []);

  useEffect(() => {
    //if there is an existing portal, remove the new instance.
    if (el) portal.remove();
    //otherwise, create a new portal and render it
    const newPortal = createPortal(el);
    setPortal(newPortal);
    //when the user exits the page, delete the portal from memory.
    return () => newPortal.remove();
  }, [el]);

  return portal.render;
}

export default usePortal;
