import React from 'react';

import { PlaybackInfo } from '@oqee/core';

import Player from './Player';

import useRecordPlaybackInfo from '../../hooks/useWebRecordPlaybackInfo';

interface RecordPlayerProps {
  recordId: string;
  recordDetail: any;
}

function RecordPlayer({ recordId, recordDetail }: RecordPlayerProps) {
  const playbackInfo: PlaybackInfo | null = useRecordPlaybackInfo(recordId, recordDetail);

  if (!playbackInfo) {
    return null;
  }

  return <Player playbackInfo={playbackInfo} />;
}

export default RecordPlayer;
