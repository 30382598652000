import React from 'react';
import clsx from 'clsx';
import { APIUserAvatarAgeRange, APIUserAvatarShape, useAccountProfile } from '@oqee/core';
import { Location, NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';

import UserProfileLayout from '../../../../components/layout/UserProfileLayout';
import SettingsNavHeader from '../../SettingsNavHeader';
import Typography from '../../../../components/shared/Typography';
import Button from '../../../../components/shared/Button';

import './ProfileColor.css';

interface ProfileColorItemProps {
  color: string;
  tone: string;
  avatarPicture: string;
  selected: boolean;
  onSelect: (color: string) => void;
}

function ProfileColorItem({ color, tone = 'fff', avatarPicture, selected, onSelect }: ProfileColorItemProps) {
  const { getAvatarUrl } = useAccountProfile();
  const pictureUrl = getAvatarUrl({
    url: avatarPicture,
    avatar_tone: `#${tone}`
  });

  return (
    <div
      className={clsx('ProfileColorItem', { selected })}
      style={{ backgroundColor: color }}
      onClick={() => onSelect(color)}
    >
      <img src={pictureUrl} alt="" />
    </div>
  );
}

type ProfileColorParams = {
  profileId: string | undefined;
  ageRange: string;
  avatarShapeKey: string;
  avatarTone: string;
};

function ProfileColor() {
  const { profileId, ageRange, avatarShapeKey, avatarTone } = useParams() as ProfileColorParams;
  const { avatars, getProfile } = useAccountProfile();
  const location: Location = useLocation();
  const state: any = location.state ?? {};
  const navigate: NavigateFunction = useNavigate();

  const currentProfile = getProfile(profileId);

  const ageRangeAvatars: APIUserAvatarAgeRange = avatars.age_ranges.find(age => age.key === ageRange);
  const avatarShape: APIUserAvatarShape | undefined = ageRangeAvatars?.avatar_shapes?.find(
    shape => shape.key === avatarShapeKey
  );

  const isProfileCreation = profileId === undefined;
  const initialColor: string | null = state.avatarColor
    ? `#${state.avatarColor}`
    : currentProfile
    ? currentProfile.avatar_color
    : null;
  const [avatarColor, setAvatarColor] = React.useState<string | null>(initialColor);

  const handleBack = () => {
    navigate('../..', {
      relative: 'path',
      state: {
        avatarShapeKey,
        avatarTone
      }
    });
  };

  const handleContinue = () => {
    if (!avatarColor) return;
    if (isProfileCreation) {
      navigate(avatarColor.slice(1));
    } else {
      const profile = {
        ...currentProfile,
        age_range: ageRange,
        avatar_shape: avatarShapeKey,
        avatar_tone: `#${avatarTone}`,
        avatar_color: avatarColor
      };
      navigate(`${avatarColor.slice(1)}/commit`, { state: { profile } });
    }
  };

  return (
    <UserProfileLayout>
      <div className="ProfileColor">
        <SettingsNavHeader onBack={handleBack} />

        <div className="ProfileColorTitle">
          <Typography variant="h1">Votre couleur</Typography>
        </div>

        <div className="ProfileColorGrid">
          {avatarShape &&
            avatars?.colors?.map((color, index) => (
              <ProfileColorItem
                key={index}
                selected={color === avatarColor}
                avatarPicture={avatarShape.picture}
                color={color}
                tone={avatarTone}
                onSelect={setAvatarColor}
              />
            ))}
        </div>

        <div className="ProfileColorContinueButton">
          <Button label="Continuer" type="primary" onClick={handleContinue} disabled={!avatarColor} />
        </div>
      </div>
    </UserProfileLayout>
  );
}

export default ProfileColor;
