import React from 'react';

import { Toaster } from 'react-hot-toast';

import './PlayerDisclaimer.css';

function PlayerDisclaimer() {
  return (
    <Toaster
      toastOptions={{
        className: 'PlayerDisclaimer'
      }}
    />
  );
}

export default PlayerDisclaimer;
