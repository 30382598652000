import React, { useEffect } from 'react';

import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import { useSubscriptionManager, formatPrice } from '@oqee/core';

import Button from '../../../../components/shared/Button';
import Spinner from '../../../../components/shared/Spinner';

import OfferDetailsSection from '../OfferDetailsSection';

function SubscriptionOffer() {
  const navigate: NavigateFunction = useNavigate();
  const { offerType, offerId } = useParams();
  const { currentOffer } = useSubscriptionManager(offerType, Number(offerId));

  useEffect(() => {
    // Fetched and not exist, go back to list
    if (currentOffer === undefined) {
      return navigate('/settings/subscriptions');
    }
  }, [currentOffer]);

  if (!currentOffer) {
    return <Spinner />;
  }

  const { title, price } = currentOffer;
  const subtitle = `${formatPrice(price, '€')}/mois, sans engagement`;

  const ButtonsSlot = () => {
    if (currentOffer.state === 'unsubscribed_end_of_month') {
      return (
        <Button label="Annuler la résiliation" onClick={() => navigate('subscribe')} size="large" type="primary" />
      );
    }

    if (currentOffer.state === 'subscribed' && currentOffer.can_unsubscribe) {
      return (
        <>
          <Button
            label="Résilier dès maintenant"
            onClick={() =>
              navigate('unsubscribe', {
                state: {
                  terminationMode: 'now'
                }
              })
            }
            type="primary"
          />
          <Button
            label="Résilier à la fin de la période d'abonnement"
            onClick={() =>
              navigate('unsubscribe', {
                state: {
                  terminationMode: 'end_of_month'
                }
              })
            }
          />
        </>
      );
    }

    return null;
  };

  return <OfferDetailsSection title={title} subtitle={subtitle} ButtonsSlot={ButtonsSlot} />;
}

export default SubscriptionOffer;
