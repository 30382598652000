import React from 'react';
import clsx from 'clsx';

import './InputText.css';

interface InputTextProps {
  placeholder: string;
  maxLength?: number;
  value?: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  list?: string;
  autoComplete?: string;
  onBlur?: (e: object) => void;
}

function InputText({ onChange, ...inputProps }: InputTextProps) {
  const handleChange = event => onChange(event.target.value);

  return (
    <div className={clsx('InputText')}>
      <input type="text" onChange={handleChange} {...inputProps} />
    </div>
  );
}

export default InputText;
