import React from 'react';

import { APIUserNpvrQuotaResult, useLibraryStatus } from '@oqee/core';

import Typography from '../shared/Typography';

function MyListRecordStatus() {
  const status: APIUserNpvrQuotaResult | null = useLibraryStatus();
  if (!status) return null;

  const statusHour: number = Math.trunc(status.current_use / 60 / 60);
  const strStatus: string = statusHour >= 2 ? `${statusHour}h enregistrées` : `${statusHour}h enregistrée`;

  return (
    <Typography variant="body2" bold className="MyListRecordStatus" color="grey-3">
      {status.price_per_hour_ct ? strStatus : null}
    </Typography>
  );
}

export default MyListRecordStatus;
