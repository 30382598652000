import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PICTURE_FORMAT_WEB } from '@oqee/core';
import { Metadata, MixedLine, Typography } from '@oqee/ui';
import SinglePageLayout from '../layout/SinglePageLayout';
import RecordCTA from '../cta/RecordCTA';
import ProgramTitle from '../shared/ProgramTitle';

type RecordSinglePageProps = {
  program: any;
  isResumable?: boolean;
  onResumableDelete?: () => void;
};

const RecordSinglePage: React.FC<RecordSinglePageProps> = ({ program, isResumable, onResumableDelete }) => {
  const navigate = useNavigate();
  const handleRecordRemoved = React.useCallback(() => {
    const timeout = setTimeout(() => navigate(-1), 500);
    return () => clearTimeout(timeout);
  }, [navigate]);

  const thumbnailUrl = program.getThumbnailUrl(PICTURE_FORMAT_WEB.THUMBNAIL);
  const providerIconUrl = program.getIcon('light');

  const { title, description, meta, global_data, subtitle } = program.content;

  return (
    <SinglePageLayout
      thumbnailUrl={thumbnailUrl}
      title={<ProgramTitle progressRing={{ logoUrl: providerIconUrl }} title={title} subtitle={subtitle} />}
    >
      {global_data && <MixedLine mixedLine={global_data} program={program} />}
      <div className="buttons">
        <RecordCTA
          program={program}
          onRemoveRecord={handleRecordRemoved}
          isResumable={isResumable}
          onResumableDelete={onResumableDelete}
        />
      </div>
      <Typography>{description}</Typography>
      <Metadata variant="body1" metadata={meta} />
    </SinglePageLayout>
  );
};

export default RecordSinglePage;
