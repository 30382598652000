import React from 'react';
import { Link } from 'react-router-dom';

import LandingHeader from '../LandingHeader';
import LandingFooter from '../../../components/layout/Footer';
import PageLayout from '../../../components/layout/PageLayout';
import Typography from '../../../components/shared/Typography';

import './OffersPage.css';

function OffersPage() {
  const handleSubscribeFreeMobile = () => window.open('https://mobile.free.fr/fiche-forfait-free', '_blank');

  const handleSubscribeFreebox = () => window.open('https://www.free.fr/freebox/', '_blank');

  return (
    <PageLayout>
      <div className="OffersPage">
        <LandingHeader />
        <div className="w-container">
          <div className="fil-dariane">
            <Link to="/logout">
              <div className="BackLinkContainer">
                <div className={`BackArrow`}></div>
                <Typography variant="body2">Accueil</Typography>
              </div>
            </Link>
          </div>
        </div>
        <div className="nos-offres-container">
          <b className="nos-offres-oqee">Nos offres OQEE by Free</b>
          <div className="oqee-by-free-container">
            <span className="oqee-by-free-est-inclus-sans-s">
              <span>OQEE by Free est inclus sans surcoût dans le</span>
              <span className="span"> </span>
            </span>
            <span className="span">
              <b>Forfait mobile Free 5G</b>
            </span>
            <span> et dans les </span>
            <b className="span">
              <span>abonnements Freebox</span>
              <span className="span1"> !</span>
            </b>
          </div>
        </div>
        <div className="frame-parent">
          <div className="rectangle-parent">
            <div className="frame-child"></div>
            <div className="forfait-parent">
              <div className="inclus-dans">Inclus dans le</div>
              <div className="forfait-hr1"></div>
              <b className="forfait">Forfait Free 5G</b>
              <div className="forfait-hr2"></div>
            </div>
            <div className="fonctionnalits-disponibles-parent">
              <span className="fonctionnalits-disponibles">Fonctionnalités disponibles</span>
              <div className="component-1">
                <div className="checkmark-circle-plain-parent">
                  <img className="checkmark-circle-plain-icon" alt="" src="./img/offers/checkmark-circle-plain.svg" />
                  <div className="en-direct">En direct</div>
                </div>
                <div className="checkmark-circle-plain-parent">
                  <img className="checkmark-circle-plain-icon" alt="" src="./img/offers/checkmark-circle-plain.svg" />
                  <div className="replay">Replay</div>
                </div>
                <div className="checkmark-circle-plain-parent">
                  <img className="checkmark-circle-plain-icon" alt="" src="./img/offers/checkmark-circle-plain.svg" />
                  <div className="replay">OQEE Ciné</div>
                </div>
                <div className="close-plain-parent">
                  <img className="icones" alt="" src="./img/offers/close-plain.svg" />
                  <div className="replay">Enregistrement</div>
                </div>
                <div className="close-plain-parent">
                  <img className="icones" alt="" src="./img/offers/close-plain.svg" />
                  <div className="replay">Vidéo à la demande</div>
                </div>
                <div className="close-plain-parent">
                  <img className="icones" alt="" src="./img/offers/close-plain.svg" />
                  <div className="en-direct">Achats intégrés (chaînes, packs, ...)</div>
                </div>
              </div>
            </div>

            <div className="souscrire-link" onClick={handleSubscribeFreeMobile}>
              <b className="lien">Souscrire le Forfait Free 5G</b>
              <img className="external-link-icon" alt="" src="./img/offers/external-link.svg" />
            </div>
          </div>
          <div className="rectangle-parent">
            <div className="frame-child"></div>
            <div className="forfait-parent">
              <div className="inclus-dans">Inclus dans les</div>
              <div className="forfait-hr1"></div>
              <b className="forfait">Offres Freebox</b>
              <div className="forfait-hr2"></div>
            </div>
            <div className="fonctionnalits-disponibles-parent">
              <span className="fonctionnalits-disponibles">Fonctionnalités disponibles</span>
              <div className="component-1">
                <div className="checkmark-circle-plain-parent">
                  <img className="checkmark-circle-plain-icon" alt="" src="./img/offers/checkmark-circle-plain.svg" />
                  <div className="en-direct">En direct</div>
                </div>
                <div className="checkmark-circle-plain-parent">
                  <img className="checkmark-circle-plain-icon" alt="" src="./img/offers/checkmark-circle-plain.svg" />
                  <div className="replay">Replay</div>
                </div>
                <div className="checkmark-circle-plain-parent">
                  <img className="checkmark-circle-plain-icon" alt="" src="./img/offers/checkmark-circle-plain.svg" />
                  <div className="replay">OQEE Ciné</div>
                </div>
                <div className="checkmark-circle-plain-parent">
                  <img className="checkmark-circle-plain-icon" alt="" src="./img/offers/checkmark-circle-plain.svg" />
                  <div className="replay">Enregistrement</div>
                </div>
                <div className="checkmark-circle-plain-parent">
                  <img className="checkmark-circle-plain-icon" alt="" src="./img/offers/checkmark-circle-plain.svg" />

                  <div className="replay">Vidéo à la demande</div>
                </div>
                <div className="checkmark-circle-plain-parent">
                  <img className="checkmark-circle-plain-icon" alt="" src="./img/offers/checkmark-circle-plain.svg" />
                  <div className="en-direct">Achats intégrés (chaînes, packs, ...)</div>
                </div>
              </div>
            </div>
            <div className="souscrire-link" onClick={handleSubscribeFreebox}>
              <b className="lien">Souscrire une offre Freebox</b>
              <img className="external-link-icon" alt="" src="./img/offers/external-link.svg" />
            </div>
          </div>
        </div>
        <LandingFooter />
      </div>
    </PageLayout>
  );
}

export default OffersPage;
