import React from 'react';
import { Typography, CircularProgress } from '@oqee/ui';

import './ProgramTitle.css';

type ProgramTitleProps = {
  progressRing: {
    logoUrl: string;
    progress?: number;
    circleColor?: string;
  };
  title: string;
  subtitle?: string;
};

function ProgramTitle({ progressRing, title, subtitle }: ProgramTitleProps) {
  return (
    <div className="ProgramTitle">
      <div className="ProgramTitleContent">
        {progressRing && <CircularProgress {...progressRing} size="small" />}
        <Typography variant="h3" bold>
          {title}
        </Typography>
      </div>
      {subtitle && (
        <Typography bold className="ProgramTitleSubContent" variant="h4">
          {subtitle}
        </Typography>
      )}
    </div>
  );
}

export default ProgramTitle;
