import React from 'react';
import { useParams } from 'react-router-dom';
import { usePageSingle } from '@oqee/core';

import MainLayout from '../../../components/layout/MainLayout';
import SinglePage from '../../../components/single/SinglePage';

type ReplaySinglePageParams = {
  contentId: string;
};

function ReplayDetailPage() {
  const { contentId } = useParams<ReplaySinglePageParams>();
  const { isReady, programEntry } = usePageSingle('replay', contentId);

  return (
    <MainLayout fbScreenName="single_replay" program={programEntry} withBackdrop>
      <SinglePage isReady={isReady} program={programEntry} />
    </MainLayout>
  );
}

export default ReplayDetailPage;
