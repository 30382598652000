import React from 'react';
import { useWebStoreActions, webStore } from '../../store/webStoreUtils';
import { useModal } from '../shared/Modal';
import { ErrorModelState } from '@oqee/core';

type DuplicateRecordWarningProps = {
  error: ErrorModelState;
};

function DuplicateRecordWarning({ error }: DuplicateRecordWarningProps) {
  const resetError = useWebStoreActions(actions => actions.error.reset);
  const { user } = webStore.getState();

  const description = React.useMemo(() => {
    const returnedProfileId = error?.data?.profile_id;
    const returnedProfile = user?.profiles?.find(p => p.id === returnedProfileId);
    const activeProfileId = user?.activeProfileId;

    if (returnedProfile && returnedProfileId !== activeProfileId) {
      return `Un enregistrement pour ce programme a déjà été planifié sur le profil “${returnedProfile.username}”`;
    } else {
      return `Un enregistrement pour ce programme a déjà été planifié sur ce profil`;
    }
  }, [user, error]);

  const { Modal } = useModal(
    {
      title: 'Enregistrement impossible',
      description: description,
      validateButtonLabel: `OK`,
      onValidate() {
        resetError();
      }
    },
    false,
    true
  );

  return <Modal />;
}

export default DuplicateRecordWarning;
