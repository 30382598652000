import React from 'react';
import clsx from 'clsx';
import ContentThumbnail from '../ContentThumbnail';

import './ContentGrid.css';

interface ContentGridProps {
  entries: any[];
  showTileTitle?: boolean;
  className?: string;
  displayVodAsLandscape?: boolean;
  onThumbnailClick?: () => any;
}

function ContentGrid({
  entries,
  showTileTitle = false,
  className,
  displayVodAsLandscape = false,
  onThumbnailClick
}: ContentGridProps) {
  return (
    <div className={clsx('ContentGrid',className)}>
      {entries?.map((entry, entryIndex) => (
        <div className="ContentGridItem" key={entryIndex} data-test-id={`content-grid-item-${entryIndex}`}>
          <ContentThumbnail
            entry={entry}
            showCircularProgress={entry.type === 'live'}
            showTileTitle={showTileTitle}
            displayVodAsLandscape={displayVodAsLandscape}
            onThumbnailClick={onThumbnailClick}
          />
        </div>
      ))}
    </div>
  );
}

export default ContentGrid;
