import React from 'react';
import { useToaster } from '@oqee/core';
import { ToasterContainer } from '@oqee/ui';

import './Toaster.css';
import clsx from 'clsx';

type ToasterProps = {
  className: string;
};

function Toaster({ className }: ToasterProps) {
  const { messages, handlers } = useToaster();
  const { onCloseMessage } = handlers;

  return (
    <ToasterContainer className={clsx('WebToaster', className)} messages={messages} onCloseMessage={onCloseMessage} displayClose />
  );
}

export default Toaster;
