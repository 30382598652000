import { useParentalCode, api } from '@oqee/core';
import React from 'react';
import { useNavigate, NavigateFunction, useLocation, Location } from 'react-router-dom';
import InputCodeModal from '../InputCodeModal';
import { useWebStoreActions, useWebStoreState } from '../../../store/webStoreUtils';
import { ActionCreator, ThunkCreator } from 'easy-peasy';

interface ParentalGuardProps extends React.PropsWithChildren {
  isAdult: boolean;
  requireNonExpiredToken?: boolean;
}

function ParentalGuard({ isAdult, requireNonExpiredToken = false, children }: ParentalGuardProps) {
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();

  const cat5Token: string | null = useWebStoreState(state => state.auth.cat5Token);
  const isCat5TokenExpired: boolean = !!cat5Token && api.auth.readToken(cat5Token)?.expire < Date.now();
  const requestCat5Token: ThunkCreator<string, any> = useWebStoreActions(actions => actions.auth.requestCat5Token);
  const resetCat5Token: ActionCreator<void> = useWebStoreActions(actions => actions.auth.resetCat5Token);
  const { parentalCode, codeLocked } = useParentalCode();

  const [isReady, setReady] = React.useState(false);

  // Reset cat5 token on non-adult content
  React.useEffect(() => {
    if (isAdult) return;

    resetCat5Token();
  }, [isAdult, resetCat5Token]);

  // On non-adult, wait for cat5 token to be reset
  React.useEffect(() => {
    if (!isAdult && cat5Token) {
      return () => setReady(true);
    } else {
      setReady(true);
    }
  }, [isAdult, cat5Token]);

  const handleValidate = (code: string) => {
    requestCat5Token(code);
  };

  const handleCancel = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      // comes from outside the app, redirect to home
      navigate('/home');
    }
  };

  if (!isReady) return null;
  return isAdult && (!cat5Token || (requireNonExpiredToken && isCat5TokenExpired)) ? (
    <InputCodeModal
      title="Code parental"
      subtitle="Saisissez votre code parental à 4 chiffres"
      showLostCodeTip={true}
      codeAttemptLeft={parentalCode.codeAttemptLeft}
      codeLocked={codeLocked}
      onValidate={handleValidate}
      onCancel={handleCancel}
    />
  ) : (
    <>{children}</>
  );
}

export default ParentalGuard;
