import React from 'react';

import { NavigateFunction, useNavigate } from 'react-router-dom';

import Modal from '../../shared/Modal';

function NeedSubscriptionCanalPlus() {
  const navigate: NavigateFunction = useNavigate();

  const handleBack = () => navigate(-1);

  const modalProps = {
    title: 'CANAL+ La chaîne en live',
    description: (
      <div>
        Pour activer cette chaîne, rendez-vous sur la chaîne 4 de votre Player TV Free 4K.
        <br />
        <br />
        Elle est incluse pour les abonnés Freebox Ultra, tandis que les abonnés des autres offres Freebox ont la
        possibilité d&apos;y souscrire.
      </div>
    ),
    onValidate: handleBack,
    validateButtonLabel: "J'ai compris"
  };

  return <Modal {...modalProps} />;
}

export default NeedSubscriptionCanalPlus;
