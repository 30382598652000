import React from 'react';
import { useParams } from 'react-router-dom';
import { useReplayProgramDetails } from '@oqee/core';

import PlayerPageLayout from '../../components/layout/PlayerPageLayout';
import ParentalGuard from '../../components/shared/ParentalGuard';
import ReplayPlayer from '../../components/player/ReplayPlayer';

type ReplayPlayerPageParams = {
  contentId: string;
};

function ReplayPlayerPage() {
  const { contentId } = useParams() as ReplayPlayerPageParams;
  const { isReady, programEntry } = useReplayProgramDetails(Number(contentId));

  return (
    <PlayerPageLayout>
      {isReady && (
        <ParentalGuard isAdult={programEntry.replay?.parental_rating >= 18} requireNonExpiredToken={true}>
          <ReplayPlayer programEntry={programEntry} />
        </ParentalGuard>
      )}
    </PlayerPageLayout>
  );
}

export default ReplayPlayerPage;
