import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProgram, useResumableContents } from '@oqee/core';
import VodSinglePage from './VodSinglePage';
import { SinglePagePlaceholder } from '../layout/SinglePageLayout';
import ReplaySinglePage from './ReplaySinglePage';
import ChannelSinglePage from './ChannelSinglePage';
import RecordSinglePage from './RecordSinglePage';

import './SinglePage.css';

const components = {
  record: RecordSinglePage,
  live: ChannelSinglePage,
  replay: ReplaySinglePage,
  vod: VodSinglePage
};

type SinglePageProps = {
  isReady?: boolean;
  program: any;
};

const SinglePage: React.FC<SinglePageProps> = ({ program: programEntry, isReady }) => {
  const program = useProgram(programEntry);
  const navigate = useNavigate();
  const { findResumable, removeResumable } = useResumableContents();
  const [isResumable, setIsResumable] = React.useState<boolean>(false);
  const location = useLocation();

  React.useEffect(() => {
    if (!programEntry) return;
    const isResumable = Boolean(findResumable(programEntry.type, programEntry[programEntry.type].id));
    setIsResumable(isResumable && Boolean(location.state.isResumable));
  }, [programEntry, location, findResumable]);

  const handleResumableDelete = React.useCallback(() => {
    const { content, contentType } = program;
    removeResumable(contentType, content.id).then(() => {
      navigate(-1);
    });
  }, [program, navigate, removeResumable]);

  if (!isReady) return <SinglePagePlaceholder />;

  const Page = components[program.contentType];

  return <Page program={program} isResumable={isResumable} onResumableDelete={handleResumableDelete} />;
};

export default SinglePage;
