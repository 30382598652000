import React from 'react';
import { PICTURE_FORMAT_WEB, formatPictureUrl, useVodProgramDetails } from '@oqee/core';
import { Metadata, MetadataProvider, MixedLine, Typography } from '@oqee/ui';
import SinglePageLayout from '../layout/SinglePageLayout';
import ProgramCTA from '../cta/ProgramCTA';

type VodSinglePageProps = {
  program: any;
  isResumable?: boolean;
  onResumableDelete?: () => void;
};

const VodSinglePage: React.FC<VodSinglePageProps> = ({ program, isResumable, onResumableDelete }) => {
  const { content_id, ordered_offer_groups } = program.content;
  const purchasedVod = useVodProgramDetails(content_id, null, false, ordered_offer_groups);
  const thumbnailUrl = program?.getThumbnailUrl(PICTURE_FORMAT_WEB.THUMBNAIL);
  const { content } = program;

  const {
    title,
    description,
    meta,
    global_data,
    thumbnail_orientation,
    entity_providers = {
      providers_logo: [],
      text: 'Distribué par :'
    }
  } = content;

  const { VOD_PROVIDER_LOGO } = PICTURE_FORMAT_WEB;
  const providerLogos = entity_providers.providers_logo.map(logoUrl => formatPictureUrl(logoUrl, VOD_PROVIDER_LOGO));

  return (
    <SinglePageLayout
      title={title}
      thumbnailUrl={thumbnailUrl}
      thumbnailLayout={thumbnail_orientation === 'landscape' ? 'landscape' : 'portrait'}
    >
      {global_data && <MixedLine mixedLine={global_data} program={{ ...program, ...purchasedVod.programEntry }} />}
      <ProgramCTA program={program} isResumable={isResumable} onResumableDelete={onResumableDelete} />
      <Typography>{description}</Typography>
      <Metadata variant="body1" metadata={meta} />
      <MetadataProvider text={entity_providers.text} providersLogo={providerLogos} mode="horizontal" />
    </SinglePageLayout>
  );
};

export default VodSinglePage;
