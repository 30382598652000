import './UserProfileLayout.css';

import React from 'react';
import { useLocation, Location } from 'react-router-dom';

import PageLayout from '../PageLayout';
import MainLayout from '../MainLayout';
import { HeaderNav } from '../../../utils/webUtils';

function UserProfileLayout({ children }: React.PropsWithChildren) {
  const location: Location = useLocation();

  if (location.pathname.startsWith('/settings')) {
    return <MainLayout activeHeaderNav={HeaderNav.SETTINGS}>{children}</MainLayout>;
  } else {
    return (
      <PageLayout>
        <div className="UserProfileLayout">{children}</div>
      </PageLayout>
    );
  }
}

export default UserProfileLayout;
