import React from 'react';
import { Typography } from "@oqee/ui";
import { useSearchParams } from "react-router-dom";

interface SearchEntryProps {
    searchEntry: string;
    searchHistory: Array<string>;
    setSearchHistory: (history) => void;
  }
  
  function SearchEntry({ searchEntry, searchHistory, setSearchHistory }: SearchEntryProps) {
    const [, setSearchParams] = useSearchParams();
  
    return (
      <div className="SearchEntry" key={searchEntry}>
        <div
          onMouseDown={() => {
            const oldSearchHistory = [...searchHistory.filter(historyValue => historyValue !== searchEntry)];
            const newSearchHistory = [searchEntry, ...oldSearchHistory];
            setSearchHistory(newSearchHistory);
            setSearchParams(
              { term: searchEntry },
              {
                replace: true
              }
            );
          }}
        >
          <Typography variant="body1" className="SearchEntryTitle">
            {searchEntry}
          </Typography>
        </div>
        <div
          className="SearchEntryClear"
          onMouseDown={e => {
            const oldSearchHistory = [...searchHistory.filter(historyValue => historyValue !== searchEntry)];
            const newSearchHistory = oldSearchHistory;
            setSearchHistory(newSearchHistory);
            e.preventDefault();
          }}
        ></div>
      </div>
    );
  }
  
  export default SearchEntry