import React from 'react';

import Typography from '../Typography';
import './ErrorTemplate.css';

function ErrorTemplateTitle({ children }) {
  return (
    <Typography variant="h2" className="ErrorTemplateTitle">
      {children}
    </Typography>
  );
}

function ErrorTemplateDescription({ children }) {
  return (
    <Typography variant="body1" className="ErrorTemplateDescription">
      {children}
    </Typography>
  );
}

function ErrorTemplateMenu({ children }) {
  return <div className="ErrorTemplateMenu">{children}</div>;
}

function ErrorTemplate({ children }) {
  return <div className="ErrorTemplate">{children}</div>;
}

ErrorTemplate.Title = ErrorTemplateTitle;
ErrorTemplate.Description = ErrorTemplateDescription;
ErrorTemplate.Menu = ErrorTemplateMenu;

export default ErrorTemplate;
