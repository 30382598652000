import React, { useMemo } from 'react';
import { CONSTANTS, useMyList } from '@oqee/core';

import { HeaderNav, MyListNav } from '../../../utils/webUtils';
import SubMenu from '../../../components/shared/SubMenu';
import MainLayout from '../../../components/layout/MainLayout';
import ContentRows, { CONTENT_ROWS_PLACEHOLDERS } from '../../../components/content/ContentRows';
import { SubMenuItem } from '../../shared/SubMenu/SubMenu';

const SUB_MENU_ITEMS: Array<SubMenuItem> = [
  {
    to: '/home/my_list',
    id: MyListNav.ALL,
    title: 'Tous'
  },
  {
    to: '/my_list/records',
    id: MyListNav.RECORDS,
    title: 'Enregistrements'
  },
  {
    to: '/my_list/vod',
    id: MyListNav.VOD,
    title: 'VOD'
  }
];

function MyListLayout({ pageId, filter, fallback }) {
  const filteredMyList = useMyList(filter, ['record', 'vod', 'vod_portal']);
  const displayedSections: Array<any> = useMemo(() => {
    return (
      filteredMyList?.map(section => {
        // For now we just show OQEE CINE
        if (section.key === 'svod_subscriptions') {
          return {
            ...section,
            entries: section.entries.filter(
              entry => entry?.[entry?.type]?.provider_id === CONSTANTS.OQEE_CINE_PROVIDER_ID
            )
          };
        }

        return section;
      }) ?? CONTENT_ROWS_PLACEHOLDERS
    );
  }, [filteredMyList]);

  return (
    <MainLayout activeHeaderNav={HeaderNav.MY_LIST} className="MyListPage">
      <SubMenu items={SUB_MENU_ITEMS} activeSubMenuItem={pageId} showRecordStatus={true} isSticky />
      {displayedSections?.length ? (
        <ContentRows
          rows={displayedSections}
          hideCircularProgressInRows={['svod_subscriptions', 'vods_expired', 'tvods', 'ests']}
          notAvailableProviders={[CONSTANTS.CANAL_VOD_PROVIDER_ID]}
          showTileTitle={false}
          showTileTitleInRows={['records_available', 'records_incoming', 'records_old']}
          showCircularProgress
        />
      ) : (
        fallback
      )}
    </MainLayout>
  );
}

export default MyListLayout;
