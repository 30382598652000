import React, { useCallback } from 'react';
import { ChevronBasIcon, ChevronHautIcon, Typography } from '@oqee/ui';
import clsx from 'clsx';

import './Select.css';

export type SelectOption = { value: string; text: string };

type SelectProps = {
  options: SelectOption[];
  value?: SelectOption;
  onSelect?: (option: SelectOption) => void;
};

function Select({ options, value, onSelect }: SelectProps) {
  const [selectedValue, setSelectedValue] = React.useState<SelectOption>(value || options[0]);
  const [showMenu, setShowMenu] = React.useState(false);

  const handleOnClick = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu, setShowMenu]);

  const handleSelectValue = useCallback(
    value => {
      setSelectedValue(value);
      onSelect && onSelect(value);
    },
    [setSelectedValue, onSelect]
  );

  const Chevron = React.useMemo(
    function renderIcon() {
      return showMenu ? ChevronHautIcon : ChevronBasIcon;
    },
    [showMenu]
  );

  return (
    <div
      className={clsx('Select', 'unselectable', { open: showMenu })}
      onClick={handleOnClick}
      data-test-id="input-select"
    >
      <Typography bold variant="body1">
        {selectedValue.text}
        <Chevron width={16} height={16} />
      </Typography>

      <div className="SelectMenu">
        {options.map((option, index) => (
          <div
            data-test-id={`input-select-item-${index}`}
            className={clsx('SelectMenuItem', { selected: option.value === selectedValue.value })}
            key={`option_${index}`}
            onClick={() => handleSelectValue(option)}
          >
            <Typography variant="body1">{option.text}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Select;
