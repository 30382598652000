import React from 'react';

import Typography from '../../../components/shared/Typography';

import './SettingsNavHeader.css';

interface SettingsNavHeaderProps {
  onBack?: () => void;
  onSkip?: () => void;
}

function SettingsNavHeader({ onBack, onSkip }: SettingsNavHeaderProps) {
  return (
    <div className="SettingsNavHeader">
      {onBack && (
        <div className="BackLinkContainer" onClick={onBack}>
          <div className="BackArrow"></div>
          <Typography variant="body2">Retour</Typography>
        </div>
      )}

      {onSkip && (
        <div className="SkipLinkContainer" onClick={onSkip}>
          <Typography variant="body2">Passer</Typography>
        </div>
      )}
    </div>
  );
}

export default SettingsNavHeader;
