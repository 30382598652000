import React from 'react';
import {
  api,
  APIReplayPlaybackInfoResponse,
  APIReplayProgramDetail,
  FormattedChannel,
  ReplayPlaybackInfo,
  StreamingType
} from '@oqee/core';
import { BrowserContext, BrowserContextType } from '../components/context/BrowserContextProvider';
import { getAdditionalConsentString, getConsentString } from '../utils/didomiUtils';
import { useWebStoreActions, useWebStoreState } from '../store/webStoreUtils';
import { ActionCreator } from 'easy-peasy';

const { DRM_SYSTEM } = api.constant;

function useWebReplayPlaybackInfo(programEntry: APIReplayProgramDetail): ReplayPlaybackInfo | null {
  const browserContext: BrowserContextType = React.useContext(BrowserContext);
  const triggerError: ActionCreator<any> = useWebStoreActions(actions => actions.error.trigger);
  const cat5Token: string | null = useWebStoreState(state => state.auth?.cat5Token);
  const channels: FormattedChannel[] = useWebStoreState(state => state.channel.list);
  const channel: FormattedChannel | undefined = channels.find(c => c.id === programEntry.replay?.portal?.channel_id);
  const { number: channelNumber, isLocked } = channel;

  const [playbackInfo, setPlaybackInfo] = React.useState<ReplayPlaybackInfo | null>(null);
  const replayId: string = programEntry?.replay?.id?.toString();

  const handlePlaybackInfoResponse = React.useCallback(
    (response: APIReplayPlaybackInfoResponse) => {
      if (!response.success) {
        triggerError({ code: response.error?.code, data: { channelNumber: channel?.number } });
        return;
      }

      if (response.result) {
        const {
          media_url,
          drm,
          license_server,
          ads_info,
          position,
          subtitles = {},
          pre_ads,
          pre_medias,
          thumbnails
        } = response.result;

        setPlaybackInfo({
          broadcastType: 'replay',
          manifestUrl: media_url,
          drmSystem: drm && DRM_SYSTEM?.[drm],
          drmServer: license_server,
          adsInfo: ads_info,
          position,
          promoToken: null,
          programId: programEntry.replay.id,
          subtitles,
          preAds: pre_ads,
          preMedias: pre_medias,
          thumbnails
        });
      }
    },
    [setPlaybackInfo, triggerError]
  );

  React.useEffect(() => {
    if (isLocked) {
      triggerError({ code: 'need_subscription', data: { channelNumber } });
      return;
    }
    const [supportedStreamType, supportedDrm, supportedCipher]: [string, string, (string | null | undefined)[]] =
      browserContext?.selectedStreamingType === StreamingType.HLS
        ? [StreamingType.HLS, 'fairplay', browserContext.availableCiphers || ['cbcs']]
        : [StreamingType.DASH, 'widevine', browserContext.availableCiphers || ['cenc']];

    const playbackInfoOptions = {
      supportedStreamTypes: [supportedStreamType],
      supportedDrms: [supportedDrm],
      supportedCiphers: supportedCipher as string[],
      supportedAds: ['vast', 'vmap'],
      consent_string: getConsentString(),
      additional_consent_string: getAdditionalConsentString()
    };

    api.replay
      .fetchPlaybackInfo(replayId, playbackInfoOptions)
      .then(handlePlaybackInfoResponse)
      .catch(error => {
        triggerError({ code: 'content_not_found', data: error });
      });
  }, [isLocked, channelNumber, handlePlaybackInfoResponse, triggerError, replayId, cat5Token]);

  return playbackInfo;
}

export default useWebReplayPlaybackInfo;
