import React from 'react';
import { PlaybackInfo } from '@oqee/core';

import useWebVodPlaybackInfo from '../../hooks/useWebVodPlaybackInfo';
import Player from './Player';

interface TVodPlayerProps {
  purchaseId: string;
}

function TVodPlayerProps({ purchaseId }: TVodPlayerProps) {
  const playbackInfo: PlaybackInfo | null = useWebVodPlaybackInfo({ apiType: 'vod', purchaseId });

  return <Player playbackInfo={playbackInfo} />;
}

export default TVodPlayerProps;
