import React from 'react';
import { useParams } from 'react-router-dom';
import { usePageSingle } from '@oqee/core';

import MainLayout from '../../../components/layout/MainLayout';
import SinglePage from '../../../components/single/SinglePage';

type DiffusionSinglePageParams = {
  contentId: string;
};

function DiffusionDetailPage() {
  const { contentId } = useParams<DiffusionSinglePageParams>();
  const { isReady, programEntry } = usePageSingle('diffusion', contentId);

  return (
    <MainLayout fbScreenName="single_upcoming" program={programEntry} withBackdrop>
      <SinglePage isReady={isReady} program={programEntry} />
    </MainLayout>
  );
}

export default DiffusionDetailPage;
