import { APIVodPortalHomeLine, APIVodPortalHomeResponse, api } from '@oqee/core';
import { useEffect, useState } from 'react';

function useWebVodPortal(portalId: number) {
  const [oqeeCinePortalData, setOqeeCinePortalData] = useState<APIVodPortalHomeLine[]>();

  useEffect(() => {
    api.vod.fetchPortal(portalId).then((response: APIVodPortalHomeResponse) => {
      if (response.result) setOqeeCinePortalData(response.result.lines);
    });
  }, []);

  return oqeeCinePortalData;
}

export default useWebVodPortal;
