import { getPromoToken, useSuggestedChannels } from '@oqee/core';
import React from 'react';
import LivePlayer from '../../../../components/player/LivePlayer';
import { LockedChannelPlayerMessage } from '../../../../components/player/PlayerMessage';

import './LivePreview.css';
import { useWebStoreState } from '../../../../store/webStoreUtils';

function LivePreview() {
  const suggestedChannels = useSuggestedChannels({ channelNumber: null });
  const suggestedChannelIndex: number = useWebStoreState(state => state.home.suggestedChannelIndex);
  const timestamp: number = useWebStoreState(state => state.channel.timestamp);

  const liveChannel = suggestedChannels?.[suggestedChannelIndex]?.channel;
  const promoToken = getPromoToken(liveChannel, timestamp);
  const isLocked = liveChannel?.isLocked && !promoToken;

  const [isChannelSubscriptionOpen, setIsChannelSubscriptionOpen] = React.useState(false);

  const openChannelSubscription = () => setIsChannelSubscriptionOpen(true);

  const closeChannelSubscription = () => setIsChannelSubscriptionOpen(false);

  return (
    <div className="PreviewPlayer">
      {isLocked && <LockedChannelPlayerMessage openChannelSubscription={openChannelSubscription} />}

      {(!isLocked || isChannelSubscriptionOpen) && liveChannel && (
        <LivePlayer
          liveChannel={liveChannel}
          handleBack={closeChannelSubscription}
          isLivePreview={true}
          key={liveChannel?.id} // use key to mount/unmount player components between each zapping, avoiding unwanted side effects
        />
      )}
    </div>
  );
}

export default LivePreview;
