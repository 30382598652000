import React, { useState } from 'react';
import { BasketIcon, Button, CroixPleinIcon, PlayIcon, Typography } from '@oqee/ui';
import { Link } from 'react-router-dom';
import ChannelSubscriptionGuard from '../shared/ChannelSubscriptionGuard';

type ReplayCTAProps = {
  program: any;
  rounded?: boolean;
  isResumable?: boolean;
  onResumableDelete?: () => void;
}

function ReplayCTA({ program, rounded = false, isResumable, onResumableDelete }: ReplayCTAProps) {
  const programUrl = program.getUrl(import.meta.env.VITE_APPLICATION_TYPE);
  const [showSubscription, setShowSubscription] = useState(false);
  const { channel, isLocked } = program;

  return (
    <>
      {isLocked ? (
        <ChannelSubscriptionGuard
          channelNumber={channel?.number}
          onCancel={() => setShowSubscription(false)}
          onSuccess={() => setShowSubscription(false)}
          displaySubscription={showSubscription}
        >
          <Button
            onClick={() => setShowSubscription(true)}
            rounded={rounded}
            color="primary"
            icon={BasketIcon}
            iconColor="info"
            tooltip={rounded ? `S'abonner à la chaine` : undefined}
          >
            {!rounded && <Typography variant="body1" bold>{`S'abonner à la chaine`}</Typography>}
          </Button>
        </ChannelSubscriptionGuard>
      ) : (
        <Link to={`${programUrl.replace('detail', 'play')}`}>
          <Button
            color="primary"
            icon={PlayIcon}
            rounded={rounded}
            iconColor="info"
            tooltip={rounded ? 'Lecture' : undefined}
          >
            {!rounded && (
              <Typography variant="body1" bold>
                Lecture
              </Typography>
            )}
          </Button>
        </Link>
      )}
      {isResumable && (
        <Button
          data-test-id="cta-replay-resume-delete"
          icon={CroixPleinIcon}
          tooltip="Retirer de la ligne"
          onClick={onResumableDelete}
        />
      )}
    </>
  );
}

export default ReplayCTA;
