import React from 'react';

import { Placeholder } from '@oqee/ui';

import './SinglePagePlaceholder.css';

const SinglePagePlaceholder: React.FC = () => {
  return (
    <div className="single-page-placeholder">
      <div className="content">
        <div className="details">
          <Placeholder radius={8} height={40} width={800} />
          <Placeholder radius={8} height={32} width={800} />
          <div className="buttons">
            <Placeholder radius={8} height={48} width={136} />
            <Placeholder radius={8} height={48} width={58} />
            <Placeholder radius={8} height={48} width={58} />
          </div>
          <div className="description">
            <Placeholder radius={8} height={32} width={800} />
            <Placeholder radius={8} height={32} width={800} />
            <Placeholder radius={8} height={32} width={700} />
          </div>
          <Placeholder radius={8} height={32} width={800} />
        </div>
        <Placeholder className="thumbnail" radius={16} height={200} width={358} />
      </div>
    </div>
  );
};

export default SinglePagePlaceholder;
