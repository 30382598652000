import React from 'react';
import { Button, CroixPleinIcon, PlayIcon, Typography } from '@oqee/ui';
import { Link } from 'react-router-dom';
import DeleteRecordButton from './DeleteRecordButton';

type RecordCTAProps = {
  program: any;
  rounded?: boolean;
  onRemoveRecord?: () => void;
  isResumable?: boolean;
  onResumableDelete?: () => void;
};

function RecordCTA({ program, rounded = false, onRemoveRecord, isResumable, onResumableDelete }: RecordCTAProps) {
  const { status } = program.content;
  const isAvailable = status === 'available';

  const programUrl = program.getUrl(import.meta.env.VITE_APPLICATION_TYPE);

  return (
    <>
      {!isAvailable ? (
        <DeleteRecordButton program={program} color="primary" rounded={rounded} onRemoveRecord={onRemoveRecord} />
      ) : (
        <>
          <Link to={`${programUrl}/play`}>
            <Button
              color="primary"
              icon={PlayIcon}
              rounded={rounded}
              iconColor="info"
              tooltip={rounded ? 'Lecture' : undefined}
            >
              {!rounded && (
                <Typography variant="body1" bold>
                  Lecture
                </Typography>
              )}
            </Button>
          </Link>
          {isResumable && (
            <Button
              data-test-id="cta-record-resume-delete"
              icon={CroixPleinIcon}
              tooltip="Retirer de la ligne"
              onClick={onResumableDelete}
            />
          )}
          <DeleteRecordButton
            program={program}
            color="secondary"
            rounded={rounded}
            hideLabel
            onRemoveRecord={onRemoveRecord}
          />
        </>
      )}
    </>
  );
}

export default RecordCTA;
