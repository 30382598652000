import React from 'react';
import { useProgram, useChannelSubscription, formatPrice } from '@oqee/core';
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import Typography from '../../shared/Typography';
import Button from '../../shared/Button';
import Spinner from '../../shared/Spinner/Spinner';
import CircularProgress from '../../shared/CircularProgress';
import ModalOpaqueBackground from '../../shared/ModalOpaqueBackground';
import useWebError from '../../../hooks/useWebError';
import Modal from '../../shared/Modal';

import './ChannelSubscriptionOffers.css';

function ChannelSubscriptionOfferChannel({ channelId }) {
  const program = useProgram({ type: 'live', live: { channel_id: channelId } });

  return (
    <div
      className="ChannelSubscriptionOfferChannel"
      data-tooltip-id="main-tooltip"
      data-tooltip-content={program.channel?.name}
    >
      <CircularProgress logoUrl={program.getIcon('light')} size="medium" />
    </div>
  );
}

interface ChannelSubscriptionOffersProps {
  onContinue: () => void;
}

function ChannelSubscriptionOffers({ onContinue }: ChannelSubscriptionOffersProps) {
  const { channel, setOffer, offers, fetchChannelOffers, selectedOffer, onCancel } = useChannelSubscription();

  const handleOfferSelect = offer => setOffer({ offerType: offer.type, offerId: offer.id });

  React.useEffect(() => {
    if (!channel?.isLocked) return;
    fetchChannelOffers(channel.id);
  }, [channel, fetchChannelOffers]);

  React.useEffect(() => {
    if (offers?.length > 0) {
      setOffer({ offerType: offers[0].type, offerId: offers[0].id });
    }
  }, [offers]);

  const error = useWebError();
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const defaultHandleBack = () => (location.key !== 'default' ? navigate(-1) : navigate('/home'));

  if (error?.shownComponentInPlayer === 'Modal' && error?.title) {
    // show message in simple modal
    return (
      <Modal
        title={error.title}
        description={error.description as string}
        onValidate={defaultHandleBack}
        validateButtonLabel="Fermer"
      />
    );
  }

  if (!channel || !offers?.length) return <Spinner testId="channel-offers-pending" />;

  return (
    <>
      <div className="ChannelSubscriptionOffers">
        <div className="ChannelSubscriptionOffersHeader">
          <Typography variant="h4">{channel.name}</Typography>
        </div>
        <Typography variant="body1" bold>
          Cette chaîne nécessite un abonnement payant. Sélectionnez une offre ci-dessous.
        </Typography>
        <div className="ChannelSubscriptionOffersContent">
          <div className="ChannelSubscriptionOffersContentLeft">
            <div className="ChannelSubscriptionOffersList">
              {offers?.map(offer => (
                <div
                  className={clsx('ChannelSubscriptionOffersListElement', { checked: offer.id === selectedOffer?.id })}
                  key={offer.menuItemId}
                  onClick={() => handleOfferSelect(offer)}
                >
                  <div className="ListElementOfferName">
                    <Typography variant="body1" bold>
                      {offer.name}
                    </Typography>
                    <img alt="" src={`/img/checkbox_${offer.id === selectedOffer?.id ? 'checked' : 'unchecked'}.svg`} />
                  </div>
                  <div className="ListElementOfferDetails">
                    <div className="ListElementOfferDetailsPrice">
                      {offer.promo_msg ? (
                        <Typography variant="body2" bold>
                          {offer.promo_msg}
                        </Typography>
                      ) : (
                        <>
                          <Typography variant="body2" bold>
                            {formatPrice(offer.price, '€')}
                          </Typography>
                          <Typography variant="body3" bold>
                            /mois
                          </Typography>
                        </>
                      )}
                    </div>
                    <div className="ListElementOfferDetailsDash"></div>
                    <div className="ListElementOfferDetailsEng">
                      <Typography variant="body2">
                        {offer.engagement > 1 ? `Engagement ${offer.engagement} mois` : `Sans engagement`}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="ChannelSubscriptionOffersMoreDetails">
              <Typography variant="body3">
                Pour plus de détails, visitez&nbsp;
                <a
                  className="ChannelSubscriptionOffersLink"
                  href="https://www.free.fr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.free.fr/
                </a>
              </Typography>
            </div>
          </div>
          <div className="ChannelSubscriptionOffersContentRight">
            {selectedOffer && (
              <div className="ChannelSubscriptionOffersSelectedOffer">
                {selectedOffer.promo_msg && <Typography variant="body3">{selectedOffer.promo_msg}</Typography>}
                {selectedOffer.abo_msg && <Typography variant="body3">{selectedOffer.abo_msg}</Typography>}

                <div className="SelectedOfferChannelsContainer">
                  <div className="SelectedOfferChannelsNumber">
                    <Typography variant="body2" bold>
                      {selectedOffer.channelIds?.length}&nbsp;
                      {selectedOffer.channelIds?.length > 1 ? 'chaînes incluses' : 'chaîne incluse'}
                    </Typography>
                  </div>
                  <div className="SelectedOfferChannelGridContainer">
                    <div className="SelectedOfferChannelGrid">
                      {selectedOffer.channelIds?.map(channelId => (
                        <ChannelSubscriptionOfferChannel channelId={channelId} key={channelId} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="ChannelSubscriptionOffersFooter">
          <div className="ChannelSubscriptionOffersFooterStep">
            <Typography variant="body1" color="grey-2">
              &Eacute;tape 1/2
            </Typography>
          </div>
          <div className="ChannelSubscriptionOffersFooterButton">
            <Button label="Annuler" onClick={onCancel} />
            <Button label="Continuer" type="primary" onClick={onContinue} />
          </div>
        </div>
      </div>
      <ModalOpaqueBackground />
    </>
  );
}

export default ChannelSubscriptionOffers;
