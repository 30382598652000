import { useChannelList, FormattedChannel } from '@oqee/core';
import React from 'react';
import { useLocation, useOutlet } from 'react-router-dom';

import { BrowserContext, BrowserContextType } from '../../components/context/BrowserContextProvider';

import MainLayout from '../../components/layout/MainLayout';
import ParentalGuard from '../../components/shared/ParentalGuard';
import { HeaderNav, ChannelsNav } from '../../utils/webUtils';
import { ChannelGridItemPlaceholder, ChannelGridItem } from './ChannelGridItem';
import SubMenu from '../../components/shared/SubMenu';
import EmptyResult from '../../components/shared/EmptyResult';
import { SubMenuItem } from '../../components/shared/SubMenu/SubMenu';

import './ChannelsPage.css';

const SUB_MENU_ITEMS: Array<SubMenuItem> = [
  {
    to: '/home/channels',
    id: ChannelsNav.ALL,
    title: 'Toutes les chaînes'
  },
  {
    to: '/home/favorite_channels',
    id: ChannelsNav.FAVORITES,
    title: 'Favoris'
  }
];

function ChannelsPage() {
  const browserContext: BrowserContextType = React.useContext(BrowserContext);
  const { pathname } = useLocation();
  const currentChannelNav: ChannelsNav = SUB_MENU_ITEMS.find(item => item.to === pathname)?.id ?? ChannelsNav.ALL;
  const { channelList } = useChannelList(browserContext?.selectedStreamingType, currentChannelNav !== ChannelsNav.ALL);

  // channels page will be hidden if livePlayerPage outlet is not null
  const livePlayerPage = useOutlet();

  return (
    <>
      {livePlayerPage}

      <MainLayout activeHeaderNav={HeaderNav.CHANNELS} hidden={!!livePlayerPage}>
        <ParentalGuard isAdult={false}>
          <div className="ChannelsPage">
            <SubMenu items={SUB_MENU_ITEMS} activeSubMenuItem={currentChannelNav} showRecordStatus={false} isSticky />

            {currentChannelNav === ChannelsNav.FAVORITES && channelList?.length === 0 ? (
              <EmptyFavorites />
            ) : (
              <div className="ChannelsGrid">
                {channelList?.length > 0
                  ? channelList.map((channel: FormattedChannel) => (
                      <ChannelGridItem channel={channel} key={channel.number} />
                    ))
                  : [...Array(12)].map((_, i) => <ChannelGridItemPlaceholder key={i} />)}
              </div>
            )}
          </div>
        </ParentalGuard>
      </MainLayout>
    </>
  );
}

function EmptyFavorites() {
  return (
    <div className="EmptyFavorites">
      <EmptyResult
        title="Votre liste de favoris est vide"
        subtitle="Ajoutez vos chaînes favorites depuis un autre appareil et retrouvez-les ici"
      />
    </div>
  );
}

export default ChannelsPage;
