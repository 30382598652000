import React from 'react';
import { Button, ButtonProps, RecordIcon, Typography } from '@oqee/ui';

import RecordModal from './RecordModal';
import { useErrorActions } from '@oqee/core';

interface RecordButtonProps extends ButtonProps {
  program: any;
}

function RecordButton({ program, color, iconColor, rounded, ...buttonProps }: RecordButtonProps) {
  const [displayModal, setDisplayModal] = React.useState<boolean>(false);
  const errorActions = useErrorActions();
  const hideText = rounded || color === 'secondary';

  const handleRecord = React.useCallback(() => {
    const { channel } = program;
    const isNpvrAllowed = channel.isNpvrAllowed;
    if (!isNpvrAllowed) {
      errorActions.trigger({ code: 'npvr_denied', data: { payload: { channelId: channel.id } } });
      return;
    }
    setDisplayModal(true);
  }, [setDisplayModal, program]);

  const handleClose = React.useCallback(
    (isCreated: boolean) => {
      const { content } = program;
      const isLive = program.isLive();
      if (isCreated && isLive) {
        errorActions.trigger({ code: 'record_program_already_started', data: { title: content.title } });
      }
      setDisplayModal(false);
    },
    [setDisplayModal, program]
  );

  return (
    <>
      <Button
        {...buttonProps}
        color={color}
        icon={RecordIcon}
        rounded={rounded}
        iconColor={iconColor}
        tooltip={hideText ? 'Enregistrer' : undefined}
        onClick={handleRecord}
      >
        {!hideText && (
          <Typography variant="body1" bold>
            Enregistrer
          </Typography>
        )}
      </Button>
      {displayModal && <RecordModal program={program} onClose={handleClose} />}
    </>
  );
}

export default RecordButton;
