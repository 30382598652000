import React from 'react';
import { PlaybackInfo } from '@oqee/core';

import useWebVodPlaybackInfo from '../../hooks/useWebVodPlaybackInfo';
import Player from './Player';

interface SVodPlayerProps {
  offerId: number;
}

function SVodPlayer({ offerId }: SVodPlayerProps) {
  const playbackInfo: PlaybackInfo | null = useWebVodPlaybackInfo({ apiType: 'svod', offerId });

  return <Player playbackInfo={playbackInfo} />;
}

export default SVodPlayer;
