import React from 'react';

import {Thumbnail, ThumbnailPicture, Typography } from '@oqee/ui';

import NavHeader from '../NavHeader';

import './SinglePageLayout.css';
import Lock from '../../shared/Lock';

type SinglePageLayoutProps = {
  title: string | React.ReactNode;
  thumbnailUrl: string;
  thumbnailLayout?: 'portrait' | 'landscape';
  isReady?: boolean;
  hasPromoToken?: boolean;
  isLocked?: boolean;
  children?: React.ReactNode;
};

const SinglePageLayout: React.FC<SinglePageLayoutProps> = ({
  title,
  thumbnailUrl,
  thumbnailLayout = 'landscape',
  hasPromoToken = false,
  isLocked = false,
  children
}) => (
  <div id="SinglePage">
    <NavHeader title="Retour" />
    <div className="content">
      <div className="details">
        <div className="header">
          {typeof title === 'string' ? <Typography variant="h3" bold>{title}</Typography> : title}
        </div>
        {children}
      </div>
      <Thumbnail layout={thumbnailLayout}>
        <ThumbnailPicture url={thumbnailUrl} />
        {isLocked
          ? <Lock isOpen={hasPromoToken}/>
          : null}
      </Thumbnail>
    </div>
  </div>
);

export default SinglePageLayout;
