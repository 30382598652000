import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Logger from 'js-logger';
import { api } from '@oqee/core';
import type { UserProfile } from '@oqee/core';

import Spinner from '../../../../components/shared/Spinner';
import UserProfileLayout from '../../../../components/layout/UserProfileLayout';
import { useWebStoreActions, useWebStoreState } from '../../../../store/webStoreUtils';
import { ActionCreator } from 'easy-peasy';

const logger = Logger.get('ProfileCreate');

function ProfileCreate() {
  const location = useLocation();
  const [createdProfile, setCreatedProfile] = React.useState<UserProfile | null>(null);

  const triggerError: ActionCreator<any> = useWebStoreActions(actions => actions.error.trigger);
  const setProfiles = useWebStoreActions(
    actions => actions.user.setProfiles
  );
  const profiles: UserProfile[] = useWebStoreState(state => state.user.profiles);
  const { state } = location;
  const { profile } = state;

  const handleCreateResponse = React.useCallback(
    response => {
      if (response.success) {
        const { result } = response;
        const createdProfile = result[result.length - 1];
        setCreatedProfile(createdProfile);
      } else {
        triggerError({
          code: response.error.code,
          data: response.error[response.error.code]
        });
      }
    },
    [setCreatedProfile, triggerError]
  );

  React.useEffect(() => {
    function handleError(error) {
      logger.error(error);
      triggerError({ code: 'api_internal_error' });
    }
    api.user.createProfile(profile).then(handleCreateResponse).catch(handleError);
  }, [profile, handleCreateResponse, triggerError]);

  React.useEffect(() => {
    if (!createdProfile) return;

    setProfiles([...profiles, createdProfile]);
  }, [setProfiles, createdProfile]);

  if (!createdProfile) {
    return (
      <UserProfileLayout>
        <Spinner testId="profile-creation-pending" />
      </UserProfileLayout>
    );
  }
  return <Navigate to="../.." state={{ profileId: createdProfile.id }} />;
}

export default ProfileCreate;
