import React from 'react';

import ContentRow from './ContentRow';

const CONTENT_ROWS_PLACEHOLDERS = [1, 2].map(id => ({
  id,
  entries: [...Array(8)].map(_ => ({
    isPlaceholder: true
  }))
}));

interface ContentRowsProps {
  rows: any[];
  showTileTitle?: boolean;
  hideCircularProgressInRows?: Array<string>;
  notAvailableProviders?: Array<any>;
  showTileTitleInRows?: Array<string>;
  showCircularProgress?: boolean;
}

function ContentRows({
  rows,
  showTileTitle = true,
  hideCircularProgressInRows = [],
  notAvailableProviders = [],
  showTileTitleInRows = [],
  showCircularProgress = false,
}: ContentRowsProps) {
  return (
    <div className="ContentRows">
      {rows?.map((row, rowIndex) => {
        const key = `${row.key || row.id || row.name || rowIndex}`;
        return (
          <ContentRow
            row={{
              ...row,
              id: key
            }}
            showTileTitle={showTileTitle}
            key={rowIndex}
            notAvailableProviders={notAvailableProviders}
            hideCircularProgressInRows={hideCircularProgressInRows}
            showTileTitleInRows={showTileTitleInRows}
            showCircularProgress={showCircularProgress}
          />
        );
      })}
    </div>
  );
}

export default ContentRows;
export { CONTENT_ROWS_PLACEHOLDERS };
