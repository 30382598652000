import React from 'react';
import { CroixIcon, SearchEvideeIcon, Typography } from '@oqee/ui';
import { useSearchParams } from 'react-router-dom';
import SearchEntry from './SearchEntry';
import InputText from '../InputText';
import { api } from '@oqee/core';

import "./SearchBar.css";

interface SearchBarProps {
  searchTerm: string;
  searchHistory: Array<string>;
  setSearchHistory: (history) => void;
}

function SearchBar({ searchTerm, searchHistory, setSearchHistory }: SearchBarProps) {
  const [focused, setFocused] = React.useState(false);
  const [, setSearchParams] = useSearchParams();

  return (
    <div className="SearchPageBar">
      <div className="SearchBar">
      
      <form role="search" onSubmit={(e) => e.preventDefault()}>
        <SearchEvideeIcon className="SearchBarIcon" />
        <InputText
          data-test-id="search-input"
          placeholder="Rechercher dans l'application"
          onChange={value => {
            setSearchParams(
              { term: value },
              {
                replace: true
              }
            );
          }}
          onFocus={() => setFocused(true)}
          autoComplete="off"
          value={searchTerm}
          onBlur={() => setFocused(false)}
        />
        {searchTerm ? (
          <CroixIcon
            className="SearchBarClearIcon"
            onMouseDown={() => {
              setSearchParams(
                { term: '' },
                {
                  replace: true
                }
              );
            }}
          />
        ) : null}
        </form>
        {searchHistory.length && focused && !searchTerm ? (
          <div className="SearchHistory">
            <div className="SearchEntry">
              <Typography variant="h4" bold>
                Historique de recherche
              </Typography>
              <div
                className="SearchBarClearAll"
                onMouseDown={() => {
                  setSearchHistory([]);
                  api.cache.remove(api.constant.LOCAL_STORAGE_KEY.SEARCH);
                }}
              >
                <Typography variant="body3" color="grey-3">
                  Tout effacer
                </Typography>
              </div>
            </div>
            {searchHistory.map(searchEntry => (
              <SearchEntry
                key={searchEntry}
                searchEntry={searchEntry}
                setSearchHistory={setSearchHistory}
                searchHistory={searchHistory}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SearchBar;
