import React from 'react';

import { Route, Routes } from 'react-router-dom';

import SubscriptionsList from './SubscriptionsList';
import SubscriptionOffer from './SubscriptionOffer';
import SubscriptionsUnsubscribe from './SubscriptionsUnsubscribe';
import SubscriptionsSubscribe from './SubscriptionsSubscribe';

function Subscriptions() {
  return (
    <Routes>
      <Route index element={<SubscriptionsList />} />
      <Route path="*" element={<SubscriptionsList />} />
      <Route path="offer/:offerType/:offerId" element={<SubscriptionOffer />} />
      <Route path="offer/:offerType/:offerId/unsubscribe" element={<SubscriptionsUnsubscribe />} />
      <Route path="offer/:offerType/:offerId/subscribe" element={<SubscriptionsSubscribe />} />
    </Routes>
  );
}

export default Subscriptions;
