import React from 'react';

import { CircularProgress as IUCircularProgress, CircularProgressSize } from '@oqee/ui';
import './CircularProgress.css';

interface CircularProgressProps {
  size?: CircularProgressSize;
  logoUrl: string;
  start?: number;
  end?: number;
  currentTime?: number;
  color?: string;
  showProgress?: boolean;
  className?: string;
}

const CircularProgress: React.FC<CircularProgressProps> = ({
  size = 'small',
  logoUrl,
  start = 0,
  end = 100,
  currentTime = 0,
  color,
  showProgress = false,
  className
}) => {
  const p = React.useMemo(() => {
    const progress: number = (currentTime - start) / (end - start);
    return { progress };
  }, [start, end, currentTime]);

  if(!logoUrl) return null;

  return (
    <IUCircularProgress
      className={className}
      showProgress={showProgress}
      circleColor={color}
      logoUrl={logoUrl}
      size={size}
      progress={p.progress}
    />
  );
};

export default React.memo(CircularProgress);
